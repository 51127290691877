import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";

export async function updateTerms(termsData) {
  const { whatsapp, termsOfUse, privacyPolicy, email } = termsData;

  const ref = collection(db, TABLES.LEGAL_DOCUMENTS);
  const q = query(ref);
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => doc.data());

  for (const item of data) {
    if (item.type === "whatsapp") {
      const documentRef = doc(db, TABLES.LEGAL_DOCUMENTS, item.id);

      await updateDoc(documentRef, {
        value: whatsapp,
      });
    }
    if (item.type === "privacy_policy") {
      const documentRef = doc(db, TABLES.LEGAL_DOCUMENTS, item.id);

      await updateDoc(documentRef, {
        value: privacyPolicy,
      });
    }
    if (item.type === "email") {
      const documentRef = doc(db, TABLES.LEGAL_DOCUMENTS, item.id);

      await updateDoc(documentRef, {
        value: email,
      });
    }
    if (item.type === "terms_of_use") {
      const documentRef = doc(db, TABLES.LEGAL_DOCUMENTS, item.id);

      await updateDoc(documentRef, {
        value: termsOfUse,
      });
    }
  }
}
