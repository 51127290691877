/***
 * Reusable Component finance body data
 */

import { TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import { TABLES } from "../../../enums/tables";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import CurrencyFilter from "../../../Components/CurrencyFilter/CurrencyFilter";
import dayjs from "dayjs";

const purchaseType = {
  connectstores: "ConnectStores",
  connectguides: "ConnectGuides",
  connectsales: "ConnectSales",
};

export default function Index({
  setTotalDate,
  orderFilter,
  dateFilter,
  isTransactionFilter,
  setIsTransactionFilter,
  currencyFilter,
  setCurrencyFilter,
  modalStatusFilter,
  setModalStatusFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalPlanFilter,
  setModalPlanFilter,
}) {
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);

  const [purchases, setPurchases] = useState([]);

  const [formatedData, setFormatedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalTransactionFilterSearch, setModalTransactionFilterSearch] =
    useState([]);
  const [modalTypeFilterSearch, setModalTypeFilterSearch] = useState([]);
  const [modalPlanFilterSearch, setModalPlanFilterSearch] = useState([]);
  const [modalStatusFilterSearch, setModalStatusFilterSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);

  const currencySymbol =
    currencyFilter.value === "BRL"
      ? "R$"
      : currencyFilter.value === "USD"
      ? "$"
      : "€";

  const currentPurchaseTypePlan = {
    complete: "ConnectStore completo",
    basic: "ConnectStore básico",
    "amateur-guide": "Connectguide não credenciado",
    "pro-guide": "Connectguide credenciado",
    connectsale: "Connectsale",
    highlight: "Highlight",
    events: "Evento",
    connectwatches: "ConnectWatch",
  };

  useEffect(() => {
    async function getDataFromFirebase() {
      const dataPurchases = await getFirebaseDataByCreationDate(
        TABLES.PURCHASES
      );

      setPurchases(dataPurchases);
    }
    getDataFromFirebase();
  }, []);

  useEffect(() => {
    if (purchases?.length === 0) return;

    const formatted = purchases
      ?.map((purchase) => {
        if (
          purchase?.plan?.collection === "connectstores" ||
          purchase?.plan?.collection === "connectguides" ||
          purchase?.plan?.collection === "connectsales"
        ) {
          return {
            ...purchase,
            status:
              (purchase?.status === "approved" && "Aprovado") ||
              (purchase?.status === "canceled" && "Cancelado") ||
              (purchase?.status === "pending" && "Pendente") ||
              (purchase?.status === "past_due" && "Atrasado") ||
              (purchase?.status === "reproved" && "Reprovado") ||
              (purchase?.status === "expired" && "Expirado") ||
              (purchase?.status === "paused" && "Pausado") ||
              (purchase?.status === "suspended" && "Suspenso") ||
              "--",
            type: purchaseType[purchase?.plan?.collection],
            totalValue:
              purchase?.plan?.prices?.[currencyFilter?.value]
                ?.price_minor_units / 100 || purchase?.amount_minor_units,
          };
        }

        if (
          purchase?.plan?.collection === "events" ||
          purchase?.plan?.collection === "connectwatches"
        ) {
          const days = dayjs(purchase?.location?.to).diff(
            dayjs(purchase?.location?.from),
            "day"
          );

          return {
            ...purchase,
            status:
              purchase?.status === "approved"
                ? "Aprovado"
                : purchase?.status === "canceled"
                ? "Cancelado"
                : purchase?.status === "pending"
                ? "Pendente"
                : purchase?.status === "past_due"
                ? "Atrasado"
                : purchase?.status === "reproved"
                ? "Reprovado"
                : purchase?.status === "expired"
                ? "Expirado"
                : purchase?.status === "paused"
                ? "Pausado"
                : purchase?.status === "suspended"
                ? "Suspenso"
                : "--",
            type: currentPurchaseTypePlan?.[purchase?.plan?.collection] ?? "--",
            days,
            totalValue:
              purchase?.plan?.prices_per_day?.[days]?.currency?.[
                currencyFilter?.value
              ]?.price_minor_units / 100 || purchase?.amount_minor_units / 100,
          };
        }
        return null;
      })
      .filter((item) => item?.currency === currencyFilter.value);
    setFormatedData(formatted);
    setTotalDate(formatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyFilter.value, purchases, setTotalDate]);

  useEffect(() => {
    if (formatedData) {
      const toDate = new Date(dateFilter?.to);
      const fromDate = new Date(dateFilter?.from);

      const filtered = formatedData
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.user?.name?.localeCompare(a?.user?.name)
              : a?.user?.name?.localeCompare(b?.user?.name);
          }
          if (orderFilter && orderFilter?.id === "type") {
            return orderFilter?.order === "asc"
              ? b?.type?.localeCompare(a?.type)
              : a?.type?.localeCompare(b?.type);
          }
          if (orderFilter && orderFilter?.id === "transaction") {
            return orderFilter?.order === "asc"
              ? b?.processor?.localeCompare(a?.processor)
              : a?.processor?.localeCompare(b?.processor);
          }
          if (orderFilter && orderFilter?.id === "model") {
            return orderFilter?.order === "asc"
              ? b?.plan?.names?.["pt-BR"]?.localeCompare(
                  a?.plan?.names?.["pt-BR"] || null
                )
              : a?.plan?.names?.["pt-BR"]?.localeCompare(
                  b?.plan?.names?.["pt-BR"] || null
                );
          }

          if (orderFilter && orderFilter?.id === "status") {
            return orderFilter?.order === "asc"
              ? b?.status?.localeCompare(a?.status)
              : a?.status?.localeCompare(b?.status);
          }

          if (orderFilter && orderFilter?.id === "total") {
            return orderFilter?.order === "asc"
              ? (b?.totalValue || 0) - (a?.totalValue || 0)
              : (a?.totalValue || 0) - (b?.totalValue || 0);
          }

          return 0;
        })
        ?.filter((row) => {
          const idMatch = row?.plan?.id
            ?.toLowerCase()
            ?.includes(query.toLowerCase());

          const nameMatch = row?.user?.name
            ?.toLowerCase()
            ?.includes(query.toLowerCase());

          const typeMatch = row?.type
            ?.toLowerCase()
            ?.includes(query.toLowerCase());

          const planNameMatch = (
            row?.plan?.names?.["pt-BR"] || `${row?.days} dias`
          )
            ?.toLowerCase()
            ?.includes(query.toLowerCase());

          let filterTransactionMatch = true;
          let filterStatusMatch = true;
          let filterTypeMatch = true;
          let filterPlanMatch = true;

          if (modalTypeFilterSearch?.length > 0) {
            const matchingItems = modalTypeFilterSearch?.filter(
              (filterItem) =>
                filterItem?.type?.toLowerCase()?.trim() ===
                row?.type?.toLowerCase()?.trim()
            );
            filterTypeMatch = matchingItems?.length > 0;
          }

          if (modalTransactionFilterSearch?.length > 0) {
            const matchingItems = modalTransactionFilterSearch?.filter(
              (filterItem) =>
                filterItem?.processor?.toLowerCase()?.trim() ===
                row?.processor?.toLowerCase()?.trim()
            );
            filterTransactionMatch = matchingItems?.length > 0;
          }

          if (modalPlanFilterSearch?.length > 0) {
            const matchingItems = modalPlanFilterSearch?.filter(
              (filterItem) => {
                return (
                  String(filterItem?.plan) ===
                  String(
                    row?.plan?.names?.["pt-BR"]?.toLowerCase()?.trim() ||
                      row?.days
                  )
                    ?.toLowerCase()
                    ?.trim()
                );
              }
            );
            filterPlanMatch = matchingItems?.length > 0;
          }

          if (modalStatusFilterSearch?.length > 0) {
            const matchingItems = modalStatusFilterSearch?.filter(
              (filterItem) =>
                filterItem?.status?.toLowerCase()?.trim() ===
                row?.status?.toLowerCase()?.trim()
            );
            filterStatusMatch = matchingItems?.length > 0;
          }

          const transactionMatch = row?.processor
            ?.toLowerCase()
            ?.includes(query.toLowerCase());

          const statusMatch = row?.status
            ?.toLowerCase()
            ?.includes(query.toLowerCase());

          const valueMatch = row?.totalValuePlaceholder
            ?.toLowerCase()
            ?.includes(query.toLowerCase());

          return (
            (nameMatch ||
              typeMatch ||
              planNameMatch ||
              transactionMatch ||
              statusMatch ||
              valueMatch ||
              idMatch) &&
            filterTransactionMatch &&
            filterStatusMatch &&
            filterPlanMatch &&
            filterTypeMatch &&
            (dateFilter
              ? new Date(row?.created_at) >= fromDate &&
                new Date(row?.created_at) <=
                  toDate?.setDate(toDate?.getDate() + 1)
              : true)
          );
        });
      setFilteredData(filtered);
    }
  }, [
    query,
    formatedData,
    orderFilter,
    dateFilter,
    modalTransactionFilterSearch,
    modalStatusFilterSearch,
    modalTypeFilterSearch,
    modalPlanFilterSearch,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [queryNum, dateFilter, query]);

  useEffect(() => {
    setActivePage(modalStatusFilterSearch, modalStatusFilter);
  }, [modalStatusFilterSearch, modalStatusFilter]);

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return (
      <>
        {paginatedData?.map(
          (row) =>
            row && (
              <TableRow hover key={row?.id}>
                <TableCell>{row?.id ? row.id.substring(0, 6) : "--"}</TableCell>
                <TableCell>{row?.user?.name || "--"}</TableCell>
                <TableCell>{row?.type}</TableCell>
                <TableCell sx={{ width: "100px" }}>
                  {row?.plan?.names?.["pt-BR"]
                    ? row?.plan?.names?.["pt-BR"]
                    : row?.days
                    ? `${row?.days} dias`
                    : "--"}
                </TableCell>
                <TableCell>
                  {moment(row?.created_at)?.format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>{row?.processor}</TableCell>
                <TableCell>
                  {/* Status Cell with Color Coding */}
                  {row?.status === "Aprovado" && (
                    <span style={{ color: "#01d371" }}>Aprovado</span>
                  )}
                  {row?.status === "Cancelado" && (
                    <span style={{ color: "#ff7300" }}>Cancelado</span>
                  )}
                  {row?.status === "Pendente" && (
                    <span style={{ color: "#F7C604" }}>Pendente</span>
                  )}
                  {row?.status === "Atrasado" && (
                    <span style={{ color: "#ff0000" }}>Atrasado</span>
                  )}
                  {row?.status === "Reprovado" && (
                    <span style={{ color: "#ff0000" }}>Reprovado</span>
                  )}
                  {row?.status === "Expirado" && (
                    <span style={{ color: "#000000" }}>Expirado</span>
                  )}
                  {row?.status === "Pausado" && (
                    <span style={{ color: "#0000ff" }}>Pausado</span>
                  )}
                  {row?.status === "Suspenso" && (
                    <span style={{ color: "#8b0000" }}>Suspenso</span>
                  )}
                  {![
                    "Aprovado",
                    "Cancelado",
                    "Pendente",
                    "Atrasado",
                    "Reprovado",
                    "Expirado",
                    "Pausado",
                    "Suspenso",
                  ].includes(row?.status) && <span>--</span>}
                </TableCell>
                <TableCell>{`${currencySymbol} ${row?.totalValue}`}</TableCell>
              </TableRow>
            )
        )}

        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {isTransactionFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalTransactionFilterSearch}
            setModalFilterSearch={setModalTransactionFilterSearch}
            setModalFilter={setIsTransactionFilter}
            data={[
              ...new Set(formatedData?.map((item) => item?.processor)),
            ]?.map((item) => ({ processor: item }))}
            labelPath="processor"
            label="Transação"
          />
        )}

        {modalPlanFilter?.isOpen && (
          <BaseFilter
            orderData={false}
            modalFilterSearch={modalPlanFilterSearch}
            setModalFilterSearch={setModalPlanFilterSearch}
            setModalFilter={setModalPlanFilter}
            data={[
              ...new Set(
                formatedData?.map((item) => {
                  return item?.plan?.names?.["pt-BR"] ?? item?.days;
                })
              ),
            ]
              .filter(Boolean)
              .sort((a, b) => {
                const numA = parseFloat(a);
                const numB = parseFloat(b);
                const aIsNumber = !isNaN(numA);
                const bIsNumber = !isNaN(numB);

                if (aIsNumber && bIsNumber) {
                  return numA - numB;
                } else if (aIsNumber) {
                  return -1;
                } else if (bIsNumber) {
                  return 1;
                } else {
                  return String(a).localeCompare(String(b));
                }
              })
              .map((item) => {
                return { plan: String(item) };
              })}
            labelPath="plan"
            label="Nome do plano"
          />
        )}

        {modalTypeFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalTypeFilterSearch}
            setModalFilterSearch={setModalTypeFilterSearch}
            setModalFilter={setModalTypeFilter}
            data={[...new Set(formatedData?.map((item) => item?.type))]?.map(
              (item) => ({ type: item })
            )}
            labelPath="type"
            label="Tipo do plano"
          />
        )}

        {modalStatusFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalStatusFilterSearch}
            setModalFilterSearch={setModalStatusFilterSearch}
            setModalFilter={setModalStatusFilter}
            data={[...new Set(formatedData?.map((item) => item?.status))]?.map(
              (item) => ({ status: item })
            )}
            labelPath="status"
            label="Status"
          />
        )}

        {currencyFilter.isOpen && (
          <CurrencyFilter
            currencyFilter={currencyFilter}
            setCurrencyFilter={setCurrencyFilter}
          />
        )}
      </>
    );
  }
  return <></>;
}
