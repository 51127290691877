import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";

export async function reactiveComment(reportId, commentId) {
  const reportRef = doc(db, TABLES.REPORTS, reportId);
  const commentsRef = doc(db, TABLES.COMMENTS, commentId);

  await Promise.all([
    updateDoc(reportRef, {
      active: true,
    }),
    deleteDoc(reportRef),
    updateDoc(commentsRef, {
      active: true,
    }),
  ]);
}
export async function deleteComment(reportId, commentId) {
  const reportRef = doc(db, TABLES.REPORTS, reportId);
  const commentsRef = doc(db, TABLES.COMMENTS, commentId);

  await Promise.all([
    deleteDoc(reportRef),
    deleteDoc(commentsRef)
  ]);
}
