import { createSlice } from "@reduxjs/toolkit";

const AgendaSlice = createSlice({
  name: "agenda",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
    DeleteRow(state, { payload }) {
      state.value = state.value.filter((item) => item.id !== payload.id);
    },
    CreateAgenda(state, { payload }) {
      state.value = [payload, ...state.value];
    },
  },
});

export const selectAll = (state) => state.agenda.value;
export const { DeleteRow, UpdateRow, CreateAgenda, UpdateValue } =
  AgendaSlice.actions;
export default AgendaSlice.reducer;
