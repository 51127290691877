import { TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import dayjs from "dayjs";
import { useAuth } from "../../../Hooks/useAuth";
import { TABLES } from "../../../enums/tables";
import { showOnlyFirstSixDigits } from "../../../utils/showOnlyFirstSixDigits";
import CurrencyFilter from "../../../Components/CurrencyFilter/CurrencyFilter";

const currentPurchaseType = {
  connectguides: "ConnectGuide",
  connectstores: "ConnectStore",
  connectsales: "ConnectSale",
  connectroutes: "ConnectRoute",
  connectwatches: "ConnectWatch",
};

export default function Index({
  OpenModal,
  OpenDetails,
  orderFilter,
  dateFilter,
  setPurchaseData,
  modalNameFilter,
  setModalNameFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalDurationFilter,
  setModalDurationFilter,
  modalPlaceFilter,
  setModalPlaceFilter,
  currencyFilter,
  setCurrencyFilter,
}) {
  const [data, setData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const monthToFilter = Number(queryParams?.get("month"));
  const typeToFilter = queryParams?.get("type");

  const currencySymbol =
    currencyFilter.value === "BRL"
      ? "R$"
      : currencyFilter.value === "USD"
      ? "$"
      : "€";

  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalPlaceFilterSearch, setModalPlaceFilterSearch] = useState([]);
  const [modalTypeFilterSearch, setModalTypeFilterSearch] = useState([]);
  const [modalDurationFilterSearch, setModalDurationFilterSearch] = useState(
    []
  );
  const [highlights, setHighlights] = useState([]);

  async function getHighlights() {
    const highlights = await getFirebaseDataByCreationDate(TABLES?.HIGHLIGHTS);
    setHighlights(highlights);
  }

  useEffect(() => {
    getHighlights();
  }, []);

  useEffect(() => {
    async function formatTableData() {
      let currentData = [];
      let data = highlights;

      if (monthToFilter !== 0) {
        data = filterMonth(highlights, monthToFilter);
      }

      data?.forEach((filteredPurchase) => {
        const days = dayjs(filteredPurchase?.to).diff(
          dayjs(filteredPurchase?.from),
          "day"
        );

        const price =
          filteredPurchase?.plan?.prices_per_day?.[days]?.currency?.[
            currencyFilter.value
          ]?.price_minor_units / 100 || 0;

        let type;

        if (
          filteredPurchase?.plan?.highlight_screens?.includes("home") &&
          filteredPurchase?.plan?.highlight_screens?.includes("search")
        ) {
          type = "Principal e categoria";
        }
        if (
          filteredPurchase?.plan?.highlight_screens?.includes("home") &&
          !filteredPurchase?.plan?.highlight_screens?.includes("search")
        ) {
          type = "Principal";
        }
        if (
          !filteredPurchase?.plan?.highlight_screens?.includes("home") &&
          filteredPurchase?.plan?.highlight_screens?.includes("search")
        ) {
          type = "Categoria";
        }
        if (filteredPurchase?.collection === "connectwatches") {
          type = "Principal";
        }

        const formattedData = {
          ...filteredPurchase,
          type,
          days,
          purchaseTypePlan: currentPurchaseType?.[filteredPurchase?.collection],
          price,
        };

        const isDuplicated = currentData?.some(
          (data) => data?.id === formattedData?.id
        );
        if (!isDuplicated) {
          currentData?.push(formattedData);
        }
      });

      const sortedData = currentData?.sort((a, b) => {
        if (dayjs(a?.created_at) > dayjs(b?.created_at)) {
          return -1;
        } else if (dayjs(a?.created_at) < dayjs(b?.created_at)) {
          return 1;
        } else {
          return 0;
        }
      });
      setData(sortedData);
    }
    formatTableData();
  }, [monthToFilter, highlights, currencyFilter]);

  function filterMonth(array, month) {
    return array?.filter((item) => {
      const date = new Date(item?.created_at);
      const itemMonth = date?.getMonth() + 1;

      return itemMonth === month;
    });
  }

  useEffect(() => {
    if (data?.length > 0) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);

      const filtered = data
        ?.filter((row) => {
          let filterNameMatch = true;
          let filterPlaceMatch = true;
          let filterTypeMatch = true;
          let filterDurationMatch = true;

          if (modalNameFilterSearch?.length > 0) {
            const matchingItems = modalNameFilterSearch?.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.userName?.toLowerCase()?.trim()
            );
            filterNameMatch = matchingItems?.length > 0;
          }

          if (modalPlaceFilterSearch?.length > 0) {
            const matchingItems = modalPlaceFilterSearch?.filter(
              (filterItem) =>
                filterItem?.type?.toLowerCase()?.trim() ===
                row?.purchaseTypePlan?.toLowerCase()?.trim()
            );
            filterPlaceMatch = matchingItems?.length > 0;
          }

          if (modalTypeFilterSearch?.length > 0) {
            const matchingItems = modalTypeFilterSearch?.filter(
              (filterItem) =>
                filterItem?.type?.toLowerCase()?.trim() ===
                row?.purchaseTypePlan?.toLowerCase()?.trim()
            );
            filterTypeMatch = matchingItems?.length > 0;
          }

          if (modalDurationFilterSearch?.length > 0) {
            const matchingItems = modalDurationFilterSearch?.filter(
              (filterItem) => filterItem?.duration === String(row?.days)
            );
            filterDurationMatch = matchingItems?.length > 0;
          }

          const nameMatch = row?.owner?.name?.toLowerCase()?.includes(query);

          const idMatch = String(row?.id)?.toLowerCase()?.includes(query);

          const usernameMatch = String(row?.owner?.name)
            ?.toLowerCase()
            ?.includes(query);

          const durationMatch = String(row?.days)
            ?.toLowerCase()
            ?.includes(query);

          const typeMatch = String(row?.purchaseTypePlan)
            ?.toLowerCase()
            ?.includes(query);

          const priceMatch = String(row?.price?.toFixed(2))
            ?.toLowerCase()
            ?.includes(query);

          return (
            (nameMatch ||
              typeMatch ||
              idMatch ||
              usernameMatch ||
              durationMatch ||
              priceMatch) &&
            filterNameMatch &&
            filterPlaceMatch &&
            filterTypeMatch &&
            filterDurationMatch &&
            (dateFilter
              ? dayjs(row?.created_at)?.isAfter(fromDate) &&
                dayjs(row?.created_at)?.isBefore(toDate?.add(1, "day"))
              : true)
          );
        })

        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.owner?.name?.localeCompare(a?.owner?.name)
              : a?.owner?.name?.localeCompare(b?.owner?.name);
          }
          if (orderFilter && orderFilter?.id === "type") {
            return orderFilter?.order === "asc"
              ? b?.purchaseType?.localeCompare(a?.purchaseType)
              : a?.purchaseType?.localeCompare(b?.purchaseType);
          }
          if (orderFilter && orderFilter?.id === "Duration") {
            return orderFilter?.order === "asc"
              ? b?.purchaseTypePlan?.localeCompare(a?.purchaseTypePlan)
              : a?.purchaseTypePlan?.localeCompare(b?.purchaseTypePlan);
          }
          if (orderFilter && orderFilter?.id === "price") {
            return orderFilter?.order === "asc"
              ? a?.pricee - b?.pricee
              : b?.pricee - a?.pricee;
          }

          return 0;
        });

      setFilteredData(filtered);
    }
  }, [
    data,
    highlights,
    dateFilter,
    modalDurationFilterSearch,
    modalNameFilterSearch,
    modalPlaceFilterSearch,
    modalTypeFilterSearch,
    orderFilter,
    currencyFilter,
    query,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    queryNum,
    query,
    filteredData,
    dateFilter,
    modalDurationFilterSearch,
    modalNameFilterSearch,
    modalTypeFilterSearch,
    orderFilter,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    setPurchaseData(filteredData);

    return (
      <>
        {paginatedData?.map((row) => {
          return (
            <TableRow hover key={row?.id}>
              <TableCell sx={{ width: "100px" }}>
                {showOnlyFirstSixDigits(row?.id)}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {row?.owner?.name ?? "--"}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {currentPurchaseType?.[row?.collection] ?? "--"}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>{row?.type ?? "--"}</TableCell>
              <TableCell sx={{ width: "100px" }}>
                {row?.days ?? "-- "} dias
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {dayjs(row?.created_at)?.format("DD[/]MM[/]YYYY") ?? "--"}
              </TableCell>

              <TableCell sx={{ width: "100px" }}>
                {currencySymbol + row?.price?.toFixed(2) ?? "--"}
              </TableCell>
            </TableRow>
          );
        })}
        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {modalNameFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(
                data?.map((item) => item?.userName && item?.userName?.trim())
              ),
            ]?.map((item) => ({ name: item }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalPlaceFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalPlaceFilterSearch}
            setModalFilterSearch={setModalPlaceFilterSearch}
            setModalFilter={setModalPlaceFilter}
            data={[
              ...new Set(data?.map((item) => item?.type && item?.type?.trim())),
            ]?.map((item) => ({ type: item }))}
            labelPath="type"
            label="local"
          />
        )}

        {modalTypeFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalTypeFilterSearch}
            setModalFilterSearch={setModalTypeFilterSearch}
            setModalFilter={setModalTypeFilter}
            data={[
              ...new Set(data?.map((item) => item?.purchaseTypePlan?.trim())),
            ]?.map((item) => ({ type: String(item) }))}
            labelPath="type"
            label="tipo do plano"
          />
        )}

        {modalDurationFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalDurationFilterSearch}
            setModalFilterSearch={setModalDurationFilterSearch}
            setModalFilter={setModalDurationFilter}
            data={[
              ...new Set(data?.map((item) => String(item?.days)?.trim())),
            ]?.map((item) => ({ duration: item }))}
            labelPath="duration"
            label="duração do highlight"
          />
        )}

        {currencyFilter.isOpen && (
          <CurrencyFilter
            currencyFilter={currencyFilter}
            setCurrencyFilter={setCurrencyFilter}
          />
        )}
      </>
    );
  }
  return (
    <>
      <p style={{ paddingLeft: "1rem", whiteSpace: "nowrap" }}>
        Nenhum resultado?.
      </p>
    </>
  );
}
