import React from "react";
import { Router } from "./Router";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./Components/Global/Global";
import { AuthProvider } from "./Contexts/AuthContext";
import store from "./features/index";

function App() {
  return (
    <>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Router />
            <GlobalStyle />
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </>
  );
}

export default App;
