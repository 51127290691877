import { StyledTableCell } from "./styled";
import { TableCell } from "@mui/material";
import React from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";
import { useState } from "react";

/****
 * LABELS FOR Category TABLE TO MAKE OUR TABLE REUSABLE
 */
export function CategoryLabels({ orderFilter, setOrderFilter }) {
  const columns = [
    { id: "name", label: "Nome", align: "left" },
    { id: "type", label: "Tipo", align: "left" },
    { id: "edit", label: "Editar", align: "right" },
    { id: "delete", label: "Excluir", align: "right" },
  ];

  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  return (
    <>
      {columns.map((column) => {
        return (
          <StyledTableCell
            onClick={() => handleChangeOrder(column.id)}
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "edit" && column.id !== "delete" && (
                <div>
                  <ArrowUp
                    size={16}
                    weight="regular"
                    color={`${
                      orderFilter &&
                      orderFilter.id === column.id &&
                      orderFilter.order === "desc"
                        ? "#06bee7"
                        : "rgba(0, 0, 0, 0.87)"
                    }`}
                  />
                  <ArrowDown
                    color={`${
                      orderFilter &&
                      orderFilter.id === column.id &&
                      orderFilter.order === "asc"
                        ? "#06bee7"
                        : "rgba(0, 0, 0, 0.87)"
                    }`}
                    size={16}
                    weight="regular"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
              )}
            </div>
          </StyledTableCell>
        );
      })}
    </>
  );
}
