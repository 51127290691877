import styled from "styled-components";
import { TableCell } from "@mui/material";

//Show Categories
export const DisplayBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  padding: 10px;
  margin-top: 0px;

  ul {
    display: grid;
    gap: 10px;

    li {
      display: flex;
      gap: 10px;
      align-items: center;

      svg {
        color: inherit;
      }
    }
  }
`;

/***
 * This styled component declares the styles for Side Dispaly color
 */

export const Ball = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({ bColor }) => bColor};
  border-radius: 50%;
`;

export const styledCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  //change color input
  input[type="color"] {
    border: 1px solid transparent;
    outline-style: none;
    border-radius: 50px;
  }
`;

export const ColorPicker = styled.div`
  position: relative;

  //color picker input
  input[type="color"] {
    border: 2px solid blue;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  //image to represent the color picker
  img {
    position: initial;
    width: 200px;
    max-width: 250px;
    min-width: 100px;
  }
`;
export const SelectedColor = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
  top: 0;
  right: 0;
  position: absolute;
  border-radius: 50%;
  border: 5px solid white;
  transform: translateX(-120px) translateY(30px);
`;
/**
 * Table Row Input to Edit table
 */

export const EditColorInput = styled.input``;

export const StyledEditInput = styled.input`
  width: 100%;
  border-bottom-color: #e9e9e9;
`;

export const StyledEditSelect = styled.select`
  width: 100%;
  border-color: transparent;
  border-bottom-color: #e9e9e9;
`;

export const TypeListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const HandleDaysAndValue = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  &:hover {
    opacity: 0.7;
    background-color: transparent;
  }
`;

export const RemoveDaysAndValueButton = styled(HandleDaysAndValue)`
  color: #000;
  margin-bottom: 8px;
`;

export const AddDaysAndValueButton = styled(HandleDaysAndValue)`
  margin-left: auto;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;
export const Option = styled.li`
  display: flex;
  align-items: center;
  padding: 2px 0;
  input {
    width: 5rem;
  }
`;
export const Gap = styled.section`
  width: 100%;
  height: 0.9px;
  background-color: #000;
`;

export const ModalContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const StyledTableCell = styled(TableCell)`
  cursor: ${({ columnId }) =>
    columnId === "edit" ||
    columnId === "delete" ||
    columnId === "trendsDays" ||
    columnId === "trendsBrl" ||
    columnId === "trendsEur" ||
    columnId === "trendsUsd"
      ? "default"
      : "pointer"};

  width: ${({ columnId }) => (columnId === "edit" ? "10px" : "")};

  > div {
    display: flex;
    align-items: center;
    gap: 5px;

    > div {
      width: 60px;
    }
  }
`;
