import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";
import { getFirebaseCollectionItem, serializeData } from "./firebase";

const TOURIST_GUIDE_STATUS = Object.freeze({
  approved: "approved",
  reproved: "reproved",
  pending: "pending",
});

export async function update(touristGuideData) {
  const {
    name,
    phone: tel,
    code: fiscal_code,
    institute: instituition,
    description,
    images,
    status,
    email,
  } = touristGuideData;

  const documentRef = doc(db, TABLES.CONNECTGUIDES, touristGuideData.id);

  return updateDoc(documentRef, {
    id: touristGuideData.id,
    owner: doc(db, TABLES.USERS, touristGuideData.id),
    name,
    contact: { email, tel },
    fiscal_code,
    intitution: instituition,
    description,
    images,
    status,
  });
}

export async function block(id, blockValue) {
  const touristGuideRef = doc(db, TABLES.CONNECTGUIDES, id);

  await updateDoc(touristGuideRef, {
    active: blockValue,
  });
}

export async function approve(id) {
  const touristGuideRef = doc(db, TABLES.CONNECTGUIDES, id);

  await updateDoc(touristGuideRef, {
    active: true,
  });
}

export async function reprove(id) {
  const touristGuideRef = doc(db, TABLES.CONNECTGUIDES, id);

  await updateDoc(touristGuideRef, {
    active: false,
  });
}

export async function approveDocuments(guideId) {
  const documentRef = doc(db, TABLES.CONNECTGUIDES, guideId);

  await updateDoc(documentRef, {
    "document.status": TOURIST_GUIDE_STATUS.approved,
  });
}

export async function rejectDocuments(guideId) {
  const documentRef = doc(db, TABLES.CONNECTGUIDES, guideId);

  await updateDoc(documentRef, {
    "document.status": TOURIST_GUIDE_STATUS.reproved,
  });
}

export const getConnectGuideById = async (id) => {
  const connectGuide = await getFirebaseCollectionItem(
    TABLES.CONNECTGUIDES,
    id
  );

  return connectGuide;
};
