import React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { ComponentProps } from 'react';
import {Root, Title, Description, Close, Viewport} from './styles';


export function Toast({ title, description, variation, ...props }) {
  const variants = {
    success: '#00C851',
    error: '#ff4444',
  };

  const buttonVariant = variants[variation] ? '#fff' : '#03738C';

  return (
    <ToastPrimitive.Provider swipeDirection="right">
      <Root
        {...props}
        variant={variants[variation]}
      >
        <header>
          <Title>
            {title}
          </Title>

          <Close
            variant={buttonVariant}
          >
            X
          </Close>
        </header>

        {!!description && (
          <Description>
            {description}
          </Description>
        )}
      </Root>

      <Viewport/>
    </ToastPrimitive.Provider>
  );
}
