import { createSlice } from "@reduxjs/toolkit";

const ExploreSlice = createSlice({
  name: "explore",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
    DeleteRow(state, { payload }) {
      state.value = state.value.filter((item) => item.id !== payload.id);
    },
    CreateInstitutionalVideo(state, { payload }) {
      state.value.unshift(payload);
    },
  },
});

export const selectAll = (state) => state.explore.value;
export const { DeleteRow, UpdateRow, CreateInstitutionalVideo, UpdateValue } =
  ExploreSlice.actions;
export default ExploreSlice.reducer;
