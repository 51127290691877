import { createSlice } from "@reduxjs/toolkit";

const TrendsPricesSlice = createSlice({
  name: "trendsPrices",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateTrendsPricesValue(state, { payload }) {
      state.value = payload;
    },
    UpdateTrendsPricesRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
    DeleteTrendsPricesRow(state, { payload }) {
      state.value = state.value.filter((item) => item.id !== payload.id);
    },
    CreateTrendsPrices(state, { payload }) {
      state.value.unshift(payload);
    },
  },
});

export const selectAllTrendsPrices = (state) => state.trendsPrices.value;
export const {
  DeleteTrendsPricesRow,
  UpdateTrendsPricesRow,
  CreateTrendsPrices,
  UpdateTrendsPricesValue,
} = TrendsPricesSlice.actions;
export default TrendsPricesSlice.reducer;
