import React from "react";
import * as S from "./styled";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const CurrencyFilter = ({ currencyFilter, setCurrencyFilter, showAll }) => {
  function handleClose() {
    setCurrencyFilter((prev) => ({ ...prev, isOpen: false }));
  }

  function handleUpdateValue(value) {
    setCurrencyFilter((prev) => ({ ...prev, value }));
  }

  return (
    <S.ModalContainer>
      <div className="content">
        <h3>Filtro</h3>

        <div className="inputsContainer">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Selecione a moeda:
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="BRL"
              value={currencyFilter.value}
              onChange={(e) => handleUpdateValue(e.target.value)}
              name="radio-buttons-group"
            >
              {showAll && (
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Todos"
                />
              )}
              <FormControlLabel value="EUR" control={<Radio />} label="Euro" />
              <FormControlLabel value="BRL" control={<Radio />} label="Real" />
              <FormControlLabel value="USD" control={<Radio />} label="Dolar" />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="buttonsWrapper">
          <button className="button" onClick={handleClose}>
            Fechar
          </button>
        </div>
      </div>
    </S.ModalContainer>
  );
};

export default CurrencyFilter;
