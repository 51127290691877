import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DenunciationsData from "./bodyData";
import { DenunciationLabel } from "./Labels";
import { AppContainer, ShowTotal } from "../../../Components/Global/Reusable";
import ConfirmModal from "../../../Components/ConfirmModal/ConfirmModal";
import StyledTable from "../../../Components/Table";
import Details from "./Details";
import DialogModal from "../../../Components/DialogModal";
import ImageModal from "../../../Components/DialogModal/ImageModal";
import { DeleteRow, selectAll } from "../../../features/DenunciationSlice";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";

export default function Index() {
  const [data, setData] = useState(null);
  const [Id, setId] = useState(null);
  const [details, setDetails] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [secondaryFilter, setSecondaryFilter] = useState("");
  const [orderFilter, setOrderFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);

  const timerRef = useRef(0);
  const dispatch = useDispatch();

  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  function OpenDetails(rowId) {
    setDetails(true);
    setId(rowId);
  }

  function CloseDetails() {
    setDetails(false);
  }

  /**
   * Declare Method to Open Modal for Confirmation
   * Declare switch functionality
   */
  function OpenModal(rowStatus, rowId, rowName) {
    setData(true);
  }

  //Method to Reproved
  async function Reprove() {
    setData(false);
    CloseDetails();
    dispatch(DeleteRow({ id: Id }));
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);
  const items = useSelector(selectAll);
  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="Denuncias"
          Component={<ShowTotal total={items.length} />}
          Labels={
            <DenunciationLabel
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
            />
          }
          TableData={
            <DenunciationsData
              secondaryFilter={secondaryFilter}
              orderFilter={orderFilter}
              dateFilter={dateFilter}
              OpenDetails={OpenDetails}
            />
          }
        />
        {details && (
          <Details
            onToastOpen={onToastOpen}
            CloseDetails={CloseDetails}
            detailsID={Id}
            openPreview={setPreview}
            OpenModal={OpenModal}
          />
        )}
        {data && (
          <DialogModal>
            <ConfirmModal
              setModal={setData}
              message={
                <p style={{ color: "#949494", margin: "0" }}>
                  Ao excluir um comentário, o mesmo não aparecerá mais na
                  plataforma.
                </p>
              }
              miscFunction={Reprove}
            />
          </DialogModal>
        )}
        {preview && (
          <DialogModal>
            <ImageModal openPreview={setPreview} />
          </DialogModal>
        )}
      </AppContainer>
    </>
  );
}
