import React, { useEffect, useState } from "react";
import * as S from "./styled";
import { X } from "phosphor-react";

const DateFilter = ({ setIsDateFilterOpen, setDateFilter, dateFilter }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (dateFilter) {
      setStartDate(dateFilter.from);
      setEndDate(dateFilter.to);
    }
  }, []);

  function handleStartDateChange(event) {
    setStartDate(event.target.value);
  }

  function handleEndDateChange(event) {
    setEndDate(event.target.value);
  }

  function clearDate() {
    setStartDate("");
    setEndDate("");
    setDateFilter(null);
  }

  function closeFilter() {
    setIsDateFilterOpen(false);
  }

  function filterDate() {
    setDateFilter({ from: startDate, to: endDate });
    setIsDateFilterOpen(false);
  }

  const disabledButton = !(startDate && endDate);

  return (
    <S.ModalContainer>
      <div className="content">
        <X
          onClick={closeFilter}
          weight="bold"
          color="#03738c"
          className="close"
        />
        <h3>Filtrar por datas:</h3>

        <div className="inputsContainer">
          <label htmlFor="startDate">De:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={startDate}
            onChange={handleStartDateChange}
          />

          <label htmlFor="endDate">Até:</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>

        <div className="buttonsWrapper">
          <button onClick={clearDate} id="clear">
            Limpar
          </button>
          <button disabled={disabledButton} onClick={filterDate}>
            Buscar
          </button>
        </div>
      </div>
    </S.ModalContainer>
  );
};

export default DateFilter;
