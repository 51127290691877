import { createSlice } from "@reduxjs/toolkit";

const GuideReportSlice = createSlice({
  name: "guideReport",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
  },
});

export const selectAll = (state) => state.guideReport.value;
export const { UpdateRow, UpdateValue } = GuideReportSlice.actions;
export default GuideReportSlice.reducer;
