import { MenuItem, Select } from "@mui/material";
import styled from "styled-components";
import { TableCell } from "@mui/material";

export const OverlayContainer = styled.div`
  background-color: #000000af;
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  display: grid;
  justify-content: end;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;
export const RegisterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 360px;
  max-width: 400px;
  min-width: 300px;
  box-shadow: 1px 1px 5px 5px #00000028;
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;

  overflow: auto;
  padding-bottom: 1rem;
`;
export const RegisterHeader = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid transparent;
  border-bottom-color: #151515;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //svg
  svg {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 50px;
  }
`;
export const RegisterBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* > * {border: 1px dashed red;} */
    /* border: 2px dashed green; */

    & > div > * {
      margin: 20px 0;
    }

    > button {
      align-self: center;
      justify-self: center;
      padding: 10px 30px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary}!important;
        color: ${({ theme }) => theme.colors.primary}!important;
      }
    }
  }
`;

export const InputGroup = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;

  //label inside
  label {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0 5px;
  }

  div {
    height: 30px;
    max-height: 30px;
    min-height: 10px;
    border: 1px solid transparent !important;
    outline: none !important;
  }
`;

export const RegisterButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.4rem 1.2rem;
  border-radius: 50px;
  transition: all 0.2s linear;
  font-size: 0.9rem;
  font-weight: 400;

  //on hover
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.primary};
  }

  //off hover
  &:disabled {
    pointer-events: none;
    filter: brightness(0.5);
  }
`;

export const StyledEditInput = styled.input`
  width: 100%;
  border-bottom-color: #e9e9e9;
`;

export const FileInputWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  padding: 0.6rem;

  div {
    position: relative;
    display: grid;
    place-items: center;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50% !important;
    padding: ${({ file }) => (file ? "0" : "1.5rem;")};
    overflow: hidden;

    img {
      min-width: ${({ file }) => (file ? "140px" : "90px")};
      width: 90px;
      max-width: 90px;
      min-height: ${({ file }) => (file ? "140px" : "90px")};
      height: 90px;
      max-height: 90px;
      object-fit: cover;
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

export const ModalContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const StyledTableCell = styled(TableCell)`
  cursor: ${({ columnId }) =>
    columnId !== "edit" && columnId !== "delete" && columnId !== "link"
      ? "pointer"
      : "default"};

  width: ${({ columnId }) => (columnId === "edit" ? "10px" : "")};

  > div {
    display: flex;
    align-items: center;
    gap: 5px;

    > div {
      width: 60px;
    }
  }
`;
