import style from "styled-components";
import { styled } from "@mui/material/styles";
import { TableCell } from "@mui/material";

export const StyledEditInput = style.input`
  width: 100%;
  border-bottom-color: #e9e9e9;
`;

export const StyledTableCell = styled(TableCell)`
  cursor: ${({ columnId }) =>
    columnId !== "details" &&
    columnId !== "block" &&
    columnId !== "id" &&
    columnId !== "category"
      ? "pointer"
      : "default"};

  > div {
    display: flex;
    align-items: center;
    gap: 5px;

    > div {
      width: 60px;
    }
  }
`;
