import { db } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  GeoPoint,
  getDoc,
  Timestamp,
  DocumentReference,
} from "firebase/firestore";
import { TABLES } from "../enums/tables";

export async function getFirebaseData(collectionName) {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const data = querySnapshot.docs.map((doc) => doc.data());
  const serializedData = await serializeData(data);
  return serializedData;
}

export async function getFirebaseItem(collectionName, id) {
  const querySnapshot = await getDoc(doc(db, collectionName, id));

  return querySnapshot.data();
}

export async function getConnectpoints() {
  const ref = collection(db, TABLES.CONNECTPOINTS);
  const q = query(ref, orderBy("created_at", "desc"));
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => doc.data());
  const serializedData = await serializeData(data);
  return serializedData;
}

export async function getFirebaseDataByCreationDate(collectionName) {
  const ref = collection(db, collectionName);
  const q = query(ref, orderBy("created_at", "desc"));
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => doc.data());
  const serializedData = await serializeData(data);
  return serializedData;
}

export async function getFirebaseCollectionWithQuery(collectionName, queries) {
  const ref = collection(db, collectionName);
  const q = query(ref, ...queries);
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => doc.data());
  const serializedData = await serializeData(data);
  return serializedData;
}

export const getDocumentByRef = async (ref) => {
  const snapshot = await getDoc(ref);
  const document = snapshot.data();
  const serializedData = await serializeData(document);
  return serializedData;
};

export const transformTimestamp = (timestamp) => {
  return timestamp.toDate().toISOString();
};

export const transformGeoPoint = (geoPoint) => {
  return { latitude: geoPoint.latitude, longitude: geoPoint.longitude };
};

export const serializeData = async (data) => {
  if (data instanceof Timestamp) {
    return transformTimestamp(data);
  }

  if (data instanceof DocumentReference) {
    return await getDocumentByRef(data);
  }

  if (data instanceof GeoPoint) {
    return transformGeoPoint(data);
  }

  if (Array.isArray(data)) {
    const promises = data.map((item) => serializeData(item));
    return Promise.all(promises);
  }

  if (data && typeof data === "object" && !(data instanceof Date)) {
    const entries = await Promise.all(
      Object.entries(data).map(async ([key, value]) => {
        const serializedValue = await serializeData(value);
        return [key, serializedValue];
      })
    );
    return Object.fromEntries(entries);
  }

  return data;
};

export const getFirebaseCollectionItem = async (collectionName, itemId) => {
  const docRef = doc(db, collectionName, itemId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  const serializedData = await serializeData(data);
  return serializedData;
};
