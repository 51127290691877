import { create } from "zustand";

const useHighlightModal = create((set) => ({
  isOpen: false,
  replaceConfirmation: false,
  existingHighlight: null,
  updateExistingHighlight: (newItem) => set({ existingHighlight: newItem }),
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  onAgreeReplace: () => set({ replaceConfirmation: true }),
  onReset: () =>
    set({ isOpen: false, replaceConfirmation: false, existingHighlight: null }),
}));

export default useHighlightModal;
