/***
 *
 * In this Component we Display details for TuristicPointReport
 */

import { ArrowBack } from "@mui/icons-material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import {
  TitleWithTwoDots,
  OverlayBody,
  OverlayChild,
  OverlayContainer,
  OverlayHeader,
  Title,
  ActionButtons,
} from "../../../Components/Global/Reusable";
import { dateFormatter } from "../../../utils/formatter";
import { DeleteRow, selectAll, ToggleActive } from "../../../features/DenunciationSlice";
import * as reports from "../../../Services/reports";
import { getFirebaseItem } from "../../../Services/firebase";
import { useEffect } from "react";
import { TABLES } from "../../../enums/tables";

const typeLabel = {
  connectguides: "ConnectGuides",
  connectsales: "ConnectSales",
  connectstores: "ConnectStores",
  connectplaces: "ConnectPlaces",
  events: "Eventos",
};

export default function Index({
  CloseDetails,
  detailsID,
  OpenModal,
  onToastOpen,
}) {
  const [commentData, setCommentData] = useState();
  const DenunciationData = useSelector(selectAll);
  const reportData = DenunciationData.filter((item) => item.id === detailsID);
  const [report] = reportData;
  const dispatch = useDispatch();

  useEffect(() => {
    if (report.comment?.id) {
      (async () => {
        try {
          const data = await getFirebaseItem(
            TABLES.COMMENTS,
            report.comment?.id
          );
          setCommentData(data);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [report.comment?.id]);

  //Method to Approve
  async function Active() {
    try {
      await reports.reactiveComment(
        report.id,
        commentData.id
      );
      dispatch(
        ToggleActive({ id: commentData.id, active: true })
      );
      dispatch(
        DeleteRow({ id: report.id})
      );
      CloseDetails();
    } catch (error) {
      console.error(error);
    }
  }
  async function DeleteComment() {
    try {
      await reports.deleteComment(
        report.id,
        commentData.id
      );
      dispatch(
        DeleteRow({ id: report.id})
      );
      CloseDetails();
    } catch (error) {
      console.error(error);
    }
  }
  
  return (
    <OverlayContainer>
      <OverlayChild style={{ width: "400px" }}>
        <OverlayHeader>
          <ArrowBack onClick={CloseDetails} />
          <Title>Detalhes</Title>
        </OverlayHeader>
        {reportData.map((item, index) => (
          <OverlayBody
            style={{
              gap: "15px",
              paddingTop: "30px",
              alignItems: "flex-start",
            }}
            key={index}
          >
            <TitleWithTwoDots
              title="Reportado por"
              description={item.reported_by.name}
            />
            <TitleWithTwoDots
              title="Quem"
              description={item.comment?.owner.name}
            />
            <TitleWithTwoDots
              title="Data"
              description={dateFormatter.format(new Date(item.created_at))}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <TitleWithTwoDots
                title="Onde"
                description={`${typeLabel[item.collection]} - ${
                  item.location?.name
                }`}
              />
              <p style={{ color: "#949494", fontSize: ".7rem", margin: "0" }}>
                (
                <a
                  href={`https://${
                    process.env.REACT_APP_CONNECTOUR_DOMAIN +
                    "/pt-BR/" +
                    item.collection +
                    "/" +
                    item.location?.id
                  }`}
                  target="_blank"
                  style={{ color: "#03738C" }}
                  rel="noreferrer"
                >
                  clique aqui
                </a>{" "}
                para visualizar a página)
              </p>
            </div>
            <TitleWithTwoDots
              title="Comentario"
              description={item.comment?.content}
              under
            />

            <ActionButtons
              onToastOpen={onToastOpen}
              id={item?.id}
              approve={Active}
              reprove={DeleteComment}
              activeTitle="Reativar"
              blockTitle="Excluir"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
             /> 
          </OverlayBody>
        ))}
      </OverlayChild>
    </OverlayContainer>
  );
}
