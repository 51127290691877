import styled from "styled-components";

export const Type = styled.div`
  border: 0.1rem solid ${(props) => props.sx?.color};
  color: ${(props) => props.sx?.color};
  & > div {
    background-color: ${(props) => props.sx?.color};
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  max-width: fit-content;
  font-size: 1rem;
  font-weight: normal;
  border-radius: 2.1rem;
  padding: 0.2rem 1rem 0.2rem 0.2rem;

  .circle {
    min-width: 1rem;
    min-height: 1rem;
    border-radius: 9999px;
  }

  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 69px;
    font-size: 0.75rem;
  }
`;
