import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";
import { getFirebaseCollectionItem } from "./firebase";
import { PAYMENT_STATUS } from "../enums/plans";

const TOURIST_GUIDE_STATUS = Object.freeze({
  approved: "approved",
  reproved: "reproved",
  pending: "pending",
});

export async function update(touristGuideData) {
  const {
    name,
    phone: tel,
    code: fiscal_code,
    institute: instituition,
    description,
    images,
    status,
    email,
  } = touristGuideData;

  const documentRef = doc(db, TABLES.CONNECTSALES, touristGuideData.id);

  return updateDoc(documentRef, {
    name,
    contact: { email, phone: tel },
    fiscal_code,
    institution: instituition,
    description,
    images,
    status,
  });
}

export async function block(id, blockValue) {
  const touristGuideRef = doc(db, TABLES.CONNECTSALES, id);

  await updateDoc(touristGuideRef, {
    active: blockValue,
  });
}

export async function approve(id) {
  const touristGuideRef = doc(db, TABLES.CONNECTSALES, id);

  await updateDoc(touristGuideRef, {
    active: true,
  });
}

export async function reprove(id) {
  const touristGuideRef = doc(db, TABLES.CONNECTSALES, id);

  await updateDoc(touristGuideRef, {
    active: false,
  });
}

export async function approveDocuments(guideId) {
  const documentRef = doc(db, TABLES.CONNECTSALES, guideId);

  await updateDoc(documentRef, {
    "document.status": PAYMENT_STATUS.APPROVED,
  });
}

export async function rejectDocuments(guideId) {
  const documentRef = doc(db, TABLES.CONNECTSALES, guideId);

  updateDoc(documentRef, {
    "document.status": PAYMENT_STATUS.REPROVED,
  });
}

export const getConnectSaleById = async (id) => {
  const connectGuide = await getFirebaseCollectionItem(TABLES.CONNECTSALES, id);

  return connectGuide;
};
