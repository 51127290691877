import styled from "styled-components";
import { TableCell } from "@mui/material";

export const StyledEditInput = styled.input`
  width: 100%;
  border-bottom-color: #e9e9e9;
`;

export const Ball = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({ bColor }) => bColor};
  border-radius: 50%;
`;

export const TypeListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledTableCell = styled(TableCell)`
  cursor: ${({ columnId }) =>
    columnId !== "category" &&
    columnId !== "edit" &&
    columnId !== "delete" &&
    columnId !== "id"
      ? "pointer"
      : "default"};

  > div {
    display: flex;
    align-items: center;
    gap: 5px;

    > div {
      width: 60px;
    }
  }
`;
