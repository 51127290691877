import { ArrowBack } from "@mui/icons-material";
import { Box, MenuItem, Select, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateCategory,
  selectAll,
  UpdateRow,
} from "../../../features/CategorySlice";
import { setCategoryState } from "../../../features/MenuSlice";
import * as S from "./styled";
import colorPickerImg from "../../../Assets/ColorPicker.png";
import { createCategory, updateCategory } from "../../../Services/categories";

export function Register({ editId, closeRegister, onToastOpen }) {
  const CategoryState = useSelector((state) => state.menu.CategoryState);
  const [itemToEdit] = useSelector(selectAll).filter(
    (item) => item.id === editId
  );

  return (
    <>
      {CategoryState && (
        <S.OverlayContainer>
          <S.RegisterContainer>
            <S.RegisterHeader>
              <ArrowBack onClick={closeRegister} />
              <h3>
                {itemToEdit ? "Edição de Categoria" : "Cadastro de Categorias"}
              </h3>
            </S.RegisterHeader>

            <S.RegisterBody>
              <CreateForm
                onToastOpen={onToastOpen}
                itemToEdit={itemToEdit}
                closeRegister={closeRegister}
              />
            </S.RegisterBody>
          </S.RegisterContainer>
        </S.OverlayContainer>
      )}
    </>
  );
}

function CreateForm({ itemToEdit, closeRegister, onToastOpen }) {
  const categoriesTypes = {
    connectroutes: "ConnectRoutes",
    connectstores: "ConnectStores",
    connectplaces: "ConnectPlaces",
  };
  const [currentLanguage, setCurrentLanguage] = useState("pt-BR");
  const [color, setColor] = useState(() => {
    if (itemToEdit && itemToEdit.color) {
      return itemToEdit.color;
    }
    return "#181764";
  });
  const [names, setNames] = useState(() => {
    if (itemToEdit && itemToEdit.names) {
      return itemToEdit.names;
    }
    return {
      "pt-BR": "",
      "en-US": "",
      es: "",
      fr: "",
      it: "",
      zh: "",
    };
  });
  const [type, setType] = useState(() => {
    if (itemToEdit && itemToEdit.collection) {
      return itemToEdit.collection;
    }
    return "";
  });
  const [progress, setProgress] = useState(false);
  const dispatch = useDispatch();
  const isCorrectForm = Object.values(names).some(Boolean) && type;

  const handleSubmit = async (self) => {
    self.preventDefault();
    setProgress(true);

    if (itemToEdit) {
      try {
        const newCategory = {
          ...itemToEdit,
          names,
          collection: type,
          color,
        };
        await updateCategory(newCategory);
        dispatch(UpdateRow(newCategory));
        closeRegister();
        onToastOpen("Success", "success");
      } catch (error) {
        setProgress(false);
        console.error(error);
        onToastOpen("Error", "error");
      }
      return;
    }

    try {
      const id = await createCategory({ names, color, collection: type });
      dispatch(CreateCategory({ id, names, color, collection: type }));
      onToastOpen("Success", "success");
      closeRegister();
    } catch (error) {
      setProgress(false);
      console.error(error);
      onToastOpen("Error", "error");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <S.InputGroup>
          <label htmlFor="name">Nome</label>
          <select
            name="languages"
            onChange={(e) => setCurrentLanguage(e.target.value)}
            value={currentLanguage}
          >
            <option value="pt-BR">PT-BR</option>
            <option value="en-US">EN-US</option>
            <option value="es">ES</option>
            <option value="fr">FR</option>
            <option value="it">IT</option>
            <option value="zh">ZH</option>
          </select>
          {Object.entries(names).map(([key, value]) => (
            <>
              {currentLanguage === key && (
                <input
                  key={key}
                  type="text"
                  required
                  value={value}
                  onChange={(self) =>
                    setNames((state) => ({
                      ...state,
                      [key]: self.target.value,
                    }))
                  }
                />
              )}
            </>
          ))}
        </S.InputGroup>

        <S.InputGroup>
          <label>Tipo</label>
          <Select
            variant="standard"
            value={type}
            displayEmpty
            onChange={(self) => setType(self.target.value)}
            renderValue={() => {
              if (!type) {
                return <em>Selecione o tipo da categoria</em>;
              }

              return categoriesTypes[type];
            }}
          >
            <MenuItem disabled value="">
              <em>Selecione o tipo da categoria</em>
            </MenuItem>
            {Object.entries(categoriesTypes).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                <em>{value}</em>
              </MenuItem>
            ))}
          </Select>
        </S.InputGroup>
      </Box>

      <S.ColorPicker>
        <img src={colorPickerImg} alt="colorpickerimage" />
        <input
          type="color"
          required
          onChange={(self) => setColor(self.target.value)}
        />
        <S.SelectedColor color={color} />
      </S.ColorPicker>
      <S.RegisterButton disabled={!isCorrectForm || progress} type="submit">
        {itemToEdit ? "Salvar" : "Cadastrar"}
      </S.RegisterButton>
      {progress && <LinearProgress />}
    </form>
  );
}
