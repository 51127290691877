/***
 *
 * In this Component we Display details for TuristicPointReport
 */

import { ArrowBack } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import image from "../../../Assets/Images/avatar1.webp";
import {
  OverlayBody,
  OverlayChild,
  OverlayContainer,
  OverlayHeader,
  Title,
} from "../../../Components/Global/Reusable";
import { selectAll } from "../../../features/PlansReportSlice";

export default function Index({ CloseDetails, detailsID }) {
  const reportData = useSelector(selectAll);
  return (
    <OverlayContainer>
      <OverlayChild style={{ width: "400px" }}>
        <OverlayHeader>
          <ArrowBack onClick={() => CloseDetails()} />
          <Title>Detalhes</Title>
        </OverlayHeader>
        {reportData
          .filter((item) => item.id === detailsID)
          .map((item, index) => (
            <OverlayBody
              style={{ gap: "15px", paddingTop: "40px" }}
              key={index}
            >
              <PlansInfo data={item} />
            </OverlayBody>
          ))}
      </OverlayChild>
    </OverlayContainer>
  );
}

export const PlansInfo = ({ data }) => {
  return (
    <StyledPlans>
      <div style={{ display: "grid", gap: "5px" }}>
        <Title style={{ margin: "5px 0 0 0" }}>{data.name}</Title>
        <div style={{ display: "grid", gap: "7px" }}></div>
      </div>
    </StyledPlans>
  );
};

/*****
 * Styled Components
 */

const StyledPlans = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 0.8rem;
  }
`;
