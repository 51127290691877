/****
 * In this component we Declare Labels for the table
 * So I decided to separate these components to make our code more reusable
 */
import React, { useState } from "react";
import { StyledTableCell } from "./styled";
import { ArrowDown, ArrowUp } from "phosphor-react";
import DateFilter from "../../../Components/DateFilter/DateFilter";

//At this point we declare Labels for the finance page
const Labels = [
  { id: "id", label: "ID", minWidth: 60, align: "left" },
  { id: "name", label: "Usuário", minWidth: 60, align: "center" },
  { id: "type", label: "Tipo do plano", minWidth: 100, align: "center" },
  { id: "model", label: "Nome do plano", minWidth: 110, align: "center" },
  {
    id: "date",
    label: "Data",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "transaction",
    label: "Transação",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 60,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "total",
    label: "Total",
    minWidth: 60,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

export default function Index({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  setIsTransactionFilter,
  setCurrencyFilter,
  setModalStatusFilter,
  setModalTypeFilter,
  setModalPlanFilter,
}) {
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }

  function openTransactionFilter() {
    setIsTransactionFilter((prev) => ({ isOpen: true, data: prev.data }));
  }

  function openTypeFilter() {
    setModalTypeFilter((prev) => ({ isOpen: true, data: prev.data }));
  }

  function openPlanFilter() {
    setModalPlanFilter((prev) => ({ isOpen: true, data: prev.data }));
  }

  function openCurrencyFilter() {
    setCurrencyFilter((prev) => ({ isOpen: true, value: prev.value }));
  }

  function openStatusFilter() {
    setModalStatusFilter((prev) => ({ ...prev, isOpen: true }));
  }

  return (
    <>
      {Labels.map((column) => {
        return (
          <StyledTableCell
            columnId={column.id}
            key={column.id}
            align={column?.align}
          >
            <div>
              <div
                onClick={(e) => {
                  if (column.id === "total") {
                    e.stopPropagation();
                    return openCurrencyFilter();
                  }
                  if (column.id === "status") {
                    e.stopPropagation();
                    return openStatusFilter();
                  }
                  if (column.id === "date") {
                    e.stopPropagation();
                    return openDateFilter();
                  }
                  if (column.id === "transaction") {
                    e.stopPropagation();
                    return openTransactionFilter();
                  }
                  if (column.id === "model") {
                    e.stopPropagation();
                    return openPlanFilter();
                  }
                  if (column.id === "type") {
                    e.stopPropagation();
                    return openTypeFilter();
                  }
                  handleChangeOrder(column.id);
                }}
                style={{
                  whiteSpace: "nowrap",
                  minWidth: `${column.minWidth}px`,
                  textAlign: column.align,
                }}
              >
                {column.label}
              </div>

              {column.id !== "id" && column.id !== "date" && (
                <div onClick={() => handleChangeOrder(column.id)}>
                  <ArrowUp
                    size={16}
                    weight="regular"
                    color={`${
                      orderFilter &&
                      orderFilter.id === column.id &&
                      orderFilter.order === "desc"
                        ? "#06bee7"
                        : "rgba(0, 0, 0, 0.87)"
                    }`}
                  />
                  <ArrowDown
                    color={`${
                      orderFilter &&
                      orderFilter.id === column.id &&
                      orderFilter.order === "asc"
                        ? "#06bee7"
                        : "rgba(0, 0, 0, 0.87)"
                    }`}
                    size={16}
                    weight="regular"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
              )}
            </div>
          </StyledTableCell>
        );
      })}
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
