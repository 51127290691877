import { createSlice } from "@reduxjs/toolkit";

const UsersReportSlice = createSlice({
  name: "usersReport",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
  },
});

export const selectAll = (state) => state.usersReport.value;
export const { UpdateRow, UpdateValue } = UsersReportSlice.actions;
export default UsersReportSlice.reducer;
