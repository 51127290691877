import { StyledTableCell } from "./styles";
import React from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";

export function ExploreLabel({ orderFilter, setOrderFilter }) {
  const columns = [
    { id: "name", label: "Nome", align: "left" },
    { id: "link", label: "Link", align: "left" },
    { id: "country", label: "País", align: "left" },
    { id: "region", label: "Região", align: "left" },
    { id: "city", label: "Cidade", align: "left" },
    { id: "edit", label: "Editar", align: "right" },
    { id: "delete", label: "Excluir", align: "right" },
  ];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  return (
    <>
      {columns.map((column) => {
        return (
          <StyledTableCell
            onClick={() => handleChangeOrder(column.id)}
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "edit" &&
                column.id !== "delete" &&
                column.id !== "link" && (
                  <div>
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        orderFilter &&
                        orderFilter.id === column.id &&
                        orderFilter.order === "desc"
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        orderFilter &&
                        orderFilter.id === column.id &&
                        orderFilter.order === "asc"
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
    </>
  );
}
