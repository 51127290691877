import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";

const STABLISHMENT_STATUS = Object.freeze({
  Approved: true,
  Reproved: false,
});

export async function block(id, blockValue) {
  const stablishmentReference = doc(db, TABLES.CONNECTSTORES, id);

  await updateDoc(stablishmentReference, {
    active: blockValue,
  });
}

export async function approve(id) {
  const stablishmentReference = doc(db, TABLES.CONNECTSTORES, id);

  await updateDoc(stablishmentReference, {
    active: true,
  });
}

export async function reprove(id) {
  const stablishmentReference = doc(db, TABLES.CONNECTSTORES, id);

  await updateDoc(stablishmentReference, {
    active: false,
  });
}
