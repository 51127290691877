import { Select } from "@mui/material";
import styled from "styled-components";
import { TableCell } from "@mui/material";

/***
 * Style Rules for Agenda Component
 */

export const styledCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

/****
 * CreateCategory StyledCompoent
 *
 */

export const RegisterBody = styled.div`
  padding: 15px 20px;

  form {
    display: grid;
    gap: 15px;
    padding-bottom: 2rem;

    div {
      border-radius: 5px;
    }
  }
`;

/**
 * Styled File Input
 * **/
export const FileInputWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  padding: 0.6rem;
  height: 120px;

  div {
    position: relative;
    display: grid;
    place-items: center;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50% !important;
    padding: ${({ file }) => (file ? "0" : "1.5rem;")};
    overflow: hidden;

    img {
      min-width: 90px;
      width: 90px;
      max-width: 90px;
      min-height: 90px;
      height: 90px;
      max-height: 90px;
      object-fit: cover;
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

/****
 * Multiple select
 */

export const MultipleSelect = styled.div`
  display: grid;
  gap: 6px;
`;
export const CustomSelect = styled(Select)`
  min-height: 20px;
  height: 35px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  outline-color: ${({ theme }) => theme.colors.primary};
`;

export const ModalContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const StyledTableCell = styled(TableCell)`
  cursor: ${({ columnId }) =>
    columnId !== "category" &&
    columnId !== "edit" &&
    columnId !== "delete" &&
    columnId !== "id"
      ? "pointer"
      : "default"};

  > div {
    display: flex;
    align-items: center;
    gap: 5px;

    > div {
      width: 60px;
    }
  }
`;
