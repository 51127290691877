import { createSlice } from "@reduxjs/toolkit";

const ShopsReportSlice = createSlice({
  name: "shopsReport",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
  },
});

export const selectAll = (state) => state.shopsReport.value;
export const { UpdateRow, UpdateValue } = ShopsReportSlice.actions;
export default ShopsReportSlice.reducer;
