import { StyledTableCell } from "./styled";
import React from "react";
import { usePlansTrends } from "../../../Hooks/usePlansTrends";
import { ArrowDown, ArrowUp } from "phosphor-react";

/****
 * LABELS FOR Category TABLE TO MAKE OUR TABLE REUSABLE
 */
export default function Index({ orderFilter, setOrderFilter }) {
  const { showHighlights } = usePlansTrends();
  /*Este sao dados para monstrar na tabela (COLUMNS)! 
Como estamos na fase de teste, usei estes dados como amostra
*/
  const columnsPlans = [
    { id: "name", label: "Nome", align: "left" },
    { id: "type", label: "Tipo", align: "left" },
    { id: "brl", label: "Valor(R$)", align: "left" },
    { id: "usd", label: "Valor($)", align: "left" },
    { id: "eur", label: "Valor(€)", align: "left" },
    { id: "edit", label: "Editar", align: "right" },
    { id: "delete", label: "Excluir", align: "right" },
  ];
  const columnsTrends = [
    { id: "trendsType", label: "Tipo", align: "left" },
    { id: "trendsPlace", label: "Local", align: "left" },
    { id: "trendsDays", label: "Dias", align: "left" },
    { id: "trendsBrl", label: "Valor(R$)", align: "left" },
    { id: "trendsUsd", label: "Valor($)", align: "left" },
    { id: "trendsEur", label: "Valor(€)", align: "left" },
    { id: "edit", label: "Editar", align: "left" },
  ];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  return (
    <>
      {showHighlights ? (
        <>
          {
            //=>Here we looping through our received data from the props
            columnsTrends.map((column, index) => {
              return (
                <StyledTableCell
                  onClick={() => handleChangeOrder(column.id)}
                  columnId={column.id}
                  key={column.id}
                  align={column.align}
                >
                  <div>
                    {column.label}
                    {column.id !== "edit" &&
                      column.id !== "delete" &&
                      column.id !== "trendsDays" &&
                      column.id !== "trendsBrl" &&
                      column.id !== "trendsUsd" &&
                      column.id !== "trendsEur" && (
                        <div>
                          <ArrowUp
                            size={16}
                            weight="regular"
                            color={`${
                              orderFilter &&
                              orderFilter.id === column.id &&
                              orderFilter.order === "desc"
                                ? "#06bee7"
                                : "rgba(0, 0, 0, 0.87)"
                            }`}
                          />
                          <ArrowDown
                            color={`${
                              orderFilter &&
                              orderFilter.id === column.id &&
                              orderFilter.order === "asc"
                                ? "#06bee7"
                                : "rgba(0, 0, 0, 0.87)"
                            }`}
                            size={16}
                            weight="regular"
                            style={{ marginLeft: "-4px" }}
                          />
                        </div>
                      )}
                  </div>
                </StyledTableCell>
              );
            })
          }
        </>
      ) : (
        <>
          {
            //=>Here we looping through our received data from the props
            columnsPlans.map((column, index) => {
              return (
                <StyledTableCell
                  onClick={() => handleChangeOrder(column.id)}
                  columnId={column.id}
                  key={column.id}
                  align={column.align}
                >
                  <div>
                    {column.label}
                    {column.id !== "edit" && column.id !== "delete" && (
                      <div>
                        <ArrowUp
                          size={16}
                          weight="regular"
                          color={`${
                            orderFilter &&
                            orderFilter.id === column.id &&
                            orderFilter.order === "desc"
                              ? "#06bee7"
                              : "rgba(0, 0, 0, 0.87)"
                          }`}
                        />
                        <ArrowDown
                          color={`${
                            orderFilter &&
                            orderFilter.id === column.id &&
                            orderFilter.order === "asc"
                              ? "#06bee7"
                              : "rgba(0, 0, 0, 0.87)"
                          }`}
                          size={16}
                          weight="regular"
                          style={{ marginLeft: "-4px" }}
                        />
                      </div>
                    )}
                  </div>
                </StyledTableCell>
              );
            })
          }
        </>
      )}
    </>
  );
}
