import { StyledTableCell } from "./styles";
import React from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";
import DateFilter from "../../../Components/DateFilter/DateFilter";
import { useState } from "react";

export function DenunciationLabel({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
}) {
  const columns = [
    { id: "reportedBy", label: "Reportado por", align: "left" },
    { id: "date", label: "Data", align: "left" },
    { id: "details", label: "Detalhes", align: "right" },
  ];

  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }

  return (
    <>
      {columns.map((column) => {
        return (
          <StyledTableCell
            onClick={
              column.id === "date"
                ? openDateFilter
                : () => handleChangeOrder(column.id)
            }
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "details" &&
                column.id !== "block" &&
                column.id !== "category" &&
                column.id !== "code" && (
                  <div>
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        orderFilter &&
                        orderFilter.id === column.id &&
                        orderFilter.order === "desc"
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        orderFilter &&
                        orderFilter.id === column.id &&
                        orderFilter.order === "asc"
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
