import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { TABLES } from "../enums/tables";

export async function createExplore(exploreData) {
  const documentRef = doc(collection(db, TABLES.CONNECTWATCHES));
  const { video_url, country, region, city, name, image_url } = exploreData;

  let imagesUrl;
  let newUrl;

  if (typeof video_url === "string") {
    if (
      video_url.substring(0, 8) !== "https://" &&
      video_url.substring(0, 7) !== "http://"
    )
      newUrl = `https://${video_url}`;
    newUrl = newUrl?.replace(/&ab_channel=.+|$/, "");
  }

  if (!image_url?.name) {
    imagesUrl = image_url;
  } else {
    imagesUrl = await uploadBytes(
      ref(storage, `images/${image_url.name}${crypto.randomUUID()}`),
      image_url
    ).then((snapshot) => getDownloadURL(snapshot.ref).then((url) => url));
  }

  if (!video_url?.name) {
    newUrl = video_url;
  } else {
    newUrl = await uploadBytes(
      ref(storage, `videos/${video_url.name}${crypto.randomUUID()}`),
      video_url
    ).then((snapshot) => getDownloadURL(snapshot.ref).then((url) => url));
  }

  const now = serverTimestamp();

  const newExplore = {
    video_url: newUrl,
    country,
    city,
    region,
    name,
    image_url: imagesUrl,
    id: documentRef.id,
    owner: doc(db, TABLES.USERS, exploreData.owner),
    is_admin: true,
    collection_name: TABLES.CONNECTWATCHES,
    created_at: now,
    updated_at: now,
    active: true,
  };

  await setDoc(documentRef, newExplore);
  return newExplore;
}

export async function updateExplore(exploreData) {
  const { video_url, country, region, city, name, id, image_url } = exploreData;

  let imagesUrl;
  const documentRef = doc(db, TABLES.CONNECTWATCHES, id);

  let newUrl = video_url;
  if (typeof link === "string") {
    if (
      video_url.substring(0, 8) !== "https://" &&
      video_url.substring(0, 7) !== "http://"
    )
      newUrl = `https://${video_url}`;
    newUrl = newUrl.replace(/&ab_channel=.+|$/, "");
  }

  if (!image_url?.name) {
    imagesUrl = image_url;
  } else {
    imagesUrl = await uploadBytes(
      ref(storage, `images/${image_url.name}${crypto.randomUUID()}`),
      image_url
    ).then((snapshot) => getDownloadURL(snapshot.ref).then((url) => url));
  }

  if (!video_url?.name) {
    newUrl = video_url;
  } else {
    newUrl = await uploadBytes(
      ref(storage, `videos/${video_url.name}${crypto.randomUUID()}`),
      video_url
    ).then((snapshot) =>
      getDownloadURL(snapshot.ref).then((url) => (newUrl = url))
    );
  }

  const updatedExplore = {
    video_url: newUrl,
    country,
    city,
    region,
    name,
    image_url: imagesUrl,
  };
  await updateDoc(documentRef, updatedExplore);

  return updatedExplore;
}

export async function removeExplore(exploreData) {
  const { id } = exploreData;

  return deleteDoc(doc(db, TABLES.CONNECTWATCHES, id));
}
