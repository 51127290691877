/**
 * User Login Component
 * In this component we write all the Login Funcionalities
 * We are going to include Authetication
 * As In our Sysytem we have two types of Clients (Admin and Standard User)
 * We will have to Autheticate for both Clients
 *
 * ********************LOGIC****************
 * As we stored in our database a key => user role
 * So we are going to use that key (user_role) to verify user role ,
 * and we are going to redirect to User Dashboard if role is Admin else to Standard User Page
 */

import React, { useState } from "react";
import {
  FormHeader,
  InputGroup,
  LinkContainer,
} from "../../Admin/Login/styled";
import LoginBanner from "../../../Assets/bgImage.png";
import {
  UserContainer,
  UserLoginContainer,
  LoginUpSide,
  LoginDownSide,
} from "./styled";
import { useDispatch } from "react-redux";
import { Login } from "../../../features/LoginSlice";
import { Alert } from "@mui/material";

import Logo from "../../../Assets/Images/logo02.webp";

import { Link, useNavigate } from "react-router-dom";

function Index() {
  return (
    <UserContainer maxWidth="xl">
      <UserLoginContainer maxWidth="sm">
        <LoginUpSide>
          <img src={LoginBanner} alt="Login Banner" />
        </LoginUpSide>
        {/* <LoginDownSide> */}
        {/* <LoginForm /> */}
        {/* </LoginDownSide> */}
      </UserLoginContainer>
    </UserContainer>
  );
}

export default Index;

function LoginForm() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  function handleLogin(e) {
    e.preventDefault();
  }
  return (
    <React.Fragment>
      <FormHeader>
        <img src={Logo} alt="OfficialLogo" />
        <h5>Login</h5>
      </FormHeader>

      <form onSubmit={handleLogin}>
        <InputGroup>
          <label>Email</label>
          <input
            type="email"
            name="email"
            required
            onChange={(self) => setEmail(self.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <label>Senha</label>
          <input
            type="password"
            name="password"
            required
            onChange={(self) => setPassword(self.target.value)}
          />
        </InputGroup>

        {error && <Alert severity="error">{error}</Alert>}

        <LinkContainer>
          <Link to="/">Esquece minha senha</Link>
          <button type="submit">Login</button>
        </LinkContainer>
      </form>
    </React.Fragment>
  );
}

/***
 * Declare a method to add data to localStorage
 */

function AddUser(user_id, user_role, user_email) {
  localStorage.setItem("user_id", user_id);
  localStorage.setItem("user_role", user_role);
  localStorage.setItem("user_email", user_email);
  localStorage.setItem("user_status", true);
}
