import { Card, Box, Grid } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import TextEditor from "../../../Components/TextEditor";
import { InputGroup } from "../../../Components/Global/Reusable";
import { AppContainer } from "../../../Components/Global/Reusable";
import { EditorState } from "draft-js";
import { getFirebaseData } from "../../../Services/firebase";
import { updateTerms } from "../../../Services/termsOfUse";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { TABLES } from "../../../enums/tables";

function Index() {
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });

  const timerRef = useRef(0);

  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  const [currentType, setCurrentType] = useState("terms");
  const [allTerms, setAllTerms] = useState({
    "pt-BR": "",
    "en-US": "",
    es: "",
    fr: "",
    it: "",
    zh: "",
  });
  const [allPrivacy, setAllPrivacy] = useState({
    "pt-BR": "",
    "en-US": "",
    es: "",
    fr: "",
    it: "",
    zh: "",
  });
  const [currentLanguage, setCurrentLanguage] = useState("pt-BR");

  const contentState = editorState.getCurrentContent();
  const markdownString = stateToMarkdown(contentState);
  const isFirstRender = useRef(true);

  const handleChangeTerms = (e) => {
    setEditorState(e);
    const contentState = e.getCurrentContent();
    const markdownString = stateToMarkdown(contentState);
    const stringWithoutPlus = markdownString.replaceAll("++", "");
    if (currentType === "terms") {
      setAllTerms((state) => ({
        ...state,
        [currentLanguage]: stringWithoutPlus,
      }));
    } else {
      setAllPrivacy((state) => ({
        ...state,
        [currentLanguage]: stringWithoutPlus,
      }));
    }
  };

  async function getDataFromFirebase(collection) {
    const data = await getFirebaseData(collection);

    data.forEach((item) => {
      if (item.type === "email") {
        setEmail(item.value);
      }
      if (item.type === "whatsapp") {
        setWhatsapp(item.value);
      }
      if (item.type === "privacy_policy") {
        setAllPrivacy(item.value);
      }
      if (item.type === "terms_of_use") {
        setAllTerms(item.value);
        const currentMarkdown = item.value[currentLanguage] || "";
        const contentState = stateFromMarkdown(currentMarkdown);
        setEditorState(EditorState.createWithContent(contentState));
      }
    });

    /* if (data) {
      setWhatsapp(data.whatsapp);
      setAllTerms((state) => ({ ...state, ...data.termsOfUse }));
      setAllPrivacy((state) => ({ ...state, ...data.privacyPolicy }));

      const currentMarkdown = terms.termsOfUse[currentLanguage] || "";
      const contentState = stateFromMarkdown(currentMarkdown);
      setEditorState(EditorState.createWithContent(contentState));
    } */
  }

  useEffect(() => {
    getDataFromFirebase(TABLES.LEGAL_DOCUMENTS);
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const contentState = stateFromMarkdown(
      currentType === "terms"
        ? allTerms?.[currentLanguage]
        : allPrivacy?.[currentLanguage]
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [allPrivacy?.value, allTerms?.value, currentLanguage, currentType]);

  async function handleSaveTerms() {
    try {
      await updateTerms({
        whatsapp,
        termsOfUse: allTerms,
        email,
        privacyPolicy: allPrivacy,
      });
      onToastOpen("Success", "success");
    } catch (error) {
      console.error(error);
      onToastOpen("Error", "error");
    }
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);

  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer
        maxWidth="xl"
        style={{
          paddingTop: "40px",
          minHeight: "calc(100vh - 110px)",
        }}
      >
        <Card
          style={{
            padding: "25px",
            display: "grid",
            gridTemplateRows: "34px 1fr",
            gap: "20px",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <Header
            link={email}
            setLink={setEmail}
            whatsapp={whatsapp}
            setWhatsapp={setWhatsapp}
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            currentType={currentType}
            setCurrentType={setCurrentType}
          />
          <TextEditor
            terms={editorState}
            setTerms={handleChangeTerms}
            saveTerms={handleSaveTerms}
            email={email}
            whatsapp={whatsapp}
            valueTextArea={markdownString}
          />
        </Card>
      </AppContainer>
    </>
  );
}

export default Index;

function Header({
  link,
  setLink,
  whatsapp,
  setWhatsapp,
  setCurrentLanguage,
  currentLanguage,
  currentType,
  setCurrentType,
}) {
  return (
    <Box>
      <Grid container style={{ gap: "1rem 0" }}>
        <Grid item lg={5}>
          <h3>
            {currentType === "terms"
              ? "Termos de uso"
              : "Política de privacidade"}
          </h3>
        </Grid>

        <Grid
          item
          lg={7}
          container
          spacing={2}
          style={{ alignContent: "center" }}
        >
          <Grid item xs>
            <SelectTermsOrPrivacy
              label="Tipo"
              currentType={currentType}
              setCurrentType={setCurrentType}
            />
          </Grid>
          <Grid item xs>
            <SelectLanguage
              label="Idioma"
              currentLanguage={currentLanguage}
              setCurrentLanguage={setCurrentLanguage}
            />
          </Grid>
          <Grid item xs>
            <InputGroup>
              <label>Email para contato</label>
              <input
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Adicionar um email para contato"
              />
            </InputGroup>
          </Grid>

          <Grid item xs>
            <InputGroup>
              <label>Fale conosco</label>
              <input
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                type="text"
                placeholder="Numero cadastrado no Whatsapp para contato"
              />
            </InputGroup>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const SelectLanguageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  select {
    font-size: 13.3px;
    padding: 5px 10px;
    height: 100%;
    border: 1px solid #03738c;
    width: 100%;
    height: 100%;
  }

  label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    font-size: 0.8rem;
  }
`;

function SelectLanguage({ label, currentLanguage, setCurrentLanguage }) {
  return (
    <SelectLanguageContainer>
      {!!label && <label>{label}</label>}
      <select
        name="languages"
        value={currentLanguage}
        onChange={(self) => setCurrentLanguage(self.target.value)}
      >
        <option value="pt-BR">PT-BR</option>
        <option value="en-US">EN-US</option>
        <option value="es">ES</option>
        <option value="fr">FR</option>
        <option value="it">IT</option>
        <option value="zh">ZH</option>
      </select>
    </SelectLanguageContainer>
  );
}

const SelectTypeContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  select {
    font-size: 13.3px;
    padding: 5px 10px;
    height: 100%;
    border: 1px solid #03738c;
    width: 100%;
    height: 100%;
  }

  label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    font-size: 0.8rem;
  }
`;

function SelectTermsOrPrivacy({ label, currentType, setCurrentType }) {
  return (
    <SelectTypeContainer>
      {!!label && <label>{label}</label>}
      <select
        name="languages"
        value={currentType}
        onChange={(self) => setCurrentType(self.target.value)}
      >
        <option value="terms">Termos de uso</option>
        <option value="privacy">Politicas de privacidade</option>
      </select>
    </SelectTypeContainer>
  );
}
