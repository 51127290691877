import React from "react";
import * as S from "./styled";

const TypePill = ({ name, color }) => {
  return (
    <>
      {name && color ? (
        <S.Type title={name} sx={{ color: color }}>
          <div className="circle"></div>
          <span className="title">{name}</span>
        </S.Type>
      ) : (
        <S.Type sx={{ color: "darkgray" }}>
          <div className="circle"></div>
          <span className="title">Sem categoria</span>
        </S.Type>
      )}
    </>
  );
};

export default TypePill;
