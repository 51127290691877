import { createSlice } from "@reduxjs/toolkit";

const QuerySlice = createSlice({
  name: "query",
  initialState: {
    query_string: '',
    queryByNumber: 10
  },
  reducers: {
    QueryData(state, action) {
      state.query_string = action.payload;
    },
    QueryByNum(state, action) {
      state.queryByNumber = action.payload;
    }
  }

})

export const QueryActions = QuerySlice.actions;
export default QuerySlice.reducer;
