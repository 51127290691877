import { Box, Card } from "@mui/material";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { PrimaryButton } from "../Button";

function Index({ terms, setTerms, saveTerms, email, whatsapp, valueTextArea }) {
  const isCorrectForm = !!email && !!valueTextArea;
  return (
    <Box
      style={{
        marginTop: "2rem",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 30px",
        gap: "10px",
      }}
    >
      <Editor
        editorState={terms}
        onEditorStateChange={setTerms}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        placeholder="Escreve aqui"
        toolbar={{
          options: ["inline", "list", "textAlign"],
          inline: {
            inDropdown: false,
            className: "customToolbar",
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline", "strikethrough"],
            bold: { className: "customToolbar" },
            italic: { className: "customToolbar" },
            underline: { className: "customToolbar" },
            strikethrough: { className: "customToolbar" },
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["unordered", "ordered", "indent", "outdent"],
            unordered: { className: "customToolbar" },
            ordered: { className: "customToolbar" },
            indent: { className: "customToolbar" },
            outdent: { className: "customToolbar" },
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["left", "center", "right", "justify"],
            left: { className: "customToolbar" },
            center: { className: "customToolbar" },
            right: { className: "customToolbar" },
            justify: { className: "customToolbar" },
          },
        }}
      />
      <PrimaryButton
        disabled={!isCorrectForm}
        style={{ justifySelf: "end", padding: "5px 30px" }}
        onClick={saveTerms}
      >
        Salvar
      </PrimaryButton>
    </Box>
  );
}

export default Index;
