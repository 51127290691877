import { TableCell, TableRow } from "@mui/material";
import { Edit, DeleteOutline } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAll,
  DeleteRow,
  UpdateRow,
  UpdateValue,
} from "../../../features/exploreSlice";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { removeExplore, updateExplore } from "../../../Services/explore";
import { Modal } from "../../../Components/Modal";
import { PrimaryButton } from "../../../Components/Button";
import { LoadingSpinner } from "../../../Components/Loader";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import { TABLES } from "../../../enums/tables";

export default function Index({ editItem, onToastOpen, orderFilter }) {
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const itineraryReportData = useSelector(selectAll);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [queryNum, query]);

  async function getDataFromFirebase(database) {
    const data = await getFirebaseDataByCreationDate(database);
    dispatch(UpdateValue(data));
  }

  useEffect(() => {
    try {
      getDataFromFirebase(TABLES.CONNECTWATCHES);
    } catch (error) {
      console?.error(error);
    }
  }, []);

  useEffect(() => {
    if (itineraryReportData?.length > 0) {
      const filtered = itineraryReportData
        ?.filter((row) => {
          const nameMatch = row?.name?.toLowerCase()?.includes(query);

          const countryMatch = row?.country?.toLowerCase()?.includes(query);

          const cityMatch = row?.city?.toLowerCase()?.includes(query);

          const regionMatch =
            row?.region && row?.region?.toLowerCase()?.includes(query);

          return nameMatch || regionMatch || countryMatch || cityMatch;
        })
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.name?.localeCompare(a?.name)
              : a?.name?.localeCompare(b?.name);
          }
          if (orderFilter && orderFilter?.id === "city") {
            return orderFilter?.order === "asc"
              ? b?.city?.localeCompare(a?.city)
              : a?.city?.localeCompare(b?.city);
          }
          if (orderFilter && orderFilter?.id === "country") {
            return orderFilter?.order === "asc"
              ? b?.country?.localeCompare(a?.country)
              : a?.country?.localeCompare(b?.country);
          }
          if (orderFilter && orderFilter?.id === "region") {
            return orderFilter?.order === "asc"
              ? a?.region && b?.region && b?.region?.localeCompare(a?.region)
              : a?.region && b?.region && a?.region?.localeCompare(b?.region);
          }
          return 0;
        });
      setFilteredData(filtered);
    }
  }, [query, itineraryReportData, orderFilter]);

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);

    return (
      <>
        {paginatedData?.map((row) => {
          return (
            <React.Fragment key={row?.id}>
              {/* {row?.id === editRowId ? (
                <EditableRow data={row} setEditId={setEditId} />
              ) : ( */}
              <ReadOnlyRow
                onToastOpen={onToastOpen}
                data={row}
                setEditId={editItem}
              />
              {/* )} */}
            </React.Fragment>
          );
        })}
        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"?.?.?."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />
      </>
    );
  }
}

export const ReadOnlyRow = ({ data: row, setEditId, onToastOpen }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDeleteExplore() {
    try {
      setIsDeleting(true);
      await removeExplore(row);
      dispatch(DeleteRow({ id: row?.id }));
      onToastOpen("Success", "success");
    } catch (error) {
      console?.error(error);
      onToastOpen("Error", "error");
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        onOpenChange={(open) => {
          if (!isDeleting) {
            setIsOpen(open);
          }
        }}
        title="Atenção!"
        description={`Tem certeza que deseja deletar "${row?.name}"?`}
      >
        <S.ModalContainer>
          <PrimaryButton onClick={() => setIsOpen(false)}>
            Cancelar
          </PrimaryButton>

          <PrimaryButton
            onClick={handleDeleteExplore}
            style={{ marginLeft: "auto" }}
          >
            {isDeleting ? <LoadingSpinner /> : "Confirmar"}
          </PrimaryButton>
        </S.ModalContainer>
      </Modal>

      <TableRow key={row?.id && row?.id}>
        <TableCell>{row?.name && row?.name}</TableCell>
        <TableCell>{row?.video_url && row?.video_url}</TableCell>
        <TableCell>{row?.country && row?.country}</TableCell>
        <TableCell>{row?.region || "--"}</TableCell>
        <TableCell>{row?.city?.replace("_", " ") || "--"}</TableCell>
        <TableCell align="right">
          {row?.is_admin ? (
            <Edit color="success" onClick={() => setEditId(row?.id)} />
          ) : (
            <Edit
              color="success"
              style={{ opacity: "0.3", pointerEvents: "none" }}
            />
          )}
        </TableCell>
        <TableCell align="right" sx={{ width: "50px" }} colSpan={0}>
          <DeleteOutline color="error" onClick={() => setIsOpen(true)} />
        </TableCell>
      </TableRow>
    </>
  );
};

export const EditableRow = ({ data: row, setEditId }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(row?.name);
  const [link, setLink] = useState(row?.link);
  const [country, setCountry] = useState(row?.country);
  const [region, setRegion] = useState(row?.region);
  const [city, setCity] = useState(row?.city);

  const handleUpdate = async (id) => {
    try {
      await updateExplore({
        ...row,
        name,
        country,
        city,
        region,
        link,
      });
      dispatch(UpdateRow({ id, name, country, city, region, link }));
      setEditId(null);
    } catch (error) {
      console?.error(error);
    }
  };

  return (
    <TableRow key={row?.id}>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o Nome"
          value={name}
          onChange={(self) => setName(self?.target?.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o Link"
          value={link}
          onChange={(self) => setLink(self?.target?.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o País"
          value={country}
          onChange={(self) => setCountry(self?.target?.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize a Região"
          value={region}
          onChange={(self) => setRegion(self?.target?.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize a Cidade"
          value={city}
          onChange={(self) => setCity(self?.target?.value)}
        />
      </TableCell>
      <TableCell align="right" sx={{ cursor: "pointer", color: "#079784" }}>
        <strong onClick={() => handleUpdate(row?.id)}>Atualizar</strong>
      </TableCell>
      <TableCell align="right" sx={{ cursor: "pointer", color: "red" }}>
        <strong onClick={() => setEditId(null)}>Cancelar</strong>
      </TableCell>
    </TableRow>
  );
};
