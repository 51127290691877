/**
 * Login Component
 * In this component we write all the Login Funcionalities
 * We are going to include Authetication
 *
 * ********************LOGIC****************
 * As we stored in our database a key => user role
 * So we are going to use that key (user_role) to verify user role ,
 * and we are going to redirect to admin Dashboard if role is Admin else to Standard User Page
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginBanner from "../../../Assets/Images/loginBanner.webp";
import Logo from "../../../Assets/Images/logo.webp";
import {
  FormHeader,
  InputGroup,
  LinkContainer,
  LoginContainer,
  LoginLeft,
  LoginRight,
  StyledContainer,
} from "./styled";
import { Alert } from "@mui/material";
import { useAuth } from "../../../Hooks/useAuth";

export default function Index() {
  return (
    <StyledContainer>
      <LoginContainer>
        <LoginLeft>
          <img src={LoginBanner} alt="Login Banner" />
        </LoginLeft>
        <LoginRight>
          <LoginForm />
        </LoginRight>
      </LoginContainer>
    </StyledContainer>
  );
}

function LoginForm() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { login, currentUserData, currentUser } = useAuth();

  const handleLogin = async (self) => {
    self.preventDefault();

    if (!currentUser) {
      try {
        setError("");
        await login(email, password);
      } catch (error) {
        const defaultCreateAccountErrorMessage = "Failed to signup.";
        if (error.code.includes("user-not-found")) {
          setError(`${defaultCreateAccountErrorMessage} User not found.`);
        }
        if (error.code.includes("wrong-password")) {
          setError(`${defaultCreateAccountErrorMessage} Wrong password.`);
        }
      }
      return;
    }
  };

  useEffect(() => {
    if (currentUserData) {
      if (currentUserData.role === "admin") navigate("/admin");
      if (currentUserData.role === "user") navigate("/user");
    }
  }, [currentUserData]);

  return (
    <>
      <FormHeader>
        <img src={Logo} alt="OfficialLogo" />
        <h5>Login</h5>
      </FormHeader>

      <form onSubmit={handleLogin}>
        <InputGroup>
          <label>Email</label>
          <input
            type="email"
            name="email"
            required
            onChange={(self) => setEmail(self.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <label>Senha</label>
          <input
            type="password"
            name="password"
            required
            onChange={(self) => setPassword(self.target.value)}
          />
        </InputGroup>

        {error && <Alert severity="error">{error}</Alert>}

        <LinkContainer>
          <Link to="/"></Link>
          <button type="submit">Login</button>
        </LinkContainer>
      </form>
    </>
  );
}
