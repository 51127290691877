import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";

const SPOT_STATUS = Object.freeze({
  Approved: true,
  Reproved: false,
});

export async function block(id, blockValue) {
  const touristGuideRef = doc(db, TABLES.CONNECTPLACES, id);

  await updateDoc(touristGuideRef, {
    active: blockValue,
  });
}

export async function approve(id) {
  const spotReference = doc(db, TABLES.CONNECTPLACES, id);

  await updateDoc(spotReference, {
    active: true,
  });
}

export async function reprove(id) {
  const spotReference = doc(db, TABLES.CONNECTPLACES, id);

  await updateDoc(spotReference, {
    active: false,
  });
}

export async function deleteSpot(id) {
  const spotReference = doc(db, TABLES.CONNECTPLACES, id);

  await deleteDoc(spotReference);
}