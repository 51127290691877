import { createSlice } from "@reduxjs/toolkit";

const FinanceSlice = createSlice({
  name: "finance",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
  },
});

export const selectAll = (state) => state.finance.value;
export const { UpdateValue } = FinanceSlice.actions;
export default FinanceSlice.reducer;
