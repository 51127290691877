/****
 * In this component we Declare Labels for the table
 * So I decided to separate these components to make our code more reusable
 */
import React, { useEffect, useState } from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";
import { StyledTableCell } from "./styles";
import DateFilter from "../../../Components/DateFilter/DateFilter";

//At this point we declare Labels for the finance page
const Labels = [
  { id: "code", label: "Codigo", minWidth: 170 },
  { id: "name", label: "Nome", minWidth: 200 },
  {
    id: "date",
    label: "Data",
    minWidth: 200,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "itineraries",
    label: "Roteiros",
    minWidth: 200,
    align: "left",
  },
  {
    id: "type",
    label: "Tipo",
    minWidth: 200,
    align: "left",
  },
  {
    id: "details",
    label: "Detalhes",
    minWidth: 200,
    align: "justify",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "block",
    label: "Bloquear",
    minWidth: 200,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function Index({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  setModalNameFilter,
  setModalItineraryFilter,
  setModalTypeFilter,
  modalNameFilter,
  modalItineraryFilter,
  modalTypeFilter,
}) {
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const filtersArray = [modalNameFilter, modalItineraryFilter, modalTypeFilter];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }

  function handleOpenNameFilter(id) {
    setModalNameFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenItineraryFilter(id) {
    setModalItineraryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenTypeFilter(id) {
    setModalTypeFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleClick(id) {
    if (id === "date") return openDateFilter(id);
    if (id === "name") return handleOpenNameFilter(id);
    if (id === "itineraries") return handleOpenItineraryFilter(id);
    if (id === "type") return handleOpenTypeFilter(id);
    return handleChangeOrder(id);
  }

  return (
    <>
      {Labels.map((column) => {
        let isFiltered = filtersArray.some(
          (item) => item.type === column.id && item.isUsing
        );

        return (
          <StyledTableCell
            onClick={() => handleClick(column.id)}
            sx={{ fontWeight: "bold" }}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "details" &&
                column.id !== "block" &&
                column.id !== "code" && (
                  <div>
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "desc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "asc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
