import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import {
  OverlayContainer,
  OverlayChild,
  OverlayHeader,
  OverlayBody,
} from "../../../../Components/Global/Reusable";
import { selectAll } from "../../../../features/PlansReportSlice";
import Form from "../Forms/PlansForm";

export default function Index({ editId, closeRegister, onToastOpen }) {
  const PlansForm = useSelector((state) => state.menu.PlansRegisterState);
  const [itemToEdit] = useSelector(selectAll).filter(
    (item) => item?.id === editId
  );

  return (
    <>
      {PlansForm && (
        <OverlayContainer>
          <OverlayChild>
            <OverlayHeader>
              <ArrowBack onClick={closeRegister} />
              <h3>{itemToEdit ? "Edição de Plano" : "Cadastro de Planos"}</h3>
            </OverlayHeader>

            <OverlayBody>
              <Form
                onToastOpen={onToastOpen}
                itemToEdit={itemToEdit}
                closeRegister={closeRegister}
              />
            </OverlayBody>
          </OverlayChild>
        </OverlayContainer>
      )}
    </>
  );
}
