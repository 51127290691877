import { TABLES } from "../enums/tables";
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { serializeData } from "./firebase";

export const getConnectpointsHistory = async () => {
  const connectpointsHistoryCol = collection(db, TABLES.CONNECTPOINTS);
  const q = query(connectpointsHistoryCol, orderBy("created_at", "desc"));

  const snap = await getDocs(q);

  const formatteDataSnap = snap.docs.map(async (doc) => {
    const data = doc.data();

    return data;
  });

  const formatteData = await Promise.all(formatteDataSnap);
  const serializedData = [];

  for (const data of formatteData) {
    const serialized = await serializeData(data);
    serializedData.push(serialized);
  }

  return serializedData;
};
