import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as S from "./styled";

const BaseFilter = ({
  label = "",
  data,
  labelPath = "name",
  setModalFilterSearch,
  setModalFilter,
  modalFilterSearch,
  orderData = true,
}) => {
  const [filterValue, setFilterValue] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  function handleChange(value) {
    setFilterValue(value);
    setModalFilterSearch(value);
  }

  useEffect(() => {
    if (filterValue.length > 0) {
      return setModalFilter((prev) => ({ ...prev, isUsing: true }));
    }
    return setModalFilter((prev) => ({ ...prev, isUsing: false }));
  }, [filterValue, setModalFilter]);

  function handleClose() {
    setModalFilter((prev) => ({ ...prev, isOpen: false }));
  }

  useEffect(() => {
    let currentFilteredData = [];

    let filteredValues = data.filter(
      (item) => item[labelPath] && item[labelPath].trim().length > 0
    );

    if (orderData) {
      filteredValues = filteredValues.sort((a, b) => {
        if (
          typeof a[labelPath] === "string" &&
          typeof b[labelPath] === "string"
        ) {
          return a[labelPath].localeCompare(b[labelPath]);
        } else {
          return a[labelPath] - b[labelPath];
        }
      });
    }

    filteredValues.forEach((value) => {
      if (
        !modalFilterSearch.some((item) => item[labelPath] === value[labelPath])
      ) {
        currentFilteredData.push(value);
      }
    });
    setFilteredData(currentFilteredData);
  }, [data, labelPath, modalFilterSearch]);

  return (
    <S.ModalContainer>
      <div className="content">
        <h3>Filtro</h3>

        <div className="inputsContainer">
          <Autocomplete
            sx={{ width: 450 }}
            multiple
            id="size-small-standard-multi"
            size="small"
            value={modalFilterSearch}
            onChange={(e, value) => handleChange(value)}
            options={
              filteredData.length > 0
                ? filteredData
                : modalFilterSearch.length === data.length
                ? []
                : data
                    .filter(
                      (item) =>
                        item[labelPath] && item[labelPath].trim().length > 0
                    )
                    .sort((a, b) => {
                      if (typeof a === "string" && typeof b === "string") {
                        return a.localeCompare(b);
                      } else {
                        return a - b;
                      }
                    })
            }
            getOptionLabel={(option) => (option.label ? option.label : option[labelPath])}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={`Filtrar por ${label}:`}
                placeholder="Selecione"
              />
            )}
          />
        </div>

        <div className="buttonsWrapper">
          <button className="button" onClick={handleClose}>
            Fechar
          </button>
        </div>
      </div>
    </S.ModalContainer>
  );
};

export default BaseFilter;