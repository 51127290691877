import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { PLAN_NATURE } from "../enums/plans";
import { TABLES } from "../enums/tables";
import { db } from "../firebase";
import { api } from "../lib/axios";

export async function createHightlightPrice(highlightData) {
  const {
    collection: collectionType,
    highlight_screens,
    prices_per_day,
  } = highlightData;

  const documentRef = doc(collection(db, TABLES.PLANS));

  let tempDaysPrices = {};
  let prices;

  for (let i = 0; i < Object.values(prices_per_day).length; i++) {
    const day = (i + 1) * 5;

    prices = Object.keys(prices_per_day).reduce((acc, label) => {
      const priceData = prices_per_day[label].currency;

      acc[label] = {
        prices: Object.keys(priceData).reduce((currencyAcc, currency) => {
          currencyAcc[currency] = {
            value: String(priceData[currency]?.price_minor_units) || "0",
          };
          return currencyAcc;
        }, {}),
      };

      return acc;
    }, {});

    const dayResponse = await api.post("subscriptions/createStripeProduct", {
      name: `HIGHLIGHT: ${collectionType} - ${highlight_screens.join(
        " & "
      )} for ${day} days`,
      description: `${collectionType} in ${highlight_screens.join(
        " & "
      )} for ${day} days`,
      prices: prices[day]?.prices,
    });

    const { prices: stripePrices, product } = dayResponse.data;
    tempDaysPrices[day] = stripePrices;
    tempDaysPrices[day].stripe_product_id = product.id;
  }

  const pricesWithIds = Object.keys(prices_per_day).reduce((acc, label) => {
    const priceData = prices_per_day[label].currency;

    acc[label] = {
      stripe_product_id: tempDaysPrices[label].stripe_product_id,
      currency: Object.keys(priceData).reduce((currencyAcc, currency) => {
        currencyAcc[currency] = {
          price_minor_units: priceData[currency]?.price_minor_units * 100 || 0,
          stripe_price_id: tempDaysPrices[label].find(
            (item) => item?.currency === currency
          )?.price?.id,
        };
        return currencyAcc;
      }, {}),
    };

    return acc;
  }, {});

  const now = serverTimestamp();

  let newHighlightPrice = {
    collection: collectionType,
    highlight_screens,
    id: documentRef.id,
    prices_per_day: pricesWithIds,
    nature: PLAN_NATURE.HIGHLIGHT,
    active: true,
    collection_name: TABLES.PLANS,
    created_at: now,
    updated_at: now,
    names: null,
    rules: null,
    type: null,
    prices: null,
    frequency: null,
    stripe_product_id: null,
  };

  await setDoc(documentRef, newHighlightPrice);

  return newHighlightPrice;
}

export async function updateHighlightPrices(highlightData) {
  const {
    id,
    collection: collectionType,
    highlight_screens,
    prices_per_day,
  } = highlightData;

  const documentRef = doc(db, TABLES.PLANS, id);

  const transformedPricesPerDay = Object.keys(prices_per_day).reduce((acc, label) => {
    const transformedLabel = label === "05" ? 5 : label;
    acc[transformedLabel] = prices_per_day[label];
    return acc;
  }, {});

  let tempDaysPrices = {};
  let prices;

  for (let i = 0; i < Object.values(transformedPricesPerDay).length; i++) {
    const day = (i + 1) * 5;

    prices = Object.keys(transformedPricesPerDay).reduce((acc, label) => {
      const priceData = transformedPricesPerDay[label].currency;

      acc[label] = {
        prices: Object.keys(priceData).reduce((currencyAcc, currency) => {
          currencyAcc[currency] = {
            value: String(priceData[currency]?.price_minor_units) || "0",
          };
          return currencyAcc;
        }, {}),
      };

      return acc;
    }, {});

    const dayResponse = await api.post("subscriptions/createStripeProduct", {
      name: `HIGHLIGHT: ${collectionType} - ${highlight_screens.join(
        " & "
      )} for ${day} days`,
      description: `${collectionType} in ${highlight_screens.join(
        " & "
      )} for ${day} days`,
      prices: prices[day]?.prices,
    });

    const { prices: stripePrices, product } = dayResponse.data;

    tempDaysPrices[day] = stripePrices;
    tempDaysPrices[day].stripe_product_id = product.id;
  }

  const pricesWithIds = Object.keys(transformedPricesPerDay).reduce((acc, label) => {
    const priceData = transformedPricesPerDay[label].currency;

    acc[label] = {
      stripe_product_id: tempDaysPrices[label].stripe_product_id,
      currency: Object.keys(priceData).reduce((currencyAcc, currency) => {
        currencyAcc[currency] = {
          price_minor_units: priceData[currency]?.price_minor_units * 100 || 0,
          stripe_price_id: tempDaysPrices[label].find(
            (item) => item?.currency === currency
          )?.price?.id,
        };
        return currencyAcc;
      }, {}),
    };

    return acc;
  }, {});

  const now = serverTimestamp();

  let newHighlightPrice = {
    id,
    collection: collectionType,
    highlight_screens,
    prices_per_day: pricesWithIds,
    updated_at: now,
    names: null,
    rules: null,
    type: null,
    prices: null,
    frequency: null,
    stripe_product_id: null,
  };

  await updateDoc(documentRef, newHighlightPrice);

  return newHighlightPrice;
}


export async function removeHighlightPrice(highlightData) {
  const { id, prices_per_day } = highlightData;

  for (const day of Object.keys(prices_per_day)) {
    for (const item of Object.values(prices_per_day?.[day]?.currency)) {
      if (item?.stripe_price_id) {
        await api.delete(
          `subscriptions/deleteStripePrice/${item.stripe_price_id}`
        );
      }
    }
  }

  await deleteDoc(doc(db, TABLES.PLANS, id));
}
