import React, { useEffect } from 'react';
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom'
import NavMenu from '../../Components/NavMenu'
import { useAuth } from '../../Hooks/useAuth';

export function DefaultLayout() {
  const { currentUserData } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUserData) {
      navigate("/");
    }
  }, [currentUserData])

  return (
    <>
      <NavMenu />
      <Outlet />
      <ScrollRestoration />
    </>
  )
}
