/****
 * Finance Page Component
 * In this component we declare all the finance page functionlities
 * we Diiplay finance data in this compont
 */
import React, { useEffect, useState } from "react";
import { ShowTotal } from "../../../Components/Global/Reusable";
import StyledTable from "../../../Components/Table";
import TableBody from "./FinanceBodyData";
import TableLabels from "./FinanceLabels";
import { AppContainer } from "../../../Components/Global/Reusable";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";

export default function Index() {
  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();
  const [totalDate, setTotalDate] = useState();
  const [totalValue, setTotalValue] = useState(0);
  const [orderFilter, setOrderFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [modalStatusFilter, setModalStatusFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalTypeFilter, setModalTypeFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalPlanFilter, setModalPlanFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [isTransactionFilter, setIsTransactionFilter] = useState({
    isOpen: false,
    data: null,
  });
  const [currencyFilter, setCurrencyFilter] = useState({
    isOpen: false,
    value: "BRL",
  });
  const currencySymbol =
    currencyFilter.value === "BRL"
      ? "R$"
      : currencyFilter.value === "USD"
      ? "$"
      : "€";

  useEffect(() => {
    function updateValue() {
      if (!totalDate) return;
      setTotalValue(
        totalDate.reduce((accumulator, currentValue) => {
          let value = Number(currentValue?.totalValue || 0);
          return accumulator + value;
        }, 0)
      );
    }
    updateValue();
  }, [totalDate]);

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);

  return (
    <AppContainer maxWidth="xl">
      <StyledTable
        TableTitle="Financeiro"
        Component={<ShowTotal total={`${currencySymbol} ${totalValue}`} />}
        Labels={
          <TableLabels
            orderFilter={orderFilter}
            setOrderFilter={setOrderFilter}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            setIsTransactionFilter={setIsTransactionFilter}
            currencyFilter={currencyFilter}
            setCurrencyFilter={setCurrencyFilter}
            setModalStatusFilter={setModalStatusFilter}
            setModalTypeFilter={setModalTypeFilter}
            setModalPlanFilter={setModalPlanFilter}
          />
        }
        TableData={
          <TableBody
            modalStatusFilter={modalStatusFilter}
            setModalStatusFilter={setModalStatusFilter}
            setTotalDate={setTotalDate}
            orderFilter={orderFilter}
            dateFilter={dateFilter}
            isTransactionFilter={isTransactionFilter}
            setIsTransactionFilter={setIsTransactionFilter}
            currencyFilter={currencyFilter}
            setCurrencyFilter={setCurrencyFilter}
            modalTypeFilter={modalTypeFilter}
            setModalTypeFilter={setModalTypeFilter}
            modalPlanFilter={modalPlanFilter}
            setModalPlanFilter={setModalPlanFilter}
          />
        }
      />
    </AppContainer>
  );
}
