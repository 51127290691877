import { createSlice } from "@reduxjs/toolkit";

const ConnectsaleSlice = createSlice({
  name: "connectsalesReport",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
  },
});

export const selectAll = (state) => state.connectsalesReport.value;
export const { UpdateRow, UpdateValue } = ConnectsaleSlice.actions;
export default ConnectsaleSlice.reducer;
