import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  LinearProgress,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileInputImage from "../../../Assets/fileInputImage.svg";
import {
  CreateAgenda,
  selectAll,
  UpdateRow,
} from "../../../features/AgendaSlice";
import { RegisterButton } from "../Category/styled";
import * as S from "./styled";
import {
  InputGroup,
  OverlayContainer,
  OverlayHeader,
  OverlayBody,
  OverlayChild,
} from "../../../Components/Global/Reusable";
import * as agenda from "../../../Services/agenda";
import { EventPriceFormatter } from "../../../utils/formatter";
import { useAuth } from ".././../../Hooks/useAuth";
import { useEffect } from "react";
import { useCountriesAndCities } from "../../../Hooks/useCountriesAndCities";
import dayjs from "dayjs";
import { LoadingSpinner } from "../../../Components/Loader";
import { db } from "../../../firebase";
import { TABLES } from "../../../enums/tables";
import { doc } from "firebase/firestore";

/**
 *
 *
 */

export default function Index({ editId, closeRegister, onToastOpen }) {
  const AgendaState = useSelector((state) => state.menu.AgendaState);
  const [itemToEdit] = useSelector(selectAll).filter(
    (item) => item.id === editId
  );
  return (
    <>
      {AgendaState && (
        <OverlayContainer>
          <OverlayChild>
            <OverlayHeader>
              <ArrowBack onClick={closeRegister} />
              <h3 style={{ marginLeft: "20px" }}>
                {itemToEdit
                  ? "Edição de Agenda de Eventos"
                  : "Cadastro de Agenda de Eventos"}
              </h3>
            </OverlayHeader>

            <OverlayBody>
              <CreateForm
                onToastOpen={onToastOpen}
                itemToEdit={itemToEdit}
                closeRegister={closeRegister}
              />
            </OverlayBody>
          </OverlayChild>
        </OverlayContainer>
      )}
    </>
  );
}

/***
 * Form
 */
export function CreateForm({ itemToEdit, closeRegister, onToastOpen }) {
  const dispatch = useDispatch();
  //Grap our form edit values
  const [progress, setProgress] = useState(false);
  const { cities, countries, regions } = useCountriesAndCities();
  const [firstValueChange, setFirstValueChange] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState(() => {
    if (itemToEdit && itemToEdit.name) {
      return itemToEdit.name;
    }
    return "";
  });
  const [startAt, setStartAt] = useState(() => {
    if (itemToEdit && itemToEdit.start_date) {
      return dayjs(itemToEdit?.start_date).toISOString()?.split("T")?.[0];
    }
    return "";
  });
  const [endAt, setEndAt] = useState(() => {
    if (itemToEdit && itemToEdit.end_date) {
      return dayjs(itemToEdit?.end_date).toISOString()?.split("T")?.[0];
    }
    return "";
  });
  const [ticketCurrency, setTicketCurrency] = useState(() => {
    if (itemToEdit && itemToEdit.ticket_currency) {
      return itemToEdit.ticket_currency;
    }
    return "";
  });
  const [start, setStartTime] = useState(() => {
    if (itemToEdit && itemToEdit.start_time_in_minutes) {
      const hours = Math.floor(itemToEdit.start_time_in_minutes / 60);
      const minutes = itemToEdit.start_time_in_minutes % 60;

      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");

      const startTime = `${formattedHours}:${formattedMinutes}`;

      return startTime;
    }
    return "";
  });
  const [entrance, setEntrance] = useState(() => {
    if (itemToEdit && itemToEdit.entrance_fee_minor_units) {
      return itemToEdit.entrance_fee_minor_units / 100;
    }
    return "";
  });
  const [city, setCity] = useState(() => {
    if (itemToEdit && itemToEdit.city) {
      return itemToEdit.city;
    }
    return "";
  });
  const [region, setRegion] = useState(() => {
    if (itemToEdit && itemToEdit.region) {
      return itemToEdit.region;
    }
    return "";
  });
  const [currency, setCurrency] = useState("");
  const [country, setCountry] = useState(() => {
    if (itemToEdit && itemToEdit.country) {
      return itemToEdit.country;
    }
    return "";
  });
  const [url, setUrl] = useState(() => {
    if (itemToEdit && itemToEdit.external_url) {
      return itemToEdit.external_url;
    }
    return "";
  });
  const [user, setUser] = useState(() => {
    if (itemToEdit && itemToEdit?.owner) {
      return {
        name: itemToEdit.owner.name,
        id: itemToEdit.owner.id,
      };
    }
    return { name: "", id: "" };
  });
  const [imageUrl, setImageUrl] = useState(() => {
    if (itemToEdit && itemToEdit.icon_url) {
      return itemToEdit.icon_url;
    }
    return "";
  });
  const [file, setFile] = useState();

  const { currentUser, currentUserData } = useAuth();

  const filteredRegions = regions.filter(
    (city) => city.country_name === country
  );

  const filteredCities = cities.filter((city) => city.state_name === region);

  const data = {
    name,
    start_date: startAt,
    end_date: endAt,
    ticket_currency: ticketCurrency,
    start_time_in_minutes: start,
    entrance_fee_minor_units: entrance,
    city,
    country,
    region,
    icon_url: file ?? imageUrl,
    external_url: url,
  };
  const isCorrectForm =
    !!data.name &&
    !!data.start_date &&
    !!data.ticket_currency &&
    !!data.end_date &&
    !!data.entrance_fee_minor_units &&
    !!data.start_time_in_minutes &&
    !!data.country &&
    !!imageUrl;

  useEffect(() => {
    if (!itemToEdit && currentUser && currentUserData) {
      setUser({ name: currentUserData.name, id: currentUser.uid });
    }
  }, [currentUser, currentUserData]);

  const handleSubmit = async (self) => {
    self.preventDefault();

    const [hour, minute] = data?.start_time_in_minutes?.split(":")?.map(Number);
    const startTimeInMinutes = hour * 60 + minute;
    const startDate = dayjs(data.start_date).toDate();
    const endDate = dayjs(data.end_date).toDate();

    setProgress(true);

    if (itemToEdit) {
      try {
        const updatedItem = await agenda.update({
          id: itemToEdit?.id,
          icon_url: data.icon_url,
          start_time_in_minutes: startTimeInMinutes,
          start_date: startDate,
          end_date: endDate,
          name: data.name,
          ticket_currency: data.ticket_currency,
          entrance_fee_minor_units:
            Number(
              data.entrance_fee_minor_units
                .replace(/[^0-9.,]/g, "")
                .replace(/,/g, ".")
            ) * 100,
          country: data.country,
          region: data.region,
          city: data.city,
          external_url: data.external_url,
        });
        dispatch(UpdateRow(updatedItem));
        onToastOpen("Success", "success");
        closeRegister();
      } catch (error) {
        console.error(error);
        onToastOpen("Error", "error");
      } finally {
        setProgress(false);
      }
      return;
    }

    try {
      const createData = await agenda.create({
        icon_url: data.icon_url,
        owner: doc(db, TABLES.USERS, currentUserData.id),
        start_time_in_minutes: startTimeInMinutes,
        start_date: startDate,
        end_date: endDate,
        name: data.name,
        ticket_currency: data.ticket_currency,
        entrance_fee_minor_units:
          Number(
            data.entrance_fee_minor_units
              .replace(/[^0-9.,]/g, "")
              .replace(/,/g, ".")
          ) * 100,
        country: data.country,
        region: data.region,
        city: data.city,
        external_url: data.external_url,
      });
      dispatch(
        CreateAgenda({
          ...createData,
          owner: { id: currentUserData, name: currentUserData.name },
        })
      );
      closeRegister();
      onToastOpen("Success", "success");
    } catch (error) {
      setProgress(false);
      console.error(error);
      onToastOpen("Error", "error");
    } finally {
      setProgress(false);
    }
  };

  function handleImageChange(event) {
    event.preventDefault();
    if (event.target.files.length === 0) return;
    const file = event.target.files[0];
    setFile(file);
    setImageUrl(URL.createObjectURL(file));
  }

  useEffect(() => {
    if (countries.length !== 0 && cities.length !== 0 && regions.length !== 0)
      setIsLoading(false);
  }, [cities, countries, regions]);

  const currencyList = countries
    .map((country) => ({
      id: country.id,
      currency: country.currency,
      name: country.currency_name,
      symbol: country.currency_symbol,
    }))
    .filter((currency, index, self) => {
      return index === self.findIndex((c) => c.name === currency.name);
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  function handleChangeCurrency(currency) {
    if (!currency) {
      setEntrance("");
      setCurrency("");
      return;
    }

    const selectedCountry = countries.find(
      (currentCountry) =>
        currentCountry.currency_name === currency?.split(" - ")[1]
    );

    const currentCurrency = {
      currency: selectedCountry?.currency,
      name: selectedCountry?.currency_name,
      symbol: selectedCountry?.currency_symbol,
    };

    setTicketCurrency(currentCurrency.currency);
    setCurrency(currentCurrency);

    if (firstValueChange && itemToEdit) {
      setFirstValueChange(false);
      return setEntrance("");
    }

    handleBlur(currentCurrency.currency);
  }

  function handleBlur(optionalCurrency) {
    if (!currency || !entrance) return;
    setFirstValueChange(false);
    let formattedValue;
    let ticketAsNumber;

    ticketAsNumber = Number(
      String(entrance)
        ?.replace(/[^0-9.,]/g, "")
        .replace(/,/g, ".")
    );

    if (isNaN(ticketAsNumber) || ticketAsNumber < 0) {
      setEntrance("");
      return;
    }

    if (!optionalCurrency) {
      formattedValue = EventPriceFormatter(currency.currency).format(
        ticketAsNumber
      );
      setEntrance(formattedValue);
      return;
    }

    formattedValue =
      EventPriceFormatter(optionalCurrency).format(ticketAsNumber);
    setEntrance(formattedValue);
  }

  function checkValidEndDate() {
    if (startAt.length < 10 || endAt.length < 10) return;
    if (dayjs(endAt).isBefore(dayjs(startAt))) {
      onToastOpen("Data final menor que a data de inicio.", "error");
      setEndAt("");
    }
  }

  useEffect(() => {
    if (!itemToEdit || countries.length === 0) return;

    const selectedCountry = countries.find(
      (currentCountry) => currentCountry.currency === itemToEdit.ticket_currency
    );

    const currentCurrency = {
      currency: selectedCountry?.currency,
      name: selectedCountry?.currency_name,
      symbol: selectedCountry?.currency_symbol,
    };

    setCurrency(currentCurrency);
  }, [countries, itemToEdit]);

  useEffect(() => {
    handleBlur();
  }, [isLoading]);

  function handleChangeValue(value) {
    let inputValue = value;
    /* inputValue = inputValue.replace(/[^0-9]/g, ""); */

    setEntrance(inputValue);
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup>
        <label>Nome</label>
        <input
          onChange={(self) => setName(self.target.value)}
          type="text"
          value={name}
          placeholder="digite o nome do evento"
        />
      </InputGroup>

      <InputGroup>
        <label>Inicio</label>
        <input
          onChange={(self) => setStartTime(self.target.value)}
          type="time"
          value={start}
          placeholder="selecione a hora de inicio"
        />
      </InputGroup>

      <InputGroup>
        <label htmlFor="country">País</label>
        <Autocomplete
          id="country"
          options={countries.map((country) => country.name)}
          style={{ width: 300 }}
          value={country}
          renderInput={(params) => <TextField {...params} variant="standard" />}
          onChange={(_, newInputValue) => {
            setCountry(newInputValue);
            setRegion("");
            setCity("");
          }}
        />
        {isLoading && (
          <LoadingSpinner
            style={{ position: "absolute", scale: "0.6", right: "17%" }}
          />
        )}
      </InputGroup>
      <InputGroup>
        <label htmlFor="region">Região</label>
        <Autocomplete
          id="região"
          options={filteredRegions.map((item) => item.name)}
          style={{ width: 300 }}
          value={region}
          renderInput={(params) => <TextField {...params} variant="standard" />}
          onChange={(_, newValue) => {
            setRegion(newValue);
            setCity("");
          }}
        />
        {isLoading && (
          <LoadingSpinner
            style={{ position: "absolute", scale: "0.6", right: "17%" }}
          />
        )}
      </InputGroup>

      <InputGroup>
        <label htmlFor="city">Cidade</label>
        <Autocomplete
          id="city"
          options={filteredCities.map((item) => item.name)}
          style={{ width: 300 }}
          value={city}
          renderInput={(params) => <TextField {...params} variant="standard" />}
          onChange={(_, newValue) => {
            setCity(newValue);
          }}
        />
        {isLoading && (
          <LoadingSpinner
            style={{ position: "absolute", scale: "0.6", right: "17%" }}
          />
        )}
      </InputGroup>

      <InputGroup>
        <label htmlFor="currency">Moeda da Entrada</label>
        <Autocomplete
          id="currency"
          options={currencyList.map((item) => `${item.symbol} - ${item.name}`)}
          style={{ width: 300 }}
          value={currency ? currency.currency : ""}
          renderInput={(params) => <TextField {...params} variant="standard" />}
          onChange={(_, newValue) => {
            handleChangeCurrency(newValue);
          }}
        />
        {isLoading && (
          <LoadingSpinner
            style={{ position: "absolute", scale: "0.6", right: "17%" }}
          />
        )}
      </InputGroup>

      <InputGroup style={!currency ? { opacity: "0.5" } : { opacity: "1" }}>
        <label>Valor da Entrada</label>
        <input
          disabled={!currency}
          onChange={(self) => handleChangeValue(self.target.value)}
          type="text"
          value={entrance}
          placeholder="digite o valor da entrada"
          onBlur={() => handleBlur()}
        />
      </InputGroup>

      <InputGroup>
        <label>Data de inicio</label>
        <input
          onChange={(self) => setStartAt(self.target.value)}
          type="date"
          value={startAt}
          placeholder="selecione a data de inicio do evento"
        />
      </InputGroup>

      <InputGroup>
        <label>Data de fim</label>
        <input
          onChange={(self) => setEndAt(self.target.value)}
          type="date"
          value={endAt}
          placeholder="selecione a data de fim do evento"
          onBlur={checkValidEndDate}
        />
      </InputGroup>

      <InputGroup>
        <label>URL</label>
        <input
          onChange={(self) => setUrl(self.target.value)}
          type="text"
          value={url}
          placeholder="digite a url do evento"
        />
      </InputGroup>

      {itemToEdit && (
        <InputGroup>
          <label>Usuario</label>
          <input
            type="text"
            value={user.name}
            placeholder="digite um usuario"
            disabled
            readOnly
          />
        </InputGroup>
      )}

      <S.FileInputWrapper file={imageUrl}>
        <Box>
          <img
            src={imageUrl ? imageUrl : FileInputImage}
            alt="fileInputImage"
          />
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleImageChange}
          />
        </Box>
      </S.FileInputWrapper>

      <RegisterButton disabled={!isCorrectForm || progress}>
        {itemToEdit ? "Salvar" : "Cadastrar"}
      </RegisterButton>

      {progress && <LinearProgress />}
    </form>
  );
}

/***
 * Select Component
 */

function ReusableComponent({ data, title, trigger }) {
  return (
    <S.CustomSelect
      value={title}
      onChange={(self) => {
        trigger(self.target.value);
      }}
    >
      <MenuItem value={title}>
        <em>{title}</em>
      </MenuItem>
      {data.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      ))}
    </S.CustomSelect>
  );
}
