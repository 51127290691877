import React, { useEffect, useRef, useState } from "react";
import StyledTable from "../../../Components/Table/index";
import ItineraryReportData from "./bodyData";
import { ItineraryReportLabel } from "./Labels";
import { ShowTotal } from "../../../Components/Global/Reusable";
import { AppContainer } from "../../../Components/Global/Reusable";
import { useSelector } from "react-redux";
import { selectAll } from "../../../features/ItineraryReportSlice";
import { Toast } from "../../../Components/Toast";
import { QueryByString } from "../../../Components/LocalSearch";
import { getEstablishmentsAndTouristSpotsFromItineraries } from "../../../Services/itinerary";

export default function Index() {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [secondaryFilterOptions, setSecondaryFilterOptions] = useState({
    establishments: [],
    touristSpots: [],
  });
  const [secondaryFilter, setSecondaryFilter] = useState({
    establishmentId: "",
    touristSpotId: "",
  });
  const [orderFilter, setOrderFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [modalNameFilter, setModalNameFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalItineraryFilter, setModalItineraryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCategoryFilter, setModalCategoryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCountryFilter, setModalCountryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCityFilter, setModalCityFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });

  const timerRef = useRef(0);

  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  useEffect(() => {
    async function getEstablishmentsAndTouristSpots() {
      const establishmentsAndTouristSpots =
        await getEstablishmentsAndTouristSpotsFromItineraries();
      setSecondaryFilterOptions(establishmentsAndTouristSpots);
    }
    getEstablishmentsAndTouristSpots();
  }, []);

  const items = useSelector(selectAll);
  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="Relatório de ConnectRoutes"
          Component={<ShowTotal total={items.length} />}
          Labels={
            <ItineraryReportLabel
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalItineraryFilter={modalItineraryFilter}
              setModalItineraryFilter={setModalItineraryFilter}
              modalCategoryFilter={modalCategoryFilter}
              setModalCategoryFilter={setModalCategoryFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
            />
          }
          aditionalSearchBoxes={
            <>
              {!secondaryFilter.touristSpotId && (
                <select
                  value={secondaryFilter.establishmentId}
                  onChange={(self) => {
                    setSecondaryFilter((state) => ({
                      ...state,
                      establishmentId: self.target.value,
                    }));
                  }}
                  style={{
                    borderRadius: "50px",
                    paddingInline: "5px",
                    width: "min(270px, 100%)",
                  }}
                >
                  <option value="">Pesquisar por: ConnectStores</option>
                  {secondaryFilterOptions.establishments.map(
                    (establishment) => (
                      <option value={establishment.id}>
                        {`Pesquisar por ConnectStores: ${establishment.name}`}
                      </option>
                    )
                  )}
                </select>
              )}
              {!secondaryFilter.establishmentId && (
                <select
                  value={secondaryFilter.touristSpotId}
                  onChange={(self) => {
                    setSecondaryFilter((state) => ({
                      ...state,
                      touristSpotId: self.target.value,
                    }));
                  }}
                  style={{
                    borderRadius: "50px",
                    paddingInline: "5px",
                    width: "min(270px, 100%)",
                  }}
                >
                  <option value="">Pesquisar por: ConnectPlaces</option>
                  {secondaryFilterOptions.touristSpots.map((spot) => (
                    <option
                      value={spot.id}
                    >{`Pesquisar por ConnectPlaces: ${spot.name}`}</option>
                  ))}
                </select>
              )}
            </>
          }
          TableData={
            <ItineraryReportData
              secondaryFilter={secondaryFilter}
              dateFilter={dateFilter}
              orderFilter={orderFilter}
              onToastOpen={onToastOpen}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalItineraryFilter={modalItineraryFilter}
              setModalItineraryFilter={setModalItineraryFilter}
              modalCategoryFilter={modalCategoryFilter}
              setModalCategoryFilter={setModalCategoryFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
            />
          }
        />
      </AppContainer>
    </>
  );
}
