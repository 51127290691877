import { X } from "phosphor-react";
import * as Dialog from "@radix-ui/react-dialog";
import { Root, Overlay, Content, Close, Title, Description } from "./styles";
import React from "react";

export function Modal(props) {
  const {
    children,
    triggerableContent,
    open,
    onOpenChange,
    title,
    description,
  } = props;

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger>{triggerableContent}</Dialog.Trigger>
      <Dialog.Portal>
        <Overlay />
        <Content>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
          <div style={{ marginTop: "3rem" }}>{children}</div>
          <Close>
            <X color="#000" />
          </Close>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
