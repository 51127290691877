/****
 * In this component we Declare Labels for the table
 * So I decided to separate these components to make our code more reusable
 */
import React, { useState } from "react";
import { StyledTableCell } from "./styled";
import { ArrowDown, ArrowUp } from "phosphor-react";
import DateFilter from "../../../Components/DateFilter/DateFilter";

//At this point we declare Labels for the finance page

//At this point we declare Labels for the finance page
const Labels = [
  { id: "name", label: "Nome", minWidth: 100 },
  {
    id: "date",
    label: "Data",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "details",
    label: "Detalhes",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "block",
    label: "Bloquear",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function Index({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  setModalFilter
}) {
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);

  function handleOpenModalFilter(id) {
    setModalFilter(prev=>({...prev, isOpen: true, type: id}))
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }

  return (
    <>
      {Labels.map((column) => {
        return (
          <StyledTableCell
            onClick={
              column.id === "date"
                ? openDateFilter
                : () => handleOpenModalFilter(column.id)
            }
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "details" && column.id !== "block" && (
                <div>
                  <ArrowUp
                    size={16}
                    weight="regular"
                    color={`${
                      orderFilter &&
                      orderFilter.id === column.id &&
                      orderFilter.order === "desc"
                        ? "#06bee7"
                        : "rgba(0, 0, 0, 0.87)"
                    }`}
                  />
                  <ArrowDown
                    color={`${
                      orderFilter &&
                      orderFilter.id === column.id &&
                      orderFilter.order === "asc"
                        ? "#06bee7"
                        : "rgba(0, 0, 0, 0.87)"
                    }`}
                    size={16}
                    weight="regular"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
              )}
            </div>
          </StyledTableCell>
        );
      })}
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
