import { Flag, House, CalendarToday } from "@mui/icons-material";
import { LinearProgress, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../../../Components/Button";
import {
  InputGroup,
  InputGroupExtended,
  TextAreaGroup,
} from "../../../../Components/Global/Reusable";
import {
  CreateplansReport,
  UpdateRow,
} from "../../../../features/PlansReportSlice";
import * as S from "../styled";
import { createPlan, updatePlan } from "../../../../Services/plans";
import { useEffect } from "react";

export default function Index({ itemToEdit, closeRegister, onToastOpen }) {
  const [progress, setProgress] = useState(false);
  const [names, setNames] = useState(() => {
    if (itemToEdit && itemToEdit.names) {
      return itemToEdit.names;
    }
    return { "pt-BR": "", "en-US": "", es: "", fr: "", it: "", zh: "" };
  });
  const [prices, setPrices] = useState(() => {
    if (itemToEdit && itemToEdit.prices) {
      return Object.keys(itemToEdit.prices).reduce((acc, label) => {
        const price = itemToEdit.prices[label];

        acc[label] = {
          ...price,
          price_minor_units: price?.price_minor_units / 100 || 0,
        };

        return acc;
      }, {});
    }
    return { BRL: {}, USD: {}, EUR: {} };
  });

  const [type, setType] = useState(() => {
    if (itemToEdit && itemToEdit.collection) {
      return {
        value: itemToEdit.collection,
        plan: itemToEdit.type,
      };
    }
    return { value: "", plan: "" };
  });
  const [rules, setRules] = useState(() => {
    if (itemToEdit && itemToEdit.rules) {
      return itemToEdit.rules;
    }
    return { "pt-BR": "", "en-US": "", es: "", fr: "", it: "", zh: "" };
  });
  const [country, setCountry] = useState(() => {
    if (itemToEdit && itemToEdit.country) {
      return itemToEdit.country;
    }
    return "";
  });
  const [region, setRegion] = useState(() => {
    if (itemToEdit && itemToEdit.region) {
      return itemToEdit.region;
    }
    return "";
  });
  const [isCorrectForm, setIsCorrectForm] = useState(false);

  const [currentNameLanguage, setCurrentNameLanguage] = useState("pt-BR");
  const [currentRulesLanguage, setCurrentRulesLanguage] = useState("pt-BR");

  const categoryType = [
    {
      id: 1,
      name: "ConnectGuide não credenciado",
      icon: <Flag />,
      type: { value: "connectguides", plan: "amateur-guide" },
    },
    {
      id: 2,
      name: "ConnectGuide credenciado",
      icon: <Flag />,
      type: { value: "connectguides", plan: "pro-guide" },
    },
    {
      id: 3,
      name: "ConnectSale",
      icon: <Flag />,
      type: { value: "connectsales", plan: "connectsale" },
    },
    {
      id: 3,
      name: "ConnectStore Básico",
      icon: <House />,
      type: { value: "connectstores", plan: "basic" },
    },
    {
      id: 3,
      name: "ConnectStore Completo",
      icon: <House />,
      type: { value: "connectstores", plan: "complete" },
    },
    {
      id: 4,
      name: "Eventos",
      icon: <CalendarToday />,
      type: { value: "events", plan: "events" },
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    const isValid = isFormValid();
    setIsCorrectForm(isValid);
  }, [names, prices, type]);

  const isFormValid = () => {
    const nameFilled = {
      "pt-BR": !!names["pt-BR"],
      "en-US": !!names["en-US"],
      es: !!names.es,
      fr: !!names.fr,
      it: !!names.it,
      zh: !!names.zh,
    };

    const priceFilled = {
      BRL: !!prices.BRL.price_minor_units,
      USD: !!prices.USD.price_minor_units,
      EUR: !!prices.EUR.price_minor_units,
    };

    if (!type.plan) {
      return false;
    }

    if (Object.values(nameFilled).every((value) => !value)) {
      return false;
    }

    if (nameFilled["pt-BR"] && !priceFilled.BRL) {
      return false;
    }
    if (nameFilled["en-US"] && !priceFilled.USD) {
      return false;
    }
    if (
      (nameFilled.es || nameFilled.fr || nameFilled.it || nameFilled.zh) &&
      !priceFilled.EUR
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (self) => {
    self.preventDefault();
    setProgress(true);
    if (itemToEdit) {
      try {
        const newPlanData = {
          stripe_product_id: itemToEdit.stripe_product_id,
          id: itemToEdit.id,
          names,
          collection: type.value,
          type: type.plan,
          prices_per_day: prices,
          rules,
        };
        const updatedPlan = await updatePlan(newPlanData);
        dispatch(UpdateRow(updatedPlan));
        closeRegister();
        onToastOpen("Success", "success");
      } catch (error) {
        console.error(error);
        onToastOpen("Error", "error");
      } finally {
        setProgress(false);
      }
      return;
    }
    try {
      const newPlanData = {
        names,
        collection: type.value,
        type: type.plan,
        prices_per_day: prices,
        rules,
      };
      const newPlan = await createPlan(newPlanData);
      dispatch(CreateplansReport(newPlan));
      closeRegister();
      onToastOpen("Success", "success");
    } catch (error) {
      console.error(error);
      onToastOpen("Error", "error");
    } finally {
      setProgress(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <label htmlFor="name">Nome</label>
          <select
            name="languages"
            onChange={(e) => setCurrentNameLanguage(e.target.value)}
            value={currentNameLanguage}
          >
            <option value="pt-BR">PT-BR</option>
            <option value="en-US">EN-US</option>
            <option value="es">ES</option>
            <option value="fr">FR</option>
            <option value="it">IT</option>
            <option value="zh">ZH</option>
          </select>
          {Object.entries(names).map(([key, value]) => (
            <>
              {currentNameLanguage === key && (
                <input
                  key={key}
                  type="text"
                  required
                  value={value}
                  onChange={(self) =>
                    setNames((state) => ({
                      ...state,
                      [key]: self.target.value,
                    }))
                  }
                />
              )}
            </>
          ))}
        </InputGroup>

        <InputGroup>
          <label htmlFor="name">Tipo</label>
          <Select
            variant="standard"
            value={type.plan}
            displayEmpty
            onChange={(self) => setType(self.target.value)}
            renderValue={() => {
              if (!type.plan) {
                return <em>Selecione o tipo de usuário</em>;
              }
              const [displayName] = categoryType.filter(
                (item) => item.type.plan === type.plan
              );

              return displayName.name;
            }}
          >
            <MenuItem disabled value="">
              <em>Selecione o tipo de usuário</em>
            </MenuItem>
            {categoryType.map((item, index) => (
              <MenuItem key={index} value={item.type}>
                <S.TypeListItem>
                  {item.icon}
                  <span>{item.name}</span>
                </S.TypeListItem>
              </MenuItem>
            ))}
          </Select>
        </InputGroup>

        <InputGroupExtended>
          <label htmlFor="Valor">Valor</label>

          <S.Option id="R$">
            R$
            <input
              type="text"
              onChange={(e) =>
                setPrices((prev) => {
                  return {
                    ...prev,
                    BRL: {
                      ...prev.BRL,

                      price_minor_units: e.target.value,
                    },
                  };
                })
              }
              value={prices.BRL.price_minor_units}
            />
          </S.Option>
          <S.Gap />
          <S.Option id="$">
            $
            <input
              type="text"
              onChange={(e) =>
                setPrices((prev) => {
                  return {
                    ...prev,
                    USD: {
                      ...prev.USD,

                      price_minor_units: e.target.value,
                    },
                  };
                })
              }
              value={prices.USD.price_minor_units}
            />
          </S.Option>
          <S.Gap />
          <S.Option id="€">
            €
            <input
              type="text"
              onChange={(e) =>
                setPrices((prev) => {
                  return {
                    ...prev,
                    EUR: {
                      ...prev.EUR,

                      price_minor_units: e.target.value,
                    },
                  };
                })
              }
              value={prices.EUR.price_minor_units}
            />
          </S.Option>
        </InputGroupExtended>
        {/* 
        <InputGroupExtended>
          <label htmlFor="Valor">Pais</label>
          <div>
            <input
              type="text"
              placeholder="Pais"
              required
              value={country}
              onChange={(self) => setCountry(self.target.value)}
              style={{ flex: "1" }}
            />
          </div>
        </InputGroupExtended>

        <InputGroupExtended>
          <label htmlFor="Valor">Regiao</label>
          <div>
            <input
              type="text"
              placeholder="Regiao"
              required
              value={region}
              onChange={(self) => setRegion(self.target.value)}
              style={{ flex: "1" }}
            />
          </div>
        </InputGroupExtended> */}

        <TextAreaGroup>
          <select
            name="languages"
            onChange={(e) => setCurrentRulesLanguage(e.target.value)}
          >
            <option value="pt-BR">PT-BR</option>
            <option value="en-US">EN-US</option>
            <option value="es">ES</option>
            <option value="fr">FR</option>
            <option value="it">IT</option>
            <option value="zh">ZH</option>
          </select>
          <label htmlFor="rules">Regras</label>
          {Object.entries(rules).map(([key, value]) => (
            <>
              {currentRulesLanguage === key && (
                <textarea
                  key={key}
                  id={key}
                  name="rules"
                  cols="20"
                  rows="10"
                  onChange={(self) =>
                    setRules((state) => ({
                      ...state,
                      [key]: self.target.value,
                    }))
                  }
                  value={value}
                  style={{ resize: "none" }}
                ></textarea>
              )}
            </>
          ))}
        </TextAreaGroup>

        <PrimaryButton disabled={!isCorrectForm || progress} type="submit">
          {itemToEdit ? "Salvar" : "Cadastrar"}
        </PrimaryButton>
        {progress && <LinearProgress />}
      </form>
    </>
  );
}
