import * as ToastPrimitive from '@radix-ui/react-toast';
import styled from "styled-components";

export const Root = styled(ToastPrimitive.Root)`
    padding: 1rem;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    
    background-color: ${({ variant }) => variant ? variant : '#fff'};

    &>header{
        display:flex;
        align-items:center;
        justify-content:space-between;
        gap: 0.25rem;
    }

    &[data-state='open']{
        animation:toast-slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state='close']{
        animation: toast-hide 200ms ease-in;
    }
    
    &[data-swipe='move']{
        transform: translateX(var(--radix-toast-swipe-move-x))
    }

    &[data-swip='cancel']{
        transform: translateX(0);
        transition: transform 200ms ease-out;
    }

    &[data-swipe='end']{
        animation: toast-swipeOut 100ms ease-out;
    }

    @keyframes toast-hide {
        from {
            opacity: 1
        }
        to {
            opacity: 0
        }
    }

    @keyframes toast-slideIn {
        from {
            transform: translateX(calc(100% + 32px)) 
        }
        to {
            transform: translateX(0) 
        }
    }

    @keyframes toast-swipeOut {
        from { transform: translateX(var(--radix-toast-swipe-end-x)) }
        to { transform: translateX(calc(100% + 32px)) }
    }
    
`
export const Title = styled(ToastPrimitive.Title)`
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 1.5rem;
    color: white;
`

export const Description = styled(ToastPrimitive.Description)`
    margin: 0;
    color: white;
    font-size: 1rem;
    line-height: 1;
`

export const Close = styled(ToastPrimitive.Close)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    font-weight: medium;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 2.25rem;
    height: 2.25rem;
    background-color: transparent;
    color: ${({ variant }) => variant};
`

export const Viewport = styled(ToastPrimitive.Viewport)`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column; 
    list-style: none;
    outline: none;
    gap: 0.75rem;
    width: 24rem;
    max-width: 100vw;
    z-index: 2147483647;
    padding: 2rem;
`
