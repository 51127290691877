import {
  doc,
  setDoc,
  collection,
  deleteDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";
import { trimObjectValues } from "../utils/trim";

export async function createCategory(categoriesData) {
  const { color, names, collection: type } = categoriesData;

  const documentRef = doc(collection(db, TABLES.CATEGORIES));
  const now = serverTimestamp();

  const newCategory = {
    color,
    names: trimObjectValues(names),
    collection: type,
    id: documentRef.id,
    active: true,
    created_at: now,
    updated_at: now,
  };

  await setDoc(documentRef, newCategory);

  return documentRef.id;
}

export async function updateCategory(categoriesData) {
  const { color, names, collection: type, id } = categoriesData;

  const documentRef = doc(db, TABLES.CATEGORIES, id);

  const updatedCategory = {
    color,
    collection: type,
    names: trimObjectValues(names),
  };

  await updateDoc(documentRef, updatedCategory);
}

export async function remove(categoriesData) {
  const { id } = categoriesData;

  return deleteDoc(doc(db, TABLES.CATEGORIES, id));
}
