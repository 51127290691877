import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OverlayContainer,
  OverlayChild,
  OverlayHeader,
  OverlayBody,
} from "../../../../Components/Global/Reusable";
import { setCategoryState } from "../../../../features/MenuSlice";
import { selectAllTrendsPrices } from "../../../../features/TrendsPricesSlice";
import Form from "../Forms/TrendsForm";
import useHighlightModal from "../../../../Hooks/useHighlightModal";

/**
 * Category Register component
 * Here we define Register Category funcionality
 *
 */
export default function Index({
  editId,
  closeRegister,
  onToastOpen,
  isReplaceModalOpen,
  setIsReplaceModalOpen,
  replaceConfirmation,
  setReplaceConfirmation,
}) {
  const CategoryState = useSelector((state) => state.menu.CategoryState);
  const highlightModal = useHighlightModal();
  const [itemToEdit] = useSelector(selectAllTrendsPrices).filter(
    (item) => item?.id === editId
  );

  return (
    <div style={highlightModal.isOpen ? { display: "none" } : {}}>
      {CategoryState && (
        <OverlayContainer>
          <OverlayChild>
            <OverlayHeader>
              <ArrowBack onClick={closeRegister} />
              <h3>
                {itemToEdit ? "Edição de Destaques" : "Cadastro de Destaques"}
              </h3>
            </OverlayHeader>

            <OverlayBody>
              <Form
                onToastOpen={onToastOpen}
                itemToEdit={itemToEdit}
                closeRegister={closeRegister}
              />
            </OverlayBody>
          </OverlayChild>
        </OverlayContainer>
      )}
    </div>
  );
}
