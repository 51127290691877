import { TableCell, TableRow } from "@mui/material";
import { Edit, DeleteOutline } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import * as S from "./styled";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAll,
  DeleteRow,
  UpdateValue,
} from "../../../features/CategorySlice";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import * as categories from "../../../Services/categories";
import { Modal } from "../../../Components/Modal";
import { PrimaryButton } from "../../../Components/Button";
import { LoadingSpinner } from "../../../Components/Loader";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import { TABLES } from "../../../enums/tables";

export default function Index({
  editItem,
  onToastOpen,
  secondaryFilter,
  orderFilter,
}) {
  const query = useSelector((state) => state.query.query_string);
  const queryNum = useSelector((state) => state.query.queryByNumber);
  const categoryData = useSelector(selectAll);
  const dispatch = useDispatch();
  const [formatedData, setFormatedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);

  async function getDataFromFirebase(collection) {
    const data = await getFirebaseDataByCreationDate(collection);
    dispatch(UpdateValue(data));
  }

  useEffect(() => {
    try {
      getDataFromFirebase(TABLES.CATEGORIES);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    function formatData() {
      setFormatedData(
        categoryData.map((category) => {
          let categoryTranslation;

          if (category.collection === "connectroutes")
            categoryTranslation = "ConnectRoutes";
          if (category.collection === "connectstores")
            categoryTranslation = "ConnectStores";
          if (category.collection === "connectplaces")
            categoryTranslation = "ConnectPlaces";

          return { ...category, type: categoryTranslation };
        })
      );
    }
    formatData();
  }, [categoryData]);

  useEffect(() => {
    if (formatedData) {
      const filtered = formatedData
        .filter((row) => {
          const nameMatch = row?.names?.["pt-BR"].toLowerCase().includes(query);

          const typeMatch = row?.collection?.toLowerCase().includes(query);

          return nameMatch || typeMatch;
        })
        .sort((a, b) => {
          if (orderFilter && orderFilter.id === "name") {
            return orderFilter.order === "asc"
              ? b?.names?.["pt-BR"].localeCompare(a?.names?.["pt-BR"])
              : a?.names?.["pt-BR"].localeCompare(b?.names?.["pt-BR"]);
          }
          if (orderFilter && orderFilter.id === "type") {
            return orderFilter.order === "asc"
              ? b?.collection.localeCompare(a?.collection)
              : a?.collection.localeCompare(b?.collection);
          }

          return 0;
        });
      setFilteredData(filtered);
    }
  }, [query, orderFilter, formatedData]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [queryNum, query]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData.slice(startIndex, endIndex);

    return (
      <>
        {paginatedData.map((row) => {
          return (
            <React.Fragment key={row.id}>
              <ReadOnlyRow
                onToastOpen={onToastOpen}
                data={row}
                setEditId={editItem}
              />
            </React.Fragment>
          );
        })}
        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredData.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage.selected);
            setCurrentPage(selectedPage.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />
      </>
    );
  }
}

export const ReadOnlyRow = ({ data: row, setEditId, onToastOpen }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  async function handleCategoryDelete() {
    try {
      setIsDeleting(true);
      await categories.remove(row);
      dispatch(DeleteRow({ id: row.id }));
      onToastOpen("Success", "success");
    } catch (error) {
      console.error(error);
      onToastOpen("Error", "error");
    } finally {
      setIsDeleting(false);
    }
  }

  function getLocalizedValue(names) {
    const languagePriority = ["pt-BR", "en-US", "es", "it", "fr", "zh"];

    for (const language of languagePriority) {
      if (names[language]) {
        return names[language];
      }
    }

    return "";
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        onOpenChange={(open) => {
          if (!isDeleting) {
            setIsModalOpen(open);
          }
        }}
        title="Atenção!"
        description={`Tem certeza que deseja deletar "${getLocalizedValue(
          row?.names
        )}"?`}
      >
        <S.ModalContainer>
          <PrimaryButton onClick={() => setIsModalOpen(false)}>
            Cancelar
          </PrimaryButton>

          <PrimaryButton
            onClick={handleCategoryDelete}
            style={{ marginLeft: "auto" }}
          >
            {isDeleting ? <LoadingSpinner /> : "Confirmar"}
          </PrimaryButton>
        </S.ModalContainer>
      </Modal>
      <TableRow key={row?.id}>
        <TableCell sx={{ width: "200px" }}>
          <S.styledCell>
            <S.Ball bColor={row?.color} />
            <span>{getLocalizedValue(row?.names)}</span>
          </S.styledCell>
        </TableCell>
        <TableCell>{row?.type}</TableCell>
        <TableCell align="right">
          <Edit color="success" onClick={() => setEditId(row?.id)} />
        </TableCell>
        <TableCell align="right" sx={{ width: "50px" }} colSpan={0}>
          <DeleteOutline color="error" onClick={openModal} />
        </TableCell>
      </TableRow>
    </>
  );
};
