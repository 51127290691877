import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";

export const Overlay = styled(Dialog.Overlay)`
  height: 100vh;
  width: 100vw;
  inset: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
`;

export const Close = styled(Dialog.Close)`
  all: unset;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
  z-index: 9999;
`;

export const Content = styled(Dialog.Content)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  overflow: hidden;
  padding: 50px;

  &,
  * {
    box-sizing: border-box;
  }
`;

export const Title = styled(Dialog.Title)``;

export const Description = styled(Dialog.Description)``;
