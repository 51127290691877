import axios from "axios";
import { useEffect, useState } from "react";

export function useCountriesAndCities() {
  const [countriesAndCities, setCountriesAndCities] = useState({
    cities: [],
    countries: [],
    regions: [],
  });

  async function getCitiesAndCountries() {
    try {
      const [countries, states, cities] = await Promise.all([
        axios.get("/data/countries.json"),
        axios.get("/data/regions.json"),
        axios.get("/data/cities.json"),
      ]);

      const statesWithCities = states.data.filter((state) =>
        cities.data.some((city) => city.state_id === state.id)
      );

      const countriesWithStates = countries.data.filter((country) =>
        statesWithCities.some((state) => state.country_id === country.id)
      );

      const sortedCities = cities.data.sort((a, b) => {
        const cityA = a.name;
        const cityB = b.name;
        if (cityA <= cityB) {
          return -1;
        }

        if (cityA >= cityB) {
          return 1;
        }

        return 0;
      });

      const sortedRegions = statesWithCities.sort((a, b) => {
        const stateA = a.name;
        const stateB = b.name;
        if (stateA <= stateB) {
          return -1;
        }

        if (stateA >= stateB) {
          return 1;
        }

        return 0;
      });

      const sortedcountries = countriesWithStates.sort((a, b) => {
        const countryA = a.name;
        const countryB = b.name;
        if (countryA <= countryB) {
          return -1;
        }

        if (countryA >= countryB) {
          return 1;
        }

        return 0;
      });

      setCountriesAndCities({
        cities: sortedCities,
        regions: sortedRegions,
        countries: sortedcountries,
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCitiesAndCountries();
  }, []);

  return countriesAndCities;
}
