import { DialogContent, Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import * as user from "../../Services/user";
import * as guide from "../../Services/touristGuide";
import * as connectsale from "../../Services/connectsale";
import * as spot from "../../Services/touristSpot";
import * as stablishment from "../../Services/stablishment";
import "./style.css";

/***
 * Confirm Modal
 */

const Collection = Object.freeze({
  users: user,
  connectsales: connectsale,
  connectguides: guide,
  connectplaces: spot,
  connectstores: stablishment,
});

export default function BlockModal({
  setModal,
  data,
  role,
  UpdateRow,
  message,
  miscFunction,
  collection,
  onToastOpen,
}) {
  /**
   * Declare switch functionality
   * @params {data , SetModal , role , Action : UpdateRow }
   */
  const dispatch = useDispatch();
  function handleSwitch() {
    setModal(false);
    Collection[collection].block(data.Id, data.active);
    dispatch(UpdateRow({ id: data.Id, active: data.active }));
    onToastOpen("Success", "success");
  }

  return (
    <DialogContent className="CardWidth-250 normalize">
      <Grid container>
        <Grid item>
          <h3>Deseja Continuar ?</h3>
        </Grid>
        <Grid item className="paddingBottom-30">
          {message ? (
            message
          ) : (
            <>
              Confirma o {data.IsBlocked ? "desbloqueio" : "bloqueio"} do {role}
              <strong> {data.Name}?</strong>
            </>
          )}
        </Grid>
        <Grid
          item
          container
          className="UpperCase alignLeft"
          style={{ gap: "1rem" }}
        >
          <Grid item lg={6}>
            <strong
              className="Pointer"
              onClick={miscFunction ? miscFunction : handleSwitch}
            >
              Confirmar
            </strong>
          </Grid>

          <Grid item lg={6}>
            <strong className="Pointer" onClick={() => setModal(false)}>
              Cancelar
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
}
