import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;

  .content {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    min-width: 250px;
  }

  h3 {
    color: #03738c;
    margin-bottom: 10px;
  }

  .inputsContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      margin-right: 10px;
    }

    input {
      border-radius: 5px;
      padding: 5px;
    }

    input + label {
      margin-left: 2rem;
    }

    span {
      margin: 0 10px;
    }
  }

  .button {
    background-color: #03738c;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .button:disabled {
    cursor: not-allowed;
    background-color: #30a4bd;
  }

  #clear {
    border: 1px solid #03738c;
    background-color: #fff;
    color: #03738c;
  }

  .buttonsWrapper {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 3rem;
  }
`;
