/**
 * In this component we are creating Reusable component
 */

import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { usePlansTrends } from "../../Hooks/usePlansTrends";
import { PrimaryButton } from "../Button";
import { QueryByString, QueryByNumber } from "../Search";

const StyledContainer = styled(Container)({
  "&.MuiContainer-root": {
    paddingTop: "4rem",
    paddingBottom: "8rem",
    "@media (min-width:600px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "@media (min-width:1200px)": {
      maxWidth: "1866px",
    },
  },
});

function StyledTable({
  TableTitle,
  Component,
  Labels,
  TableData,
  SecondComponent,
  aditionalSearchBoxes,
  styledLabel,
}) {
  const { showHighlightsPrices, showHighlights } = usePlansTrends();
  return (
    <StyledContainer sx={{ paddingTop: "4rem", paddingBottom: "8rem" }}>
      <Paper sx={{ borderRadius: "18px" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    borderBottomColor: "transparent",
                    padding: "0 20px",
                    paddingTop: "20px",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  {TableTitle}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="justify" colSpan={12}>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <Grid
                      item
                      container
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item xs>
                        <QueryByString
                          placeholder={styledLabel || "Pesquisar:"}
                        />
                      </Grid>
                      <Grid item xs>
                        {aditionalSearchBoxes}
                      </Grid>
                      {showHighlightsPrices !== undefined &&
                        showHighlights !== undefined && (
                          <Grid item xs>
                            <PrimaryButton onClick={showHighlightsPrices}>
                              {!showHighlights
                                ? "Mostrar Destaques"
                                : "Mostrar Planos"}
                            </PrimaryButton>
                          </Grid>
                        )}
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                      spacing={3}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs>
                        <QueryByNumber />
                      </Grid>

                      <Grid item xs>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          gap="1rem"
                        >
                          <Box>{Component}</Box>

                          {SecondComponent && <Box>{SecondComponent}</Box>}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>{Labels}</TableRow>
            </TableHead>
            <TableBody>{TableData}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </StyledContainer>
  );
}

export default StyledTable;
