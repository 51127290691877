/***
 * Reusable Component finance body data
 */

import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAll, UpdateValue } from "../../../features/UsersReportSlice";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import dayjs from "dayjs";
import { TABLES } from "../../../enums/tables";

export default function Index({
  OpenModal,
  OpenDetails,
  orderFilter,
  dateFilter,
  modalFilter,
  setModalFilter,
}) {
  /***
   * Here we read our retrieved data from redux store
   * */
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const data = useSelector(selectAll);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalFilterSearch, setModalFilterSearch] = useState([]);

  useEffect(() => {
    if (data) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);

      const filtered = data
        ?.filter((row) => {
          let modalMatch = true;
          const nameMatch = row?.name?.toLowerCase()?.includes(query);

          if (modalFilterSearch?.length > 0) {
            const matchingItems = modalFilterSearch?.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.name?.toLowerCase()?.trim()
            );

            modalMatch = matchingItems?.length > 0;
          }

          return (
            nameMatch &&
            modalMatch &&
            (dateFilter
              ? dayjs(row?.created_at)?.isAfter(fromDate) &&
                dayjs(row?.created_at)?.isBefore(toDate?.add(1, "day"))
              : true)
          );
        })
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.name?.localeCompare(a?.name)
              : a?.name?.localeCompare(b?.name);
          }
          if (orderFilter && orderFilter?.id === "date") {
            return orderFilter?.order === "asc"
              ? b?.created_at?.localeCompare(a?.created_at)
              : a?.created_at?.localeCompare(b?.created_at);
          }
          return 0;
        });

      setFilteredData(filtered);
    }
  }, [query, data, orderFilter, dateFilter, modalFilterSearch]);

  async function getDataFromFirebase(collection) {
    const data = await getFirebaseDataByCreationDate(collection);
    setModalFilter((prev) => ({ ...prev, data }));
    dispatch(UpdateValue(data));
  }

  useEffect(() => {
    try {
      getDataFromFirebase(TABLES.USERS);
    } catch (error) {
      console?.error(error);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [query, data, orderFilter, dateFilter, modalFilterSearch, queryNum]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return (
      <>
        {paginatedData?.map((row) => (
          <TableRow hover key={row?.id}>
            <TableCell sx={{ width: "300px" }}>{row?.name}</TableCell>
            <TableCell>
              {dayjs(row?.created_at)?.format("DD[/]MM[/]YYYY")}
            </TableCell>
            <TableCell
              sx={{ width: "70px", color: "#006875", cursor: "pointer" }}
            >
              <RemoveRedEyeOutlined onClick={() => OpenDetails(row?.id)} />
            </TableCell>
            <TableCell sx={{ width: "50px" }} align="left">
              <Switch
                checked={!row?.active}
                color="success"
                onChange={() => OpenModal(!row?.active, row?.id, row?.name)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </TableCell>
          </TableRow>
        ))}

        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {modalFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalFilterSearch}
            setModalFilterSearch={setModalFilterSearch}
            setModalFilter={setModalFilter}
            data={[...new Set(data?.map((item) => item?.name?.trim()))]?.map(
              (item) => ({
                name: item,
              })
            )}
            labelPath="name"
            label="nome"
          />
        )}
      </>
    );
  }
}
