/****
 * Shops Report Page Component
 * In this component we declare all the Shops Report page functionlities
 * we Diiplay Shops Report data in this compont
 */
import { AppContainer } from "../../../Components/Global/Reusable";
import React, { useEffect, useState, useRef } from "react";
import DialogModal from "../../../Components/DialogModal";
import { ShowTotal } from "../../../Components/Global/Reusable";
import StyledTable from "../../../Components/Table";
import { UpdateRow } from "../../../features/ShopsReportSlice";
import BodyData from "./BodyData";
import Details from "./Details";
import Labels from "./Labels";
import BlockModal from "../../../Components/BlockModal";
import ImageModal from "../../../Components/DialogModal/ImageModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";
import { QueryByString } from "../../../Components/LocalSearch";
import { useSelector } from "react-redux";
import { selectAll } from "../../../features/ShopsReportSlice";
import { TABLES } from "../../../enums/tables";

export default function Index() {
  const [data, setData] = useState(null);
  const [Id, setId] = useState(null);
  const [details, setDetails] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [secondaryFilter, setSecondaryFilter] = useState("");
  const [orderFilter, setOrderFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [modalNameFilter, setModalNameFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalItineraryFilter, setModalItineraryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCategoryFilter, setModalCategoryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCountryFilter, setModalCountryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCityFilter, setModalCityFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });

  const timerRef = useRef(0);

  /***
   * Declare a method to Open and close Details
   * We are receiving {row.id} parameters
   */

  function OpenDetails(rowId) {
    setDetails(true);
    setId(rowId);
  }

  function CloseDetails() {
    setDetails(false);
  }

  /**
   * Declare Method to Open Modal for Confirmation
   * Declare switch functionality
   */
  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  function OpenModal(rowIsBlocked, rowId, rowName) {
    setData({
      Id: rowId,
      active: rowIsBlocked,
      Name: rowName,
    });
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);

  const items = useSelector(selectAll);

  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="ConnectStores"
          Component={<ShowTotal total={items.length} />}
          Labels={
            <Labels
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalItineraryFilter={modalItineraryFilter}
              setModalItineraryFilter={setModalItineraryFilter}
              modalCategoryFilter={modalCategoryFilter}
              setModalCategoryFilter={setModalCategoryFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
            />
          }
          TableData={
            <BodyData
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalItineraryFilter={modalItineraryFilter}
              setModalItineraryFilter={setModalItineraryFilter}
              modalCategoryFilter={modalCategoryFilter}
              setModalCategoryFilter={setModalCategoryFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
              secondaryFilter={secondaryFilter}
              OpenDetails={OpenDetails}
              OpenModal={OpenModal}
              orderFilter={orderFilter}
              dateFilter={dateFilter}
            />
          }
        />
        {details && (
          <Details
            CloseDetails={CloseDetails}
            detailsID={Id}
            openPreview={setPreview}
          />
        )}
        {data && (
          <DialogModal>
            <BlockModal
              onToastOpen={onToastOpen}
              data={data}
              setModal={setData}
              UpdateRow={UpdateRow}
              role="ConnectStore"
              collection={TABLES.CONNECTSTORES}
            />
          </DialogModal>
        )}

        {preview && (
          <DialogModal>
            <ImageModal openPreview={setPreview} image={preview} />
          </DialogModal>
        )}
      </AppContainer>
    </>
  );
}
