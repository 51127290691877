import { createSlice } from "@reduxjs/toolkit";

const purchaseSlice = createSlice({
  name: "purchase",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
    DeleteRow(state, { payload }) {
      state.value = state.value.filter((item) => item.id !== payload.id);
    },
  },
});

export const selectAll = (state) => state.purchase.value;
export const { DeleteRow, UpdateRow, UpdateValue } = purchaseSlice.actions;
export default purchaseSlice.reducer;
