import { createSlice } from "@reduxjs/toolkit";

const ItineraryReportSlice = createSlice({
  name: "itineraryReport",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.forEach((item) => {
        if (item.id === payload.id) {
          Object.assign(item, payload);
        }
      });
    },
    DeleteRow(state, { payload }) {
      state.value = state.value.filter((item) => item.id !== payload.id);
    },
  },
});

export const selectAll = (state) => state.itineraryReport.value;
export const { DeleteRow, UpdateRow, UpdateValue } =
  ItineraryReportSlice.actions;
export default ItineraryReportSlice.reducer;
