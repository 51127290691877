export const trimObjectValues = (obj) => {
  const trimmedObject = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      trimmedObject[key] = typeof value === "string" ? value.trim() : value;
    }
  }

  return trimmedObject;
};
