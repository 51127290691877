/****
 * Users Report Page Component
 * In this component we declare all the Users Report page functionlities
 * we Diiplay Users Report data in this compont
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContainer, ShowTotal } from "../../../Components/Global/Reusable";
import StyledTable from "../../../Components/Table";
import { useAuth } from "../../../Hooks/useAuth";
import TableData from "./BodyData";
import Details from "./Details";
import Labels from "./Labels";

export default function Index() {
  const [data, setData] = useState(null);
  const [Id, setId] = useState(null);
  const [details, setDetails] = useState(false);
  const [orderFilter, setOrderFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [purchaseData, setPurchaseData] = useState(null);
  const [modalNameFilter, setModalNameFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalTypeFilter, setModalTypeFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalModelFilter, setModalModelFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [currencyFilter, setCurrencyFilter] = useState({
    isOpen: false,
    value: "BRL",
  });
  const [modalActiveFilter, setModalActiveFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });

  /***
   * Declare a method to Open and close Details
   * We are receiving {row.id} parameters
   */

  function OpenDetails(rowId) {
    setDetails(true);
    setId(rowId);
  }

  function CloseDetails() {
    setDetails(false);
  }

  /**
   * Declare Method to Open Modal for Confirmation
   * Declare switch functionality
   */
  function OpenModal(rowIsBlocked, rowId, rowName) {
    setData({
      Id: rowId,
      IsBlocked: rowIsBlocked,
      Name: rowName,
    });
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);

  return (
    <>
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="Assinaturas"
          Labels={
            <Labels
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalTypeFilter={modalTypeFilter}
              setModalTypeFilter={setModalTypeFilter}
              modalModelFilter={modalModelFilter}
              setModalModelFilter={setModalModelFilter}
              currencyFilter={currencyFilter}
              setCurrencyFilter={setCurrencyFilter}
              modalActiveFilter={modalActiveFilter}
              setModalActiveFilter={setModalActiveFilter}
            />
          }
          Component={<ShowTotal total={purchaseData?.length} />}
          TableData={
            <TableData
              OpenModal={OpenModal}
              OpenDetails={OpenDetails}
              dateFilter={dateFilter}
              orderFilter={orderFilter}
              setPurchaseData={setPurchaseData}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalTypeFilter={modalTypeFilter}
              setModalTypeFilter={setModalTypeFilter}
              modalModelFilter={modalModelFilter}
              setModalModelFilter={setModalModelFilter}
              currencyFilter={currencyFilter}
              setCurrencyFilter={setCurrencyFilter}
              modalActiveFilter={modalActiveFilter}
              setModalActiveFilter={setModalActiveFilter}
            />
          }
        />
        {details && <Details CloseDetails={CloseDetails} detailsID={Id} />}
      </AppContainer>
    </>
  );
}
