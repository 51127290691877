export const PLAN_NATURE = Object.freeze({
  HIGHLIGHT: "highlight",
  PLAN: "plan",
});

export const PAYMENT_STATUS = Object.freeze({
  PENDING: "pending",
  REPROVED: "reproved",
  APPROVED: "approved",
  CANCELED: "canceled",
  PAUSED: "paused",
  EXPIRED: "expired",
  SUSPENDED: "suspended",
});
