import { TableCell, TableRow } from "@mui/material";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAll, UpdateValue } from "../../../features/DenunciationSlice";
import dayjs from "dayjs";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import { TABLES } from "../../../enums/tables";

export default function Index({ OpenDetails, orderFilter, dateFilter }) {
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const denunciationData = useSelector(selectAll);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);

  async function getDataFromFirebase(collection) {
    const data = await getFirebaseDataByCreationDate(collection);
    dispatch(UpdateValue(data));
  }

  useEffect(() => {
    if (denunciationData) {
      const toDate = new Date(dateFilter?.to);
      const fromDate = new Date(dateFilter?.from);

      const filtered = denunciationData
        ?.filter((row) => {
          const nameMatch = row?.reported_by?.name
            ?.toLowerCase()
            ?.includes(query);

          return dateFilter
            ? new Date(row?.created_at) >= fromDate &&
                new Date(row?.created_at) <=
                  toDate?.setDate(toDate?.getDate() + 1)
            : true && nameMatch;
        })
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "reported_by") {
            return orderFilter?.order === "asc"
              ? b?.reported_by?.name?.localeCompare(a?.reported_by?.name)
              : a?.reported_by?.name?.localeCompare(b?.reported_by?.name);
          }
          if (orderFilter && orderFilter?.id === "date") {
            return orderFilter?.order === "asc"
              ? b?.created_at?.localeCompare(a?.created_at)
              : a?.created_at?.localeCompare(b?.created_at);
          }
          return 0;
        });
      setFilteredData(filtered);
    }
  }, [query, orderFilter, dateFilter, denunciationData]);

  useEffect(() => {
    try {
      getDataFromFirebase(TABLES.REPORTS);
    } catch (error) {
      console?.error(error);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [queryNum, dateFilter, query]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);

    return (
      <>
        {paginatedData?.map((row) => {
          return (
            <TableRow hover key={row?.id}>
              <TableCell sx={{ width: "300px" }}>
                {row?.reported_by?.name}
              </TableCell>
              <TableCell>
                {dayjs(row?.created_at)?.format("DD[/]MM[/]YYYY")}
              </TableCell>
              <TableCell
                sx={{ width: "70px", color: "#006875", cursor: "pointer" }}
                align="center"
              >
                <RemoveRedEyeOutlined onClick={() => OpenDetails(row?.id)} />
              </TableCell>
            </TableRow>
          );
        })}
        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"?.?.?."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />
      </>
    );
  }
}
