/****
 * In this component we declare all functionlities for Category Page
 * We retrieve data from our server
 */

import { AppContainer } from "../../../Components/Global/Reusable";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../../Components/Button";
import StyledTable from "../../../Components/Table/index";
import {
  setCategoryState,
  setPlanRegisterState,
} from "../../../features/MenuSlice";
import TableData from "./bodyData";
import TableLabels from "./Labels";
import RegisterPlans from "./Register/RegisterPlans";
import RegisterTrends from "./Register/RegisterTrends";
import { PlansTrendsProvider } from "../../../Contexts/PlansTrendsContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";
import { Modal } from "../../../Components/Modal";
import * as S from "./styled";
import useHighlightModal from "../../../Hooks/useHighlightModal";
import { LoadingSpinner } from "../../../Components/Loader";

export default function Index() {
  const [actualEditIdPlans, setActualEditIdPlans] = useState("");
  const [actualEditIdTrends, setActualEditIdTrends] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [secondaryFilter, setSecondaryFilter] = useState("");
  const [orderFilter, setOrderFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef(0);
  const dispatch = useDispatch();

  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  function openEditPlans(id) {
    setActualEditIdPlans(id);
    dispatch(setPlanRegisterState());
  }

  function closeEditPlans() {
    setActualEditIdPlans("");
    dispatch(setPlanRegisterState());
  }
  function openEditTrends(id) {
    setActualEditIdTrends(id);
    dispatch(setCategoryState());
  }

  function closeEditTrends() {
    setActualEditIdTrends("");
    dispatch(setCategoryState());
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();
  const highlightModal = useHighlightModal();

  useEffect(() => {
    setIsLoading(false);
  }, [highlightModal.isOpen]);

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);

  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <PlansTrendsProvider>
        <AppContainer maxWidth="xl">
          <StyledTable
            TableTitle="Planos de Assinaturas"
            Component={
              <PrimaryButton big onClick={() => dispatch(setCategoryState())}>
                Cadastrar Destaques
              </PrimaryButton>
            }
            SecondComponent={
              <PrimaryButton
                big
                onClick={() => dispatch(setPlanRegisterState())}
              >
                Cadastrar Planos
              </PrimaryButton>
            }
            Labels={
              <TableLabels
                orderFilter={orderFilter}
                setOrderFilter={setOrderFilter}
              />
            }
            TableData={
              <TableData
                onToastOpen={onToastOpen}
                editItemPlans={openEditPlans}
                editItemTrends={openEditTrends}
                orderFilter={orderFilter}
                secondaryFilter={secondaryFilter}
              />
            }
          />

          <RegisterTrends
            onToastOpen={onToastOpen}
            editId={actualEditIdTrends}
            closeRegister={closeEditTrends}
          />

          <RegisterPlans
            onToastOpen={onToastOpen}
            editId={actualEditIdPlans}
            closeRegister={closeEditPlans}
          />

          <Modal
            open={highlightModal.isOpen}
            onOpenChange={() => {
              if (!highlightModal.isOpen) {
                return highlightModal.onOpen();
              }
              return highlightModal.onClose();
            }}
            title="Plano de highlight existente!"
            description="Já existe um  plano cadastrado de para este mesmo tipo e local. Caso prossiga, o highlight que existe será sobrescrevido."
          >
            <S.ModalContainer>
              <PrimaryButton
                disabled={isLoading}
                onClick={() => highlightModal.onReset()}
              >
                Cancelar
              </PrimaryButton>

              <PrimaryButton
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  highlightModal.onAgreeReplace();
                }}
                style={{ marginLeft: "auto" }}
              >
                {isLoading ? <LoadingSpinner /> : "Confirmar"}
              </PrimaryButton>
            </S.ModalContainer>
          </Modal>
        </AppContainer>
      </PlansTrendsProvider>
    </>
  );
}
