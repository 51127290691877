import {
  doc,
  deleteDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";
import { getFirebaseData } from "./firebase";

export async function update(itineraryData) {
  const { name, categories, id } = itineraryData;

  const documentRef = doc(db, TABLES.CONNECTROUTES, id);

  await updateDoc(documentRef, {
    name,
    categories,
  });
}

export async function remove(itineraryData) {
  const { id } = itineraryData;

  return deleteDoc(doc(db, TABLES.CONNECTROUTES, id));
}

export const getNumberOfItinerariesWithStopId = async (stopId) => {
  const itinerariesColRef = collection(db, TABLES.CONNECTROUTES);

  const itinerariesSnap = await getDocs(itinerariesColRef);

  const itinerariesWithLocation = itinerariesSnap.docs.filter((doc) => {
    const itinerary = doc.data();

    return itinerary.stops.some((stop) => stop.id === stopId);
  });

  return itinerariesWithLocation.length;
};

export const getEstablishmentsAndTouristSpotsFromItineraries = async () => {
  const itineraries = await getFirebaseData(TABLES.CONNECTROUTES);

  let establishments = [];
  let touristSpots = [];

  itineraries.forEach((itinerary) => {
    itinerary.stops.forEach((stop) => {
      if (stop?.collection_name === TABLES.CONNECTPLACES) {
        touristSpots.push(stop);
      }
      if (stop?.collection_name === TABLES.CONNECTSTORES) {
        establishments.push(stop);
      }
    });

    return itinerary;
  });

  return {
    establishments: establishments,
    touristSpots: touristSpots,
  };
};
