import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Description,
  ImagesAlbum,
  OverlayBody,
  OverlayChild,
  OverlayContainer,
  OverlayHeader,
  StyledProfile,
  Title,
  ContactInfo,
  ActionButtons,
  GuideInfo,
  LanguagesInfo,
} from "../../../Components/Global/Reusable";
import { selectAll } from "../../../features/GuideReportSlice";
import {
  approveDocuments,
  rejectDocuments,
} from "../../../Services/connectsale";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { UpdateValue } from "../../../features/GuideReportSlice";
import { TABLES } from "../../../enums/tables";

export default function Index({ CloseDetails, detailsID, openPreview }) {
  const reportData = useSelector(selectAll);
  const dispatch = useDispatch();

  async function handleRejectDocuments(id) {
    try {
      await rejectDocuments(id);
      await getDataFromFirebase();
      CloseDetails();
    } catch (error) {
      console.error(error);
    }
  }

  async function handleApproveDocuments(id) {
    try {
      await approveDocuments(id);
      await getDataFromFirebase();
      CloseDetails();
    } catch (error) {
      console.error(error);
    }
  }

  async function getDataFromFirebase() {
    const data = await getFirebaseDataByCreationDate(TABLES.CONNECTSALES);
    dispatch(UpdateValue(data));
  }

  return (
    <OverlayContainer>
      <OverlayChild style={{ width: "400px" }}>
        <OverlayHeader>
          <ArrowBack onClick={CloseDetails} />
          <Title>Detalhes</Title>
        </OverlayHeader>
        {reportData.length > 0 &&
          reportData
            .filter((item) => item.id === detailsID)
            .map((item, index) => (
              <OverlayBody
                style={{ gap: "15px", paddingTop: "15px" }}
                key={index}
              >
                <Profile name={item.name} image={item.images[0]} />
                <Description desc={item.description} />
                <ContactInfo contact={item.contact} />
                <GuideInfo
                  info={{
                    fiscal_code: item.fiscal_code,
                    instituition: item.instituition,
                  }}
                />
                <LanguagesInfo languages={item.spoken_languages} />
                <ImagesAlbum photos={item.images} openPreview={openPreview} />
                {item.document?.front && item.document?.back && (
                  <>
                    <ImagesAlbum
                      photos={[item.document.front, item.document.back]}
                      openPreview={openPreview}
                      title="Documentos"
                    />
                    {item.document.status === "pending" && (
                      <ActionButtons
                        approve={() => handleApproveDocuments(item.id)}
                        reprove={() => handleRejectDocuments(item.id)}
                      />
                    )}
                  </>
                )}
              </OverlayBody>
            ))}
      </OverlayChild>
    </OverlayContainer>
  );
}

export const Profile = ({ image, name }) => {
  return (
    <StyledProfile>
      <div style={{ display: "grid", gap: "5px", alignContent: "center" }}>
        <Title style={{ margin: "5px 0 0 0", fontSize: "1.3rem" }}>
          {name}
        </Title>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={image}
          alt="profileImage"
          style={{
            objectFit: "cover",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            border: "2px solid #006875",
            cursor: "initial",
          }}
        />
      </div>
    </StyledProfile>
  );
};
