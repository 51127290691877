/***
 *
 * In this Component we Display details for TuristicPointReport
 */

import { ArrowBack } from "@mui/icons-material";
import { Alert, Button } from "@mui/material"; // Importa o Button do MUI
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import place1 from "../../../Assets/Images/place01.webp";
import place2 from "../../../Assets/Images/place02.webp";
import place3 from "../../../Assets/Images/place03.webp";
import place4 from "../../../Assets/Images/place04.webp";
import {
  Address,
  ImagesAlbum,
  OverlayBody,
  OverlayChild,
  OverlayContainer,
  OverlayHeader,
  Title,
  Description,
  ActionButtons,
} from "../../../Components/Global/Reusable";
import {
  selectAll,
} from "../../../features/TuristicPointsReportSlice";
import * as spot from "../../../Services/touristSpot";

export default function Index({ CloseDetails, detailsID, openPreview }) {
  const reportData = useSelector(selectAll);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const images = [place1, place2, place3, place4, place3, place1];

  async function Active({ id }) {
    await spot.approve(id);
    setMessage("Aprovado");
    setTimeout(() => CloseDetails(), 1500);
  }

  async function Block({ id }) {
    await spot.reprove(id);
    setMessage("Reprovado");
    setTimeout(() => CloseDetails(), 1500);
  }

  // Função para deletar o item
  async function handleDelete(id) {
    await spot.deleteSpot(id);
    setMessage("Excluído com sucesso");
    setTimeout(() => CloseDetails(), 1500);
    setTimeout(() => window.location.reload(), 1);
  }

  return (
    <OverlayContainer>
      <OverlayChild>
        <OverlayHeader>
          <ArrowBack onClick={() => CloseDetails()} />
          <Title>Detalhes</Title>
        </OverlayHeader>

        {reportData
          .filter((item) => item.id === detailsID)
          .map((item, index) => (
            <OverlayBody
              style={{ gap: "15px", paddingTop: "40px" }}
              key={index}
            >
              <Title>{item.name}</Title>
              <Description desc={item.description} />
              <Address
                address={`${item.address.cep},${item.address.street} ${item.address.number},${item.address.city} - ${item.address.country}`}
              />
              <ImagesAlbum photos={item.images} openPreview={openPreview} />
              {message && <Alert severity="success">{message}</Alert>}

              {/* Exibe o botão de excluir */}
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDelete(item.id)}
              >
                Excluir
              </Button>

              {item.status === "pending" && (
                <ActionButtons id={item.id} approve={Active} reprove={Block} />
              )}
            </OverlayBody>
          ))}
      </OverlayChild>
    </OverlayContainer>
  );
}
