import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TABLES } from "../enums/tables";

const USER_STATUS = Object.freeze({
  Approved: true,
  Reproved: false,
});

export async function block(id, blockValue) {
  const userReference = doc(db, TABLES.USERS, id);

  await updateDoc(userReference, {
    active: blockValue,
  });
}

export async function approve(id) {
  const userReference = doc(db, TABLES.USERS, id);

  await updateDoc(userReference, {
    active: USER_STATUS.Approved,
  });
}

export async function reprove(id) {
  const userReference = doc(db, TABLES.USERS, id);

  await updateDoc(userReference, {
    active: USER_STATUS.Reproved,
  });
}
