export const TABLES = Object.freeze({
  ACCOUNTS: "accounts",
  NOTIFICATIONS: "notifications",
  FAVORITES: "favorites",
  CATEGORIES: "categories",
  CONNECTSALES: "connectsales",
  CONNECTWATCHES: "connectwatches",
  CONNECTROUTES: "connectroutes",
  CONNECTPLACES: "connectplaces",
  CONNECTSTORES: "connectstores",
  CONNECTGUIDES: "connectguides",
  CONNECTPOINTS: "connectpoints",
  PURCHASES: "purchases",
  PLANS: "plans",
  HIGHLIGHTS: "highlights",
  EVENTS: "events",
  LEGAL_DOCUMENTS: "legal_documents",
  COMMENTS: "comments",
  REPORTS: "reports",
  USERS: "users",
});
