import React, { useState } from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";
import { StyledTableCell } from "./styled";
import DateFilter from "../../../Components/DateFilter/DateFilter";
/****
 * LABELS FOR Category TABLE TO MAKE OUR TABLE REUSABLE
 */
export default function Index({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalItineraryFilter,
  setModalItineraryFilter,
  setModalCategoryFilter,
  modalCountryFilter,
  setModalCountryFilter,
  modalCityFilter,
  setModalCityFilter,
  modalStateFilter,
  setModalStateFilter,
}) {
  /*Este sao dados para monstrar na tabela (COLUMNS)! 
Como estamos na fase de teste, usei estes dados como amostra
*/
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const filtersArray = [
    modalNameFilter,
    modalItineraryFilter,
    modalCountryFilter,
    modalCityFilter,
    modalStateFilter,
  ];

  const columns = [
    { id: "code", label: "Codigo", align: "left" },
    { id: "name", label: "Nome", align: "left" },
    { id: "itineraryCount", label: "ConnectRoutes", align: "center" },
    { id: "city", label: "Cidade", align: "left" },
    { id: "state", label: "Estado", align: "left" },
    { id: "country", label: "Pais", align: "left" },
    { id: "date", label: "Data", align: "left" },
    { id: "details", label: "Detalhes", align: "center" },
    { id: "block", label: "Bloquear", align: "right" },
  ];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }

  function handleOpenNameFilter(id) {
    setModalNameFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenItineraryFilter(id) {
    setModalItineraryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCategoryFilter(id) {
    setModalCategoryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCountryFilter(id) {
    setModalCountryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCityFilter(id) {
    setModalCityFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }
  function handleOpenStateFilter(id) {
    setModalStateFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleClick(id) {
    if (id === "date") return openDateFilter(id);
    if (id === "name") return handleOpenNameFilter(id);
    if (id === "itineraryCount") return handleOpenItineraryFilter(id);
    if (id === "category") return handleOpenCategoryFilter(id);
    if (id === "country") return handleOpenCountryFilter(id);
    if (id === "state") return handleOpenStateFilter(id);
    if (id === "city") return handleOpenCityFilter(id);
    return handleChangeOrder(id);
  }

  return (
    <>
      {
        //=>Here we looping through our received data from the props
        columns.map((column) => {
          let isFiltered = filtersArray.some(
            (item) => item.type === column.id && item.isUsing
          );
          return (
            <StyledTableCell
              onClick={() => handleClick(column.id)}
              columnId={column.id}
              key={column.id}
              align={column.align}
            >
              <div>
                {column.label}

                {column.id !== "details" &&
                  column.id !== "block" &&
                  column.id !== "code" && (
                    <div>
                      <ArrowUp
                        size={16}
                        weight="regular"
                        color={`${
                          (orderFilter &&
                            orderFilter.id === column.id &&
                            orderFilter.order === "desc") ||
                          isFiltered
                            ? "#06bee7"
                            : "rgba(0, 0, 0, 0.87)"
                        }`}
                      />
                      <ArrowDown
                        color={`${
                          (orderFilter &&
                            orderFilter.id === column.id &&
                            orderFilter.order === "asc") ||
                          isFiltered
                            ? "#06bee7"
                            : "rgba(0, 0, 0, 0.87)"
                        }`}
                        size={16}
                        weight="regular"
                        style={{ marginLeft: "-4px" }}
                      />
                    </div>
                  )}
              </div>
            </StyledTableCell>
          );
        })
      }
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
