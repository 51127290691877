/****
 * At this component declare the (Indicadores) Funcionalities
 *
 * */
import { CardContent, CardHeader, CardMedia } from "@mui/material";
import React, { useEffect } from "react";
import { AppContainer } from "../../../Components/Global/Reusable";
import PaperAndPen from "../../../Assets/Svgs/paper_and_pen.svg";
import Binocular from "../../../Assets/Svgs/binocular.svg";
import MapPin from "../../../Assets/Svgs/map_pin.svg";
import Stablishments from "../../../Assets/Svgs/market.svg";
import Flag from "../../../Assets/Svgs/flag.svg";
import User from "../../../Assets/Svgs/user.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  getFirebaseData,
  getFirebaseDataByCreationDate,
} from "../../../Services/firebase";
import { Card, Home, Wrapper } from "./styled";
import {
  UpdateValue as userUpdateValue,
  selectAll as userSelectAll,
} from "../../../features/UsersReportSlice";
import {
  UpdateValue as guideUpdateValue,
  selectAll as guideSelectAll,
} from "../../../features/GuideReportSlice";
import {
  UpdateValue as connectsaleUpdateValue,
  selectAll as connectsaleSelectAll,
} from "../../../features/ConnectsalesSlice";
import {
  UpdateValue as stablishmentUpdateValue,
  selectAll as stablishmentSelectAll,
} from "../../../features/ShopsReportSlice";
import {
  UpdateValue as itineraryUpdateValue,
  selectAll as itinerarySelectAll,
} from "../../../features/ItineraryReportSlice";
import {
  UpdateValue as spotUpdateValue,
  selectAll as spotSelectAll,
} from "../../../features/TuristicPointsReportSlice";
import {
  UpdateValue as purchaseUpdateValue,
  selectAll as purchaseSelectAll,
} from "../../../features/PurchaseSlice";
import {
  UpdateValue as agendaUpdateValue,
  selectAll as agendaSelectAll,
} from "../../../features/AgendaSlice";
import {
  UpdateValue as connectpointUpdateValue,
  selectAll as connectpointSelectAll,
} from "../../../features/ConnectpointSlice";

import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import moment from "moment";
import { useState } from "react";
import { TABLES } from "../../../enums/tables";
import dayjs from "dayjs";

export default function Index() {
  const months = [
    "Janeiro",
    "Fervereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();
  const usersData = useSelector(userSelectAll);
  const guidesData = useSelector(guideSelectAll);
  const connectsalesData = useSelector(connectsaleSelectAll);
  const stablishmentsData = useSelector(stablishmentSelectAll);
  const itinerariesData = useSelector(itinerarySelectAll);
  const spotsData = useSelector(spotSelectAll);
  const purchaseData = useSelector(purchaseSelectAll);
  const agendaData = useSelector(agendaSelectAll);
  const connectpoint = useSelector(connectpointSelectAll);
  const [currentMonth] = useState(moment()?.format("YYYY-MM"));
  const [plans, setPlans] = React?.useState(null);
  const [highlights, setHighlights] = useState([]);

  async function getDatas() {
    const highlights = await getFirebaseDataByCreationDate(TABLES?.HIGHLIGHTS);
    setHighlights(highlights);
    const userData = await getFirebaseDataByCreationDate(TABLES.USERS);
    dispatch(userUpdateValue(userData));
    const guidesData = await getFirebaseDataByCreationDate(
      TABLES.CONNECTGUIDES
    );
    dispatch(guideUpdateValue(guidesData));
    const stablishmentsData = await getFirebaseData(TABLES.CONNECTSTORES);
    dispatch(stablishmentUpdateValue(stablishmentsData));
    const itinerariesData = await getFirebaseData(TABLES.CONNECTROUTES);
    dispatch(itineraryUpdateValue(itinerariesData));
    const touristSpotData = await getFirebaseData(TABLES.CONNECTPLACES);
    dispatch(spotUpdateValue(touristSpotData));
    let plans = await getFirebaseData(TABLES.PLANS);
    setPlans(plans);
    let purchasesData = await getFirebaseDataByCreationDate(TABLES.PURCHASES);
    dispatch(purchaseUpdateValue(purchasesData));
    let agendaData = await getFirebaseData(TABLES.EVENTS);
    dispatch(agendaUpdateValue(agendaData));
    let connectpoint = await getFirebaseData(TABLES.CONNECTPOINTS);
    dispatch(connectpointUpdateValue(connectpoint));
    let connectsales = await getFirebaseData(TABLES.CONNECTSALES);
    dispatch(connectsaleUpdateValue(connectsales));
  }

  useEffect(() => {
    try {
      getDatas();
      if (currentUserData && currentUserData?.role === "user") {
        (async () => {
          await logout();
          navigate("/admin/login");
        })();
      }
    } catch (error) {
      console?.error(error);
    }
  }, []);

  const totalValueMonth = purchaseData
    ?.filter(
      (purchase) =>
        purchase?.status === "approved" &&
        purchase?.plan?.collection !== "events" &&
        purchase?.plan?.nature !== "highlight" &&
        purchase?.plan?.collection !== "connectwatches"
    )
    ?.reduce((total, purchase) => {
      const purchaseMonth = moment(purchase?.created_at)?.format("YYYY-MM");
      if (purchaseMonth === currentMonth) {
        total += purchase.plan?.prices?.BRL?.price_minor_units / 100;
      }
      return total;
    }, 0);

  const totalValue = purchaseData
    ?.filter(
      (purchase) =>
        purchase?.status === "approved" &&
        purchase?.plan &&
        purchase?.plan?.collection !== "events" &&
        purchase?.plan?.nature !== "highlight" &&
        purchase?.plan?.collection !== "connectwatches"
    )
    ?.reduce((total, purchase) => {
      return (total += purchase.plan?.prices?.BRL?.price_minor_units / 100);
    }, 0);

  const totalAgendaValue = purchaseData
    ?.filter(
      (purchase) =>
        purchase?.status === "approved" &&
        purchase?.plan &&
        purchase?.plan?.collection !== "events"
    )
    ?.reduce((total, purchase) => {
      const purchaseMonthFrom = moment(purchase?.location?.from)?.format(
        "YYYY-MM"
      );
      const purchaseMonthTo = moment(purchase?.location?.to)?.format("YYYY-MM");

      const days = dayjs(purchase?.location?.to).diff(
        dayjs(purchase?.location?.from),
        "day"
      );

      if (purchaseMonthFrom === currentMonth || purchaseMonthTo) {
        if (NaN) return total;
        return (total +=
          purchase?.plan?.prices_per_day?.[days]?.currency?.BRL
            ?.price_minor_units / 100 || 0);
      }
      return total;
    }, 0);

  const Data = [
    {
      id: 1,
      title: "Usuario",
      value: usersData?.length,
      icon: User,
      link: "/admin/relatorio_de_usuario",
    },
    {
      id: 2,
      title: "ConnectGuides",
      value: guidesData?.length,
      icon: Flag,
      link: "/admin/relatorio_de_guias_turisticas",
    },
    {
      id: 3,
      title: "ConnectSales",
      value: connectsalesData?.length,
      icon: Flag,
      link: "/admin/relatorio_de_connectsales",
    },
    {
      id: 4,
      title: "ConnectStore",
      value: stablishmentsData?.length,
      icon: Stablishments,
      link: "/admin/relatorio_de_estabelecimentos",
    },
    {
      id: 5,
      title: "ConnectRoutes",
      value: itinerariesData?.length,
      icon: MapPin,
      link: "/admin/relatorio_de_roteiros",
    },
    {
      id: 6,
      title: "ConnectPlaces",
      value: spotsData?.length,
      icon: Binocular,
      link: "/admin/relatorio_de_pontos_turisticos",
    },
  ];

  let Data2 = [
    {
      id: 1,
      title: "Assinaturas",
      icon: PaperAndPen,
      link: "/admin/relatorio_de_assinaturas",
      value:
        purchaseData?.filter((purchase) => {
          return (
            purchase?.status === "approved" &&
            purchase?.plan &&
            purchase?.plan?.collection !== "events" &&
            purchase?.plan?.nature !== "highlight" &&
            purchase?.plan?.collection !== "connectwatches"
          );
        })?.length || 0,
    },
    {
      id: 2,
      title: `Assinaturas (${months[new Date()?.getMonth()]})`,
      value: `R$ ${totalValueMonth}`,
      icon: PaperAndPen,
      link: `/admin/relatorio_de_assinaturas?month=${
        new Date()?.getMonth() + 1
      }`,
    },
    {
      id: 3,
      title: "Assinaturas (Total)",
      value: `R$ ${totalValue}`,
      icon: PaperAndPen,
      link: "/admin/relatorio_de_assinaturas",
    },
    {
      id: 4,
      title: `Agenda de eventos (${months[new Date()?.getMonth()]})`,
      value: `R$ ${totalAgendaValue}`,
      icon: PaperAndPen,
      link: "/admin/agenda",
    },
    {
      id: 5,
      title: "ConnectPoints",
      value: connectpoint?.length,
      icon: PaperAndPen,
      link: "/admin/relatorio_de_connectpoints",
    },
    {
      id: 5,
      title: "Highlights",
      icon: PaperAndPen,
      link: "/admin/relatorio_de_highlights",
      value: highlights?.length || 0,
    },
  ];

  return (
    <AppContainer maxWidth="xl">
      <Home>
        <h3>Indicadores</h3>
        <Wrapper>
          {Data?.map((item) => (
            <IndicatorCard key={item?.id} item={item} />
          ))}
        </Wrapper>

        <Wrapper>
          {Data2?.map((item) => {
            return <IndicatorCard key={item?.id} item={item} />;
          })}
        </Wrapper>
      </Home>
    </AppContainer>
  );
}

/***
 * Here we are going to create card component to display in our Home Component
 */

const IndicatorCard = ({ item }) => {
  return (
    <>
      <Card sx={{ borderRadius: "10px" }}>
        <Link to={item?.link}>
          <CardHeader title={<h4>{item?.title}</h4>} />
          <CardMedia>
            <span>
              <img src={item?.icon} alt="" />
            </span>
          </CardMedia>
          <CardContent>
            <h4>{item?.value}</h4>
          </CardContent>
        </Link>
      </Card>
    </>
  );
};
