/***
 * Register Form Component
 */
import { Flag, House, Map } from "@mui/icons-material";
import {
  Box,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownArrowIcon } from "../../../../Assets/Svgs/reshot-icon-arrow-chevron-down-EUCMLYADT9.svg";
import { PrimaryButton } from "../../../../Components/Button";
import { InputGroup } from "../../../../Components/Global/Reusable";
import { TABLES } from "../../../../enums/tables";
import {
  CreateTrendsPrices,
  DeleteTrendsPricesRow
} from "../../../../features/TrendsPricesSlice";
import useHighlightModal from "../../../../Hooks/useHighlightModal";
import { getFirebaseDataByCreationDate } from "../../../../Services/firebase";
import {
  createHightlightPrice,
  removeHighlightPrice,
  updateHighlightPrices,
} from "../../../../Services/highlights-prices";
import * as S from "../styled";

export default function Index({ itemToEdit, closeRegister, onToastOpen }) {

  const dispatch = useDispatch();

  const [progress, setProgress] = useState(false);
  const [data, setData] = useState([]);
  const highlightModal = useHighlightModal();
  const navigate = useNavigate();
  const [trendPlace, setTrendPlace] = useState(() => {
    if (itemToEdit && itemToEdit.highlight_screens) {
      return itemToEdit?.highlight_screens;
    }
    return [];
  });
  const [type, setType] = useState(() => {
    if (itemToEdit && itemToEdit.collection) {
      return itemToEdit.collection;
    }
    return "";
  });

  const [daysPrices, setDaysPrices] = useState(() => {
    if (itemToEdit && itemToEdit?.prices_per_day) {
      let values = {};

      for (
        let i = 0;
        i < Object.values(itemToEdit?.prices_per_day).length;
        i++
      ) {
        values = Object.keys(itemToEdit?.prices_per_day).reduce(
          (acc, label) => {
            const priceData = itemToEdit?.prices_per_day[label].currency;

            acc[label] = {
              currency: Object.keys(priceData).reduce(
                (currencyAcc, currency) => {
                  currencyAcc[currency] = {
                    price_minor_units:
                      priceData[currency]?.price_minor_units / 100 || 0,
                  };
                  return currencyAcc;
                },
                {}
              ),
            };

            return acc;
          },
          {}
        );
      }

      return values;
    }
    return {
      5: {
        prices: {
          BRL: { price_minor_units: 0 },
          USD: { price_minor_units: 0 },
          EUR: { price_minor_units: 0 },
        },
      },
      10: {
        prices: {
          BRL: { price_minor_units: 0 },
          USD: { price_minor_units: 0 },
          EUR: { price_minor_units: 0 },
        },
      },
      15: {
        prices: {
          BRL: { price_minor_units: 0 },
          USD: { price_minor_units: 0 },
          EUR: { price_minor_units: 0 },
        },
      },
      20: {
        prices: {
          BRL: { price_minor_units: 0 },
          USD: { price_minor_units: 0 },
          EUR: { price_minor_units: 0 },
        },
      },
      25: {
        prices: {
          BRL: { price_minor_units: 0 },
          USD: { price_minor_units: 0 },
          EUR: { price_minor_units: 0 },
        },
      },
      30: {
        prices: {
          BRL: { price_minor_units: 0 },
          USD: { price_minor_units: 0 },
          EUR: { price_minor_units: 0 },
        },
      },
    };
  });
  const [isConnectwatch, setIsConnectwatch] = useState(false);
  const categoryType = [
    { id: 1, name: "ConnectGuides", icon: <Flag />, value: "connectguides" },
    { id: 1, name: "ConnectSales", icon: <Flag />, value: "connectsales" },
    { id: 2, name: "ConnectStores", icon: <House />, value: "connectstores" },
    { id: 3, name: "ConnectWatches", icon: <House />, value: "connectwatches" },
    { id: 4, name: "ConnectRoutes", icon: <Map />, value: "connectroutes" },
  ];

  const pricesFilled =
    typeof daysPrices === "object" &&
    daysPrices !== null &&
    Object.values(daysPrices).every((dayPrice) => {
      if (dayPrice && dayPrice.currency) {
        return Object.values(dayPrice.currency).every((price) => {
          return (
            price?.price_minor_units !== null &&
            price?.price_minor_units !== "" &&
            price?.price_minor_units !== undefined
          );
        });
      }
      return false; // Objeto dayPrice ou propriedade prices não está definido
    });
  const validForm = trendPlace && type && pricesFilled;

  useEffect(() => {
    getHighlightsPlans();
  }, []);

  async function getHighlightsPlans() {
    const response = await getFirebaseDataByCreationDate(TABLES.PLANS);
    const highlightsPlans = response.filter(
      (item) => item.nature === "highlight"
    );
    setData(highlightsPlans);
  }

  function handleChangeTrendPlace(e) {
    const value = e.target.value;
    setTrendPlace(() => {
      switch (value) {
        case "home":
          return ["home"];
        case "explore":
          return ["explore"];
        case "both":
          return ["explore", "home"];
        default:
          return [];
      }
    });
  }

  function handleValuesPerDaysChange(pricesValues, id) {
    const day = (id + 1) * 5;

    const uptedPrices = {
      ...daysPrices,
      [day]: {
        currency: {
          ...daysPrices?.[day]?.currency,
          ...pricesValues.currency,
        },
      },
    };

    setDaysPrices(uptedPrices);
  }

  useEffect(() => {
    if (highlightModal.replaceConfirmation) {
      handleSubmit();
    }
  }, [highlightModal.replaceConfirmation]);

  const handleSubmit = async (self) => {
    self?.preventDefault();
    highlightModal.onClose();

    if (!type || !trendPlace) {
      return;
    }

    const arraysHaveSameItems = (arr1, arr2) => {
      return (
        arr1.every((item) => arr2.includes(item)) &&
        arr2.every((item) => arr1.includes(item))
      );
    };

    const existingData = data.find(
      (item) =>
        item.highlight_screens.length === trendPlace.length &&
        item.collection === type &&
        arraysHaveSameItems(item.highlight_screens, trendPlace)
    );

    if (
      (!itemToEdit ||
        (itemToEdit &&
          itemToEdit.id !== (existingData && existingData?.id) &&
          (!arraysHaveSameItems(itemToEdit.highlight_screens, trendPlace) ||
            itemToEdit.collection !== type))) &&
      existingData &&
      !highlightModal.replaceConfirmation
    ) {
      return highlightModal.onOpen();
    }

    if (itemToEdit) {
      try {
        setProgress(true);

        // Atualiza o item
        const updatedHighlight = await updateHighlightPrices({
          ...itemToEdit,
          id: itemToEdit.id,
          collection: type,
          highlight_screens: trendPlace,
          prices_per_day: daysPrices,
        });

        navigate(0);

        if (existingData) {
          await removeHighlightPrice(existingData);
          dispatch(DeleteTrendsPricesRow(existingData));
        }

        dispatch(CreateTrendsPrices(updatedHighlight));
        onToastOpen("Success", "success");
        closeRegister();
        highlightModal.onReset();
        highlightModal.onClose();
        closeRegister();
      } catch (error) {
        console.error(error);
        onToastOpen("Error", "error");
      } finally {
        setProgress(false);
        highlightModal.onReset();
        highlightModal.onClose();
        closeRegister();
      }
      return;
    }

    try {
      setProgress(true);

      const newHighlightPrice = await createHightlightPrice({
        collection: type,
        highlight_screens: trendPlace,
        prices_per_day: daysPrices,
      });

      if (existingData) {
        await removeHighlightPrice(existingData);
        dispatch(DeleteTrendsPricesRow(existingData));
      }

      dispatch(CreateTrendsPrices(newHighlightPrice));
      onToastOpen("Success", "success");
      highlightModal.onReset();
      highlightModal.onClose();
      closeRegister();
    } catch (error) {
      console.error(error);
      onToastOpen("Error", "error");
    } finally {
      setProgress(false);
      highlightModal.onReset();
      highlightModal.onClose();
      closeRegister();
    }
    highlightModal.onReset();
    highlightModal.onClose();
    closeRegister();
  };

  useEffect(() => {
    if (type && type === "connectwatch") {
      setIsConnectwatch(true);
      setTrendPlace(["home"]);
    }
  }, [type]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <label htmlFor="name">Tipo</label>
          <Select
            sx={{ fontSize: ".9rem" }}
            variant="standard"
            value={type}
            onChange={(self) => {
              setType(self.target.value);
              setIsConnectwatch(self.target.value === "connectwatch");
            }}
            displayEmpty
            renderValue={() => {
              if (!type) {
                return <em>Selecione o tipo de usuário</em>;
              }

              return categoryType.find((item) => item?.value === type)?.name;
            }}
          >
            <MenuItem disabled value="">
              <em>Selecione o tipo de usuário</em>
            </MenuItem>
            {categoryType.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                <S.TypeListItem>
                  {item.icon}
                  <span>{item.name}</span>
                </S.TypeListItem>
              </MenuItem>
            ))}
          </Select>
        </InputGroup>

        <RadioForm
          handleChangeTrendPlace={handleChangeTrendPlace}
          trendPlace={trendPlace}
          isConnectwatch={isConnectwatch}
        />
        <MultiInputs
          daysPrices={daysPrices}
          handleValuesPerDaysChange={handleValuesPerDaysChange}
        />

        <PrimaryButton type="submit" disabled={progress || !validForm}>
          {itemToEdit ? "Salvar" : "Cadastrar"}
        </PrimaryButton>
        {progress && <LinearProgress />}
      </form>
    </>
  );
}

function RadioForm({ handleChangeTrendPlace, trendPlace, isConnectwatch }) {
  return (
    <>
      <Box>
        <Grid container direction="column">
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              style={{ color: "#006875" }}
              name="trendPlace"
              value="home"
              checked={
                trendPlace.includes("home") && !trendPlace.includes("explore")
              }
              onChange={handleChangeTrendPlace}
            />
            <InputLabel sx={{ fontSize: ".9rem" }}>Tela Principal</InputLabel>
          </Grid>

          {!isConnectwatch && (
            <>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  style={{ color: "#006875" }}
                  name="trendPlace"
                  value="explore"
                  checked={
                    !trendPlace.includes("home") &&
                    trendPlace.includes("explore")
                  }
                  onChange={handleChangeTrendPlace}
                />
                <InputLabel sx={{ fontSize: ".9rem" }}>
                  Tela de Categoria
                </InputLabel>
              </Grid>

              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  style={{ color: "#006875" }}
                  name="trendPlace"
                  value="both"
                  checked={
                    trendPlace.includes("home") &&
                    trendPlace.includes("explore")
                  }
                  onChange={handleChangeTrendPlace}
                />
                <InputLabel sx={{ fontSize: ".9rem" }}>
                  Tela Principal + Categoria
                </InputLabel>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}

function MultiInputs({ handleValuesPerDaysChange, daysPrices }) {
  const [showOptions, setShowOptions] = useState([]);

  function handleShowOptions(index) {
    const array = [];

    if (showOptions[index] === array[index]) {
      array[index] = true;
      setShowOptions(array);
    } else {
      array[index] = false;

      setShowOptions(!array);
    }
  }
  return (
    <Box sx={{ marginTop: "5px" }}>
      <Grid container spacing={2}>
        {Object.values(daysPrices).map((item, index) => {
          return (
            <Grid item key={index}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <InputGroup>
                    <label>Dias</label>
                    <input
                      type="text"
                      name="days"
                      value={(index + 1) * 5}
                      readOnly
                    />
                  </InputGroup>
                </Grid>

                <Grid item xs={6}>
                  <InputGroup>
                    <label>Valor</label>
                    <ul>
                      <>
                        <S.Option>
                          Valores
                          <DownArrowIcon
                            onClick={() => handleShowOptions(index)}
                            style={{ marginLeft: "4rem", cursor: "pointer" }}
                          />
                        </S.Option>
                      </>

                      {showOptions[index] && (
                        <>
                          <S.Gap />
                          <S.Option id="R$">
                            R$
                            <input
                              type="text"
                              name="real"
                              autoComplete="off"
                              onChange={(e) =>
                                handleValuesPerDaysChange(
                                  {
                                    ...item,
                                    currency: {
                                      BRL: {
                                        ...item?.currency?.BRL,
                                        price_minor_units: e?.target?.value,
                                      },
                                    },
                                  },
                                  index
                                )
                              }
                              value={item?.currency?.BRL?.price_minor_units}
                            />
                          </S.Option>
                          <S.Gap />
                          <S.Option id="$">
                            $
                            <input
                              type="text"
                              name="dolar"
                              autoComplete="off"
                              onChange={(e) =>
                                handleValuesPerDaysChange(
                                  {
                                    ...item,
                                    currency: {
                                      USD: {
                                        ...item?.currency?.USD,
                                        price_minor_units: e?.target?.value,
                                      },
                                    },
                                  },
                                  index
                                )
                              }
                              value={item?.currency?.USD?.price_minor_units}
                            />
                          </S.Option>
                          <S.Gap />
                          <S.Option id="€">
                            €
                            <input
                              type="text"
                              name="euro"
                              autoComplete="off"
                              onChange={(e) =>
                                handleValuesPerDaysChange(
                                  {
                                    ...item,
                                    currency: {
                                      EUR: {
                                        ...item?.currency?.EUR,
                                        price_minor_units: e?.target?.value,
                                      },
                                    },
                                  },
                                  index
                                )
                              }
                              value={item?.currency?.EUR?.price_minor_units}
                            />
                          </S.Option>
                        </>
                      )}
                    </ul>
                  </InputGroup>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
