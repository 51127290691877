export const dateFormatter = new Intl.DateTimeFormat("pt-BR");
export function dateInputFormatter(date) {
  const formattedDate = new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
    .format(new Date(date))
    .replaceAll("/", "-");
  return formattedDate;
}

export const priceFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function EventPriceFormatter(currency) {
  return new Intl.NumberFormat(currency, {
    style: "currency",
    currency,
  });
}
