/****
 * In this component we Declare Labels for the table
 * So I decided to separate these components to make our code more reusable
 */
import React, { useState } from "react";
import { StyledTableCell } from "./styled";
import { ArrowDown, ArrowUp } from "phosphor-react";
import DateFilter from "../../../Components/DateFilter/DateFilter";

//At this point we declare Labels for the finance page
const Labels = [
  { id: "code", label: "Codigo", minWidth: 170 },
  { id: "name", label: "Nome", minWidth: 100 },
  {
    id: "date",
    label: "Data",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "itineraryCount",
    label: "ConnectRoutes",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "category",
    label: "Categoria",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "country",
    label: "Pais",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "city",
    label: "Cidade",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "details",
    label: "Detalhes",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "block",
    label: "Bloquear",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function Index({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalItineraryFilter,
  setModalItineraryFilter,
  modalCategoryFilter,
  setModalCategoryFilter,
  modalCountryFilter,
  setModalCountryFilter,
  modalCityFilter,
  setModalCityFilter,
}) {
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const filtersArray = [
    modalNameFilter,
    modalItineraryFilter,
    modalCategoryFilter,
    modalCountryFilter,
    modalCityFilter,
  ];

  function handleOpenNameFilter(id) {
    setModalNameFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenItineraryFilter(id) {
    setModalItineraryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCategoryFilter(id) {
    setModalCategoryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCountryFilter(id) {
    setModalCountryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCityFilter(id) {
    setModalCityFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleClick(id) {
    if (id === "date") return openDateFilter(id);
    if (id === "name") return handleOpenNameFilter(id);
    if (id === "itineraryCount") return handleOpenItineraryFilter(id);
    if (id === "category") return handleOpenCategoryFilter(id);
    if (id === "country") return handleOpenCountryFilter(id);
    if (id === "city") return handleOpenCityFilter(id);
    return handleChangeOrder(id);
  }

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }

  return (
    <>
      {Labels.map((column) => {
        let isFiltered = filtersArray.some(
          (item) => item.type === column.id && item.isUsing
        );
        return (
          <StyledTableCell
            onClick={() => handleClick(column.id)}
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "details" &&
                column.id !== "block" &&
                column.id !== "code" && (
                  <div>
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "desc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "asc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
