import styled from "styled-components";
import { Card as CardMUI } from "@mui/material";

export const Home = styled.div`
  h3 {
    font-size: 1.3rem;
  }

  padding-top: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
  margin-block: 3rem;
`;

export const Card = styled(CardMUI)`
  display: grid;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.3s linear;
  cursor: pointer;
  width: 100%;
  max-width: 190px;
  min-height: 100%;
  max-height: 246px;
  border-radius: 12px;
  justify-content: space-around;
  align-items: center;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  h4,
  span,
  img {
    transition: all 0.3s linear;
  }

  h4 {
    white-space: pre-line;
    text-align: center;
  }

  //on hover
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};

    h4 {
      color: white;
    }

    span {
      color: white;
    }

    img {
      filter: brightness(0) invert(100%);
    }
  }
  //header
  h4 {
    font-size: 1rem;
    color: black;
    font-weight: lighter;
  }

  //span
  span {
    color: ${({ theme }) => theme.colors.primary};
    svg {
      font-size: 4rem;
      color: inherit;
    }
  }
`;
