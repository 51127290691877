import React, { useEffect, useState, useRef } from "react";
import StyledTable from "../../../Components/Table/index";
import ConnectpointData from "./bodyData";
import { ConnectpointLabel } from "./Labels";
import { ShowTotal } from "../../../Components/Global/Reusable";
import { AppContainer } from "../../../Components/Global/Reusable";
import { useDispatch, useSelector } from "react-redux";
import { setCategoryState } from "../../../features/MenuSlice";
import { selectAll } from "../../../features/ConnectpointSlice";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";

export default function Index() {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [secondaryFilter, setSecondaryFilter] = useState("");
  const [orderFilter, setOrderFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);

  const timerRef = useRef(0);
  const items = useSelector(selectAll);
  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();
  const [modalCategoryFilter, setModalCategoryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalTypeFilter, setModalTypeFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCountryFilter, setModalCountryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCityFilter, setModalCityFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalStatusFilter, setModalStatusFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [currencyFilter, setCurrencyFilter] = useState({
    isOpen: false,
    value: "all",
  });

  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);
  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="Relatório ConnectPoints"
          Component={<ShowTotal total={items.length} />}
          Labels={
            <ConnectpointLabel
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              modalCategoryFilter={modalCategoryFilter}
              setModalCategoryFilter={setModalCategoryFilter}
              modalTypeFilter={modalTypeFilter}
              setModalTypeFilter={setModalTypeFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
              modalStatusFilter={modalStatusFilter}
              setModalStatusFilter={setModalStatusFilter}
              currencyFilter={currencyFilter}
              setCurrencyFilter={setCurrencyFilter}
            />
          }
          TableData={
            <ConnectpointData
              onToastOpen={onToastOpen}
              orderFilter={orderFilter}
              dateFilter={dateFilter}
              secondaryFilter={secondaryFilter}
              modalCategoryFilter={modalCategoryFilter}
              setModalCategoryFilter={setModalCategoryFilter}
              modalTypeFilter={modalTypeFilter}
              setModalTypeFilter={setModalTypeFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
              modalStatusFilter={modalStatusFilter}
              setModalStatusFilter={setModalStatusFilter}
              currencyFilter={currencyFilter}
              setCurrencyFilter={setCurrencyFilter}
            />
          }
        />
      </AppContainer>
    </>
  );
}
