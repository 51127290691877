import styled, { keyframes } from "styled-components";

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.span`
  display: block;
  width: 19px;
  height: 19px;
  border: 6px solid #f5f5f5;
  border-top-color: #03738c;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;
