/***
 *
 * In this Component we Display details for TuristicPointReport
 */

import { ArrowBack } from "@mui/icons-material";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import aventure2 from "../../../Assets/Images/aventure03.webp";
import aventure4 from "../../../Assets/Images/place05.webp";
import {
  ActionButtons,
  ComplexAddress,
  Description,
  ImagesAlbum,
  OverlayBody,
  OverlayChild,
  OverlayContainer,
  OverlayHeader,
  StyledProfile,
  Title,
} from "../../../Components/Global/Reusable";
import {
  UpdateRow,
  selectAll,
  DeleteRow,
} from "../../../features/ApprovedSlice";
import * as user from "../../../Services/user";
import * as guide from "../../../Services/touristGuide";
import * as spot from "../../../Services/touristSpot";
import * as stablishment from "../../../Services/stablishment";
import * as approval from "../../../Services/approval";

/***
 * Confirm Modal
 */

const Collection = Object.freeze({
  user: user,
  "tourist-guide": guide,
  "tourist-spot": spot,
  stablishment: stablishment,
});

export default function Index({ CloseDetails, detailsID, openPreview }) {
  const [message, setMessage] = useState(null);
  const ApprovedData = useSelector(selectAll);
  const dispatch = useDispatch();

  async function Active({ id, type }) {
    await Collection[type].approve(id);
    await approval.remove(id);
    dispatch(DeleteRow({ id }));
    setMessage("Aprovado");
    setTimeout(() => CloseDetails(), 1500);
  }

  async function Block({ id, type }) {
    await Collection[type].reprove(id);
    await approval.remove(id);
    dispatch(DeleteRow({ id }));
    setMessage("Reprovado");
    setTimeout(() => CloseDetails(), 1500);
  }

  return (
    <OverlayContainer>
      <OverlayChild style={{ width: "400px" }}>
        <OverlayHeader>
          <ArrowBack onClick={() => CloseDetails()} />
          <Title>Detalhes</Title>
        </OverlayHeader>
        {ApprovedData.filter((item) => item.id === detailsID).map(
          (item, index) => (
            <OverlayBody
              style={{ gap: "15px", paddingTop: "15px" }}
              key={index}
            >
              <Profile name={item.name} />
              {item.description && <Description desc={item.description} />}
              {item.address && <ComplexAddress address={item.address} />}

              {item.images.length >= 1 && (
                <ImagesAlbum photos={item.images} openPreview={openPreview} />
              )}

              {message && <Alert severity="success">{message}</Alert>}
              <ActionButtons
                id={item.id}
                type={item.type}
                approve={Active}
                reprove={Block}
                activeTitle="Aprovar"
                blockTitle="Reprovar"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              />
            </OverlayBody>
          )
        )}
      </OverlayChild>
    </OverlayContainer>
  );
}

const Profile = ({ name }) => {
  return (
    <StyledProfile>
      <div style={{ display: "grid", gap: "5px", alignContent: "center" }}>
        <Title style={{ margin: "5px 0 0 0", fontSize: "1.3rem" }}>
          {name}
        </Title>
      </div>
    </StyledProfile>
  );
};
