import React, { useState } from "react";
import { StyledTableCell } from "./styled";
import { ArrowDown, ArrowUp } from "phosphor-react";
import DateFilter from "../../../Components/DateFilter/DateFilter";

/**
 * In this component we define labels for Agenda Table
 *
 */

export default function AgendaLabels({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalUsernameFilter,
  setModalUsernameFilter,
  modalPlaceFilter,
  setModalPlaceFilter,
}) {
  const columns = [
    { id: "name", label: "Nome", align: "left" },
    { id: "date", label: "Data", align: "left" },
    { id: "start", label: "Inicio", align: "left" },
    { id: "place", label: "Local", align: "left" },
    { id: "username", label: "Usuário", align: "left" },
    { id: "entrance", label: "Entrada", align: "left" },
    { id: "edit", label: "Editar", align: "right" },
    { id: "delete", label: "Excluir", align: "right" },
  ];

  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const filtersArray = [modalNameFilter, modalUsernameFilter, modalPlaceFilter];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
    setOrderFilter({ id: "date", order: "asc" });
  }

  function handleOpenNameFilter(id) {
    setModalNameFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenUsernameFilter(id) {
    setModalUsernameFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenPlaceFilter(id) {
    setModalPlaceFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleClick(id) {
    if (id === "date") return openDateFilter(id);
    if (id === "name") return handleOpenNameFilter(id);
    if (id === "username") return handleOpenUsernameFilter(id);
    if (id === "place") return handleOpenPlaceFilter(id);
    return handleChangeOrder(id);
  }

  return (
    <>
      {columns.map((column) => {
        let isFiltered = filtersArray.some(
          (item) => item.type === column.id && item.isUsing
        );
        return (
          <StyledTableCell
            onClick={() => handleClick(column.id)}
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "edit" &&
                column.id !== "delete" &&
                column.id !== "id" &&
                column.id !== "category" && (
                  <div>
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "desc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "asc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
