/****
 * In this component we declare all functionlities for Agenda Page
 * We retrieve data from our server
 */

import { AppContainer } from "../../../Components/Global/Reusable";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setAgendaState } from "../../../features/MenuSlice";
import { RegisterButton } from "../Category/styled";
import StyledTable from "../../../Components/Table";
import AgendaBodyData from "./bodyData";
import AgendaLabels from "./Labels";
import Register from "./Register";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";
import { QueryByString } from "../../../Components/LocalSearch";

export default function Index() {
  const [actualEditId, setActualEditId] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [orderFilter, setOrderFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [modalNameFilter, setModalNameFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalUsernameFilter, setModalUsernameFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalPlaceFilter, setModalPlaceFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });

  const timerRef = useRef(0);
  const dispatch = useDispatch();

  function editItem(id) {
    setActualEditId(id);
    dispatch(setAgendaState(true));
  }

  function closeRegister() {
    dispatch(setAgendaState(false));
    setActualEditId("");
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);

  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="Cadastro de Agenda de Eventos"
          Component={
            <RegisterBtn onClick={() => dispatch(setAgendaState(true))} />
          }
          Labels={
            <AgendaLabels
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalUsernameFilter={modalUsernameFilter}
              setModalUsernameFilter={setModalUsernameFilter}
              modalPlaceFilter={modalPlaceFilter}
              setModalPlaceFilter={setModalPlaceFilter}
            />
          }
          TableData={
            <AgendaBodyData
              onToastOpen={onToastOpen}
              editItem={editItem}
              dateFilter={dateFilter}
              orderFilter={orderFilter}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalUsernameFilter={modalUsernameFilter}
              setModalUsernameFilter={setModalUsernameFilter}
              modalPlaceFilter={modalPlaceFilter}
              setModalPlaceFilter={setModalPlaceFilter}
            />
          }
        />
        <Register
          onToastOpen={onToastOpen}
          editId={actualEditId}
          closeRegister={closeRegister}
        />
      </AppContainer>
    </>
  );
}

/***
 * ==================CADASTRE_SE BUTTON===============
 *
 */

function RegisterBtn({ ...rest }) {
  return (
    <>
      <RegisterButton {...rest}>Cadastrar Novo</RegisterButton>
    </>
  );
}
