import { doc, getDoc, updateDoc } from 'firebase/firestore'
import React, { useState, createContext, useEffect } from 'react'
import { auth, db } from '../firebase'

export const PlansTrendsContext = createContext({})

export function PlansTrendsProvider({ children }) {
  const [showHighlights, setShowHighlights] = useState(false);

  function showHighlightsPrices() {
    setShowHighlights(state => !state)
  }
  const value = {
    showHighlights,
    showHighlightsPrices

  }

  return (
    <PlansTrendsContext.Provider value={value}>
      {children}
    </PlansTrendsContext.Provider>
  )
}
