import { createSlice } from "@reduxjs/toolkit";

const ApprovedSlice = createSlice({
  name: "approved",
  initialState: {
    value: [],
  },
  reducers: {
    UpdateValue(state, { payload }) {
      state.value = payload;
    },
    UpdateRow(state, { payload }) {
      state.value.map((item) => {
        return () => {
          if (item.id === payload.id) {
            if (payload.name !== null) {
              item.name = payload.name;
            } else if (payload.type !== null) {
              item.type = payload.type;
            }
          }
        };
      });
    },
    DeleteRow(state, { payload }) {
      state.value = state.value.filter((item) => item.id !== payload.id);
    },
    Createapproved(state, { payload }) {
      state.value.unshift(payload);
    },
  },
});

export const selectAll = (state) => state.approved.value;
export const { DeleteRow, UpdateRow, Createapproved, UpdateValue } =
  ApprovedSlice.actions;
export default ApprovedSlice.reducer;
