import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Home from "./Pages/Admin/Home";
import ErrorPage from "./Pages/ErrorPage";

import UserLogin from "./Pages/User/Login";
import UserPage from "./Pages/User";
import TermsOfUse from "./Pages/Admin/TermsOfUse";

import Login from "./Pages/Admin/Login";
import Agenda from "./Pages/Admin/Agenda";
import Approved from "./Pages/Admin/Approved";
import Category from "./Pages/Admin/Category";
import Finance from "./Pages/Admin/Finance";
import Explore from "./Pages/Admin/Explore";
import Denunciation from "./Pages/Admin/Denunciation";
import UsersReport from "./Pages/Admin/UsersReport";
import GuideReport from "./Pages/Admin/GuideReport";
import ConnectsalesReport from "./Pages/Admin/ConnectsalesReport";
import ShopsReport from "./Pages/Admin/ShopsReport";
import PlansReport from "./Pages/Admin/PlansReport";
import ItineraryReport from "./Pages/Admin/ItineraryReport";
import ConnectpointsReport from "./Pages/Admin/ConnectpointsReport";
import TuristicPointsReport from "./Pages/Admin/TuristicPointsReport";
import SignatureReport from "./Pages/Admin/SignatureReport";
import HighlightReport from "./Pages/Admin/HighlightReport";
import { DefaultLayout } from "./Layouts/DefaultLayout";

export function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Login />} />

        <Route path="/admin" element={<DefaultLayout />}>
          <Route index element={<Home />} />
          <Route path="relatorio_de_usuario" element={<UsersReport />} />
          <Route
            path="relatorio_de_guias_turisticas"
            element={<GuideReport />}
          />
          <Route
            path="relatorio_de_connectsales"
            element={<ConnectsalesReport />}
          />
          <Route
            path="relatorio_de_estabelecimentos"
            element={<ShopsReport />}
          />
          <Route
            path="relatorio_de_connectpoints"
            element={<ConnectpointsReport />}
          />
          <Route path="relatorio_de_roteiros" element={<ItineraryReport />} />
          <Route path="relatorio_financeiro" element={<Finance />} />
          <Route path="planos_de_assinatura" element={<PlansReport />} />
          <Route
            path="relatorio_de_pontos_turisticos"
            element={<TuristicPointsReport />}
          />
          <Route path="termos_de_uso" element={<TermsOfUse />} />
          <Route path="denuncias" element={<Denunciation />} />
          <Route path="category" element={<Category />} />
          <Route path="explorar" element={<Explore />} />
          <Route path="agenda" element={<Agenda />} />

          <Route
            path="relatorio_de_assinaturas"
            element={<SignatureReport />}
          />
          <Route path="relatorio_de_highlights" element={<HighlightReport />} />
          {/* <Route path="approved" element={<Approved />} /> */}
        </Route>

        <Route path="/user">
          <Route index element={<UserPage />} />
          <Route path="login" element={<UserLogin />} />
        </Route>

        {/* Not Found 404 Page */}
        <Route path="*" element={<ErrorPage />} />
      </>
    )
  );
  return <RouterProvider router={router} />;
}
