import { DeleteOutlined, Edit } from "@mui/icons-material";
import { TableCell, TableRow } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteRow,
  selectAll,
  UpdateRow,
  UpdateValue,
} from "../../../features/AgendaSlice";
import * as S from "./styled";
import * as agenda from "../../../Services/agenda";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { LoadingSpinner } from "../../../Components/Loader";
import { PrimaryButton } from "../../../Components/Button";
import { Modal } from "../../../Components/Modal";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import dayjs from "dayjs";
import { TABLES } from "../../../enums/tables";

export default function AgendaBodyData({
  editItem,
  onToastOpen,
  orderFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalUsernameFilter,
  setModalUsernameFilter,
  modalPlaceFilter,
  setModalPlaceFilter,
}) {
  const query = useSelector((state) => state.query.query_string);
  const queryNum = useSelector((state) => state.query.queryByNumber);
  const agendaData = useSelector(selectAll);
  const dispatch = useDispatch();
  const [formattedAgenda, setFormattedAgenda] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalUsernameFilterSearch, setModalUsernameFilterSearch] = useState(
    []
  );
  const [modalPlaceFilterSearch, setModalPlaceFilterSearch] = useState([]);

  async function getDataFromFirebase(collection) {
    const data = await getFirebaseDataByCreationDate(collection);
    dispatch(UpdateValue(data));
  }

  useEffect(() => {
    try {
      getDataFromFirebase(TABLES.EVENTS);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (agendaData) {
      setFormattedAgenda(
        agendaData.map((agenda) => {
          let city = agenda?.city;
          let region = agenda?.region;
          let country = agenda?.country;

          const address = [];

          if (country) address.push(country);
          if (region) address.push(region);
          if (city) address.push(city);

          return {
            ...agenda,
            entrance: agenda.entrance || "--",
            place: address.join(", "),
            eventDate: dayjs(agenda?.date),
          };
        })
      );
    }
  }, [agendaData]);

  useEffect(() => {
    if (formattedAgenda) {
      const toDate = dayjs(dateFilter?.to).endOf("day");
      const fromDate = dayjs(dateFilter?.from).startOf("day");

      const filtered = formattedAgenda
        .filter((row) => {
          let filterNameMatch = true;
          let filterUsernameMatch = true;
          let filterPlaceMatch = true;

          if (modalNameFilterSearch.length > 0) {
            const matchingItems = modalNameFilterSearch.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.name?.toLowerCase()?.trim()
            );
            filterNameMatch = matchingItems.length > 0;
          }
          if (modalUsernameFilterSearch.length > 0) {
            const matchingItems = modalUsernameFilterSearch.filter(
              (filterItem) =>
                filterItem.username.toLowerCase().trim() ===
                row.owner?.name?.toLowerCase().trim()
            );
            filterUsernameMatch = matchingItems.length > 0;
          }
          if (modalPlaceFilterSearch.length > 0) {
            const matchingItems = modalPlaceFilterSearch.filter(
              (filterItem) =>
                filterItem.place.toLowerCase().trim() ===
                row?.place.toLowerCase().trim()
            );
            filterPlaceMatch = matchingItems.length > 0;
          }

          const nameMatch = row?.name?.toLowerCase().includes(query);

          const startTimeMatch = row?.startTime?.toLowerCase().includes(query);

          const entranceMatch = String(row?.entrance_fee_minor_units).includes(
            query
          );

          const cityMatch = row?.city?.toLowerCase().includes(query);

          const countryMatch = row?.country?.toLowerCase().includes(query);

          const regionMatch = row?.region?.toLowerCase().includes(query);

          const userMatch = row?.owner?.name?.toLowerCase().includes(query);

          return (
            (nameMatch ||
              startTimeMatch ||
              entranceMatch ||
              regionMatch ||
              userMatch ||
              cityMatch ||
              countryMatch) &&
            filterNameMatch &&
            filterUsernameMatch &&
            filterPlaceMatch &&
            (dateFilter
              ? dayjs(row.start_date)
                  .startOf("day")
                  .isAfter(fromDate.subtract(1, "day").endOf("day")) &&
                dayjs(row.end_date)
                  .startOf("day")
                  .isBefore(toDate.add(1, "day"))
              : true)
          );
        })
        .sort((a, b) => {
          if (orderFilter && orderFilter.id === "name") {
            return orderFilter.order === "asc"
              ? b?.name?.localeCompare(a?.name)
              : a?.name?.localeCompare(b?.name);
          }
          if (orderFilter && orderFilter.id === "start") {
            const getTime = (start_time_in_minutes) => {
              const hours = Math.floor(start_time_in_minutes / 60);
              const minutes = start_time_in_minutes % 60;
              return hours + minutes / 60;
            };

            const aTime = getTime(a.start_time_in_minutes);
            const bTime = getTime(b.start_time_in_minutes);

            return orderFilter.order === "asc" ? aTime - bTime : bTime - aTime;
          }
          if (orderFilter && orderFilter.id === "entrance") {
            return orderFilter.order === "asc"
              ? b?.entrance_fee_minor_units / 100 -
                  a?.entrance_fee_minor_units / 100
              : a?.entrance_fee_minor_units / 100 -
                  b?.entrance_fee_minor_units / 100;
          }
          if (orderFilter && orderFilter.id === "local") {
            return orderFilter.order === "asc"
              ? b?.city?.localeCompare(a?.city)
              : a?.city?.localeCompare(b?.city);
          }
          if (orderFilter && orderFilter.id === "date") {
            return orderFilter.order === "asc"
              ? b?.created_at?.localeCompare(a?.created_at)
              : a?.created_at?.localeCompare(b?.created_at);
          }
          if (orderFilter && orderFilter.id === "user") {
            return orderFilter.order === "asc"
              ? b?.owner?.name?.localeCompare(a?.owner?.name)
              : a?.owner?.name?.localeCompare(b?.owner?.name);
          }

          return 0;
        });

      setFilteredData(filtered);
    }
  }, [
    query,
    orderFilter,
    dateFilter,
    formattedAgenda,
    modalNameFilterSearch,
    modalUsernameFilterSearch,
    modalPlaceFilterSearch,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    queryNum,
    dateFilter,
    query,
    formattedAgenda,
    modalNameFilterSearch,
    modalUsernameFilterSearch,
    modalPlaceFilterSearch,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (filteredData.length > 0) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return (
      <>
        {paginatedData.map((row, index) => {
          return (
            <React.Fragment key={index}>
              <ReadOnlyRow
                onToastOpen={onToastOpen}
                data={row}
                setEditId={editItem}
              />
            </React.Fragment>
          );
        })}
        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredData.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage.selected);
            setCurrentPage(selectedPage.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {modalNameFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(formattedAgenda.map((item) => item?.name?.trim())),
            ].map((item) => ({
              name: item,
            }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalUsernameFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalUsernameFilterSearch}
            setModalFilterSearch={setModalUsernameFilterSearch}
            setModalFilter={setModalUsernameFilter}
            data={[
              ...new Set(
                formattedAgenda.map((item) => item?.owner?.name?.trim())
              ),
            ].map((item) => ({ username: item }))}
            labelPath="username"
            label="nome usuário"
          />
        )}

        {modalPlaceFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalPlaceFilterSearch}
            setModalFilterSearch={setModalPlaceFilterSearch}
            setModalFilter={setModalPlaceFilter}
            data={[
              ...new Set(formattedAgenda.map((item) => item?.place?.trim())),
            ].map((item) => ({ place: item }))}
            labelPath="place"
            label="local"
          />
        )}
      </>
    );
  }
}

export const ReadOnlyRow = ({ data: row, setEditId, onToastOpen }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDeleteAgenda() {
    try {
      setIsDeleting(true);
      await agenda.remove(row);
      dispatch(DeleteRow({ id: row.id }));
      onToastOpen("Success", "success");
    } catch (error) {
      console.error(error);
      onToastOpen("Error", "error");
    } finally {
      setIsDeleting(false);
      setIsOpen(false);
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        onOpenChange={(open) => {
          if (!isDeleting) {
            setIsOpen(open);
          }
        }}
        title="Atenção!"
        description={`Tem certeza que deseja deletar "${row?.name}"?`}
      >
        <S.ModalContainer>
          <PrimaryButton onClick={() => setIsOpen(false)}>
            Cancelar
          </PrimaryButton>

          <PrimaryButton
            onClick={handleDeleteAgenda}
            style={{ marginLeft: "auto" }}
          >
            {isDeleting ? <LoadingSpinner /> : "Confirmar"}
          </PrimaryButton>
        </S.ModalContainer>
      </Modal>

      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell colSpan={0} sx={{ width: "200px" }}>
          {row?.name}
        </TableCell>

        <TableCell>
          {row?.start_date && row?.end_date
            ? `${dayjs(row?.start_date).format("DD/MM/YYYY")}
         - 
         ${dayjs(row?.end_date).format("DD/MM/YYYY")}`
            : dayjs(row?.end_date).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell>
          {String(Math.floor(row.start_time_in_minutes / 60)).padStart(2, "0")}:
          {String(row.start_time_in_minutes % 60).padStart(2, "0")}h
        </TableCell>
        <TableCell sx={{ maxWidth: "450px" }}>{row?.place}</TableCell>
        <TableCell sx={{ maxWidth: "450px" }}>{row?.owner?.name}</TableCell>
        <TableCell>
          {row.ticket_currency}{" "}
          {(row?.entrance_fee_minor_units / 100).toFixed(2)}
        </TableCell>
        <TableCell align="right">
          <Edit
            style={{ color: "#079784", cursor: "pointer" }}
            onClick={() => setEditId(row.id)}
          />
        </TableCell>
        <TableCell align="right">
          <DeleteOutlined
            style={{ color: "#ec0202", cursor: "pointer" }}
            onClick={() => setIsOpen(true)}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

// export const EditableRow = ({ data: row, setEditId }) => {

//   const dispatch = useDispatch();

//   const [name, setName] = useState(row.name);
//   const [date, setDate] = useState(dateInputFormatter(row.date));
//   const [startTime, setStartTime] = useState(row.startTime);
//   const [entrance, setEntrance] = useState(row.entrance);
//   const [local, setLocal] = useState(`${row.city}${row.region && `, ${row.region}`}${row.country && `, ${row.country}`}`);

//   const [city = "", region = "", country = ""] = local.split(',')
//   const [year, month, day] = date.split('-')
//   const formattedDate = new Date(year, month - 1, day).toISOString()
//   const cityFormatted = city.trim()
//   const regionFormmated = region.trim()
//   const countryFormatted = country.trim()

//   const handleUpdate = async (id) => {
//     try {
//       await agenda.update({ ...row, name, date: formattedDate, startTime, entrance, city: cityFormatted, region: regionFormmated, country: countryFormatted })
//       dispatch(UpdateRow({ id, name, date: formattedDate, startTime, entrance, city: cityFormatted, region: regionFormmated, country: countryFormatted }));
//       setEditId(null);
//     } catch (error) {
//       console.error(error.message)
//     }
//   };

//   return (
//     <TableRow key={row.id}>
//       <TableCell>
//         <StyledEditInput
//           type="text"
//           value={name}
//           placeholder="Atualize Nome"
//           onChange={(self) => setName(self.target.value)}
//         />
//       </TableCell>
//       <TableCell>
//         <StyledEditInput
//           type="date"
//           value={date}
//           placeholder="Atualize data"
//           onChange={(self) => setDate(self.target.value)}
//         />
//       </TableCell>
//       <TableCell>
//         <StyledEditInput
//           type="time"
//           value={start_time}
//           placeholder="Atualize a hora do inicio"
//           onChange={(self) => setStartTime(self.target.value)}
//         />
//       </TableCell>
//       <TableCell>
//         <StyledEditInput
//           type="text"
//           value={entrance.replace('.', ',')}
//           placeholder="Atualize a entrada"
//           onChange={(self) => setEntrance(self.target.value)}
//         />
//       </TableCell>
//       <TableCell>
//         <StyledEditInput
//           type="text"
//           value={local}
//           placeholder="Atualize o Local"
//           onChange={(self) => setLocal(self.target.value)}
//         />
//       </TableCell>
//       <TableCell>
//         {row.user}
//       </TableCell>
//       <TableCell align="right">
//         <span
//           style={{ color: "#079784", cursor: "pointer" }}
//           onClick={() => handleUpdate(row.id)}
//         >
//           Atualize
//         </span>
//       </TableCell>
//       <TableCell align="right">
//         <span
//           style={{ color: "#ec0202", cursor: "pointer" }}
//           onClick={() => setEditId(null)}
//         >
//           Cancelar
//         </span>
//       </TableCell>
//     </TableRow>
//   );
// };
