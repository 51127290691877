/***
 * Reusable Component Guides Report body data
 */

import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAll, UpdateValue } from "../../../features/GuideReportSlice";
import {
  selectAll as selectAllItineraries,
  UpdateValue as UpdateValueItineraries,
} from "../../../features/ItineraryReportSlice";
import {
  getFirebaseDataByCreationDate,
  getFirebaseItem,
} from "../../../Services/firebase";
import { dateFormatter } from "../../../utils/formatter";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import dayjs from "dayjs";
import { TABLES } from "../../../enums/tables";
import { showOnlyFirstSixDigits } from "../../../utils/showOnlyFirstSixDigits";

export default function Index({
  OpenModal,
  OpenDetails,
  orderFilter,
  secondaryFilter,
  dateFilter,
  modalNameFilter,
  modalItineraryFilter,
  modalTypeFilter,
  setModalNameFilter,
  setModalItineraryFilter,
  setModalTypeFilter,
}) {
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const guides = useSelector(selectAll);
  const itinerariesData = useSelector(selectAllItineraries);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [formatedData, setFormatedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalItineraryFilterSearch, setModalItineraryFilterSearch] = useState(
    []
  );
  const [modalTypeFilterSearch, setModalTypeFilterSearch] = useState([]);

  async function getDataFromFirebase() {
    const data = await getFirebaseDataByCreationDate(TABLES.CONNECTGUIDES);
    const itineraries = await getFirebaseDataByCreationDate(
      TABLES.CONNECTROUTES
    );

    dispatch(UpdateValue(data));
    dispatch(
      UpdateValueItineraries(
        itineraries?.filter((item) => item?.type === "connectguides")
      )
    );
  }

  useEffect(() => {
    function formatData() {
      setFormatedData(
        data?.map((guide) => {
          let currentPlan;
          if (guide?.plan?.type?.includes("amateur")) {
            currentPlan = "Não credenciado";
          } else if (guide?.plan?.type?.includes("pro")) {
            currentPlan = "Credenciado";
          } else {
            currentPlan = "Sem plano";
          }
          return {
            ...guide,
            currentPlan: currentPlan,
            itinerariesCount: guide?.itineraries?.length,
          };
        })
      );
    }
    formatData();
  }, [data]);

  const getGuidesCodes = async (formattedData) => {
    const promises = formattedData?.map(async (guide) => {
      const user = await getFirebaseItem(TABLES.USERS, guide?.id);
      return {
        ...guide,
        code: user?.code,
      };
    });
    return await Promise?.all(promises);
  };

  const formatGuides = async () => {
    try {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);
      const guides = await getGuidesCodes(formatedData);
      const filtered = guides
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.name?.localeCompare(a?.name)
              : a?.name?.localeCompare(b?.name);
          }
          if (orderFilter && orderFilter?.id === "itineraries") {
            return orderFilter?.order === "asc"
              ? a?.itineraries?.length - b?.itineraries?.length
              : b?.itineraries?.length - a?.itineraries?.length;
          }
          if (orderFilter && orderFilter?.id === "date") {
            return orderFilter?.order === "asc"
              ? b?.createdAt?.localeCompare(a?.createdAt)
              : a?.createdAt?.localeCompare(b?.createdAt);
          }
          if (orderFilter && orderFilter?.id === "type") {
            return orderFilter?.order === "asc"
              ? b?.currentPlan?.localeCompare(a?.currentPlan)
              : a?.currentPlan?.localeCompare(b?.currentPlan);
          }
          return 0;
        })
        ?.filter((row) => {
          let filterNameMatch = true;
          let filterItineraryMatch = true;
          let filterTypeMatch = true;

          const nameMatch =
            row?.name && row?.name?.toLowerCase()?.includes(query);
          const itinerariesCountMatch = String(
            row?.itineraries?.length
          )?.includes(query);
          const codeMatch = String(row?.fiscal_code)?.includes(query);
          const planMatch = row?.currentPlan
            ?.toLowerCase()
            ?.trim()
            ?.includes(query);

          if (modalNameFilterSearch?.length > 0) {
            const matchingItems = modalNameFilterSearch?.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.name?.toLowerCase()?.trim()
            );

            filterNameMatch = matchingItems?.length > 0;
          }

          if (modalItineraryFilterSearch?.length > 0) {
            const matchingItems = modalItineraryFilterSearch?.filter(
              (filterItem) =>
                String(filterItem?.itinerariesCount)?.toLowerCase()?.trim() ===
                String(row?.itinerariesCount)?.toLowerCase()?.trim()
            );

            filterItineraryMatch = matchingItems?.length > 0;
          }

          if (modalTypeFilterSearch?.length > 0) {
            const matchingItems = modalTypeFilterSearch?.filter(
              (filterItem) =>
                filterItem?.currentPlan?.toLowerCase()?.trim() ===
                row?.currentPlan?.toLowerCase()?.trim()
            );

            filterTypeMatch = matchingItems?.length > 0;
          }

          return (
            (nameMatch || codeMatch || itinerariesCountMatch || planMatch) &&
            filterNameMatch &&
            filterItineraryMatch &&
            filterTypeMatch &&
            (dateFilter
              ? dayjs(row?.createdAt)?.isAfter(fromDate) &&
                dayjs(row?.createdAt)?.isBefore(toDate?.add(1, "day"))
              : true)
          );
        });

      setFilteredData(filtered);
    } catch (error) {
      console?.error(error);
    }
  };

  useEffect(() => {
    if (formatedData) {
      formatGuides();
    }
  }, [
    query,
    secondaryFilter,
    orderFilter,
    formatedData,
    dateFilter,
    modalNameFilter,
    modalItineraryFilter,
    modalTypeFilter,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalTypeFilterSearch,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    query,
    secondaryFilter,
    orderFilter,
    formatedData,
    dateFilter,
    modalNameFilter,
    modalItineraryFilter,
    modalTypeFilter,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalTypeFilterSearch,
    queryNum,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (guides && itinerariesData) {
      setData(
        guides?.map((guide) => {
          let guideItineraries = itinerariesData?.filter(
            (item) => item?.owner.id === guide?.id
          );
          return { ...guide, itineraries: guideItineraries || [] };
        })
      );
    }
  }, [guides]);

  useEffect(() => {
    try {
      getDataFromFirebase();
    } catch (error) {
      console?.error(error);
    }
  }, []);

  if (data?.length > 0) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return (
      <>
        {paginatedData?.map((row) => (
          <TableRow hover key={row?.id}>
            <TableCell sx={{ width: "40px" }}>
              {showOnlyFirstSixDigits(row?.id)}
            </TableCell>
            <TableCell sx={{ width: "300px" }}>{row?.name}</TableCell>
            <TableCell>
              {dayjs(row?.createdAt && row?.createdAt)?.format(
                "DD[/]MM[/]YYYY"
              )}
            </TableCell>
            <TableCell>{row?.itinerariesCount}</TableCell>
            <TableCell>{row?.currentPlan}</TableCell>
            <TableCell
              sx={{ width: "70px", color: "#006875", cursor: "pointer" }}
              align="center"
            >
              <RemoveRedEyeOutlined onClick={() => OpenDetails(row?.id)} />
            </TableCell>
            <TableCell sx={{ width: "50px" }} align="left">
              <Switch
                checked={!row?.active}
                color="success"
                onChange={() => OpenModal(!row?.active, row?.id, row?.name)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </TableCell>
          </TableRow>
        ))}

        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {modalNameFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(formatedData?.map((item) => item?.name?.trim())),
            ]?.map((item) => ({
              name: item,
            }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalItineraryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalItineraryFilterSearch}
            setModalFilterSearch={setModalItineraryFilterSearch}
            setModalFilter={setModalItineraryFilter}
            data={[
              ...new Set(formatedData?.map((item) => item?.itinerariesCount)),
            ]?.map((item) => ({ itinerariesCount: String(item) }))}
            labelPath="itinerariesCount"
            label="quantidade de roteiros"
          />
        )}

        {modalTypeFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalTypeFilterSearch}
            setModalFilterSearch={setModalTypeFilterSearch}
            setModalFilter={setModalTypeFilter}
            data={[
              ...new Set(
                formatedData?.map((item) => item?.currentPlan?.trim())
              ),
            ]?.map((item) => ({ currentPlan: item }))}
            labelPath="currentPlan"
            label="tipo"
          />
        )}
      </>
    );
  }
}
