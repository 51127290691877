import { styled } from "@mui/material/styles";
import { TableCell } from "@mui/material";

export const StyledTableCell = styled(TableCell)`
  cursor: ${({ columnId }) =>
    columnId !== "details" &&
    columnId !== "block" &&
    columnId !== "code" &&
    columnId !== "category"
      ? "pointer"
      : "default"};

  > div {
    display: flex;
    align-items: center;
    gap: 5px;

    > div {
      width: 60px;
    }
  }
`;
