import { configureStore } from "@reduxjs/toolkit";
import MenuSlice from "./MenuSlice";
import QuerySlice from "./QuerySlice";
import CategorySlice from "./CategorySlice";
import AgendaSlice from "./AgendaSlice";
import FinanceSlice from "./FinanceSlice";
import ApprovedSlice from "./ApprovedSlice";
import LoginSlice from "./LoginSlice";
import UsersReportSlice from "./UsersReportSlice";
import GuideReportSlice from "./GuideReportSlice";
import ShopsReportSlice from "./ShopsReportSlice";
import PlansReportSlice from "./PlansReportSlice";
import DenunciationSlice from "./DenunciationSlice";
import ConnectpointSlice from "./ConnectpointSlice";
import ItineraryReportSlice from "./ItineraryReportSlice";
import TuristicPointsReportSlice from "./TuristicPointsReportSlice";
import ExploreSlice from "./exploreSlice";
import TrendsPricesSlice from "./TrendsPricesSlice";
import ConnectsaleSlice from "./ConnectsalesSlice";
import PurchaseSlice from "./PurchaseSlice";

const store = configureStore({
  reducer: {
    menu: MenuSlice,
    query: QuerySlice,
    category: CategorySlice,
    agenda: AgendaSlice,
    finance: FinanceSlice,
    explore: ExploreSlice,
    approved: ApprovedSlice,
    denunciations: DenunciationSlice,
    login: LoginSlice,
    usersReport: UsersReportSlice,
    guideReport: GuideReportSlice,
    shopsReport: ShopsReportSlice,
    plansReport: PlansReportSlice,
    trendsPrices: TrendsPricesSlice,
    connectpointsReport: ConnectpointSlice,
    itineraryReport: ItineraryReportSlice,
    turisticPoint: TuristicPointsReportSlice,
    purchase: PurchaseSlice,
    connectsalesReport: ConnectsaleSlice,
  },
});

export default store;
