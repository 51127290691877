import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { TABLES } from "../enums/tables";
import { PAYMENT_STATUS } from "../enums/plans";

export async function create(agendaData) {
  const { icon_url } = agendaData;
  const documentRef = doc(collection(db, TABLES.EVENTS));
  let new_image_url = icon_url;

  const now = serverTimestamp();

  if (typeof new_image_url !== "string") {
    new_image_url = await uploadBytes(
      ref(storage, `images/${icon_url.name}${crypto.randomUUID()}`),
      icon_url
    )
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => url);
  }

  await setDoc(documentRef, {
    ...agendaData,
    icon_url: new_image_url,
    status: PAYMENT_STATUS.APPROVED,
    id: documentRef.id,
    created_at: now,
    updated_at: now,
    active: true,
    collection_name: TABLES.EVENTS,
  });

  return { id: documentRef.id, ...agendaData };
}

export async function update(agendaData) {
  const { icon_url, id } = agendaData;

  const documentRef = doc(db, TABLES.EVENTS, id);

  if (typeof icon_url === "string") {
    await updateDoc(documentRef, {
      id,
      ...agendaData,
    });

    return {
      id,
      ...agendaData,
    };
  }

  const new_image_url = await uploadBytes(
    ref(storage, `images/${icon_url.name}${crypto.randomUUID()}`),
    icon_url
  )
    .then((snapshot) => getDownloadURL(snapshot.ref))
    .then((url) => url);

  const updatedData = {
    id,
    ...agendaData,
    icon_url: new_image_url,
  };

  await updateDoc(documentRef, updatedData);

  return updatedData;
}

export async function remove(agendaData) {
  const { id } = agendaData;

  return deleteDoc(doc(db, TABLES.EVENTS, id));
}
