/****
 * In this component we Declare Labels for the table
 * So I decided to separate these components to make our code more reusable
 */
import { StyledTableCell } from "./styled";
import React, { useState } from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";
import DateFilter from "../../../Components/DateFilter/DateFilter";

export default function Index({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalDurationFilter,
  setModalDurationFilter,
  modalPlaceFilter,
  setModalPlaceFilter,
  currencyFilter,
  setCurrencyFilter,
}) {
  const Labels = [
    { id: "signatureId", label: "ID da assinatura", minWidth: 100 },
    { id: "name", label: "Usuário", minWidth: 100 },
    { id: "type", label: "Tipo", minWidth: 100 },
    { id: "place", label: "Local", minWidth: 100 },
    { id: "duration", label: "Duração", minWidth: 100 },
    {
      id: "date",
      label: "Data",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "price",
      label: `Preço (${currencyFilter.value})`,
      minWidth: 100,
      align: "left",
    },
  ];

  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const filtersArray = [
    modalNameFilter,
    modalTypeFilter,
    modalDurationFilter,
    modalPlaceFilter,
  ];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function handleOpenNameFilter(id) {
    setModalNameFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenTypeFilter(id) {
    setModalTypeFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenDurationFilter(id) {
    setModalDurationFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenPlaceFilter(id) {
    setModalPlaceFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function openCurrencyFilter() {
    setCurrencyFilter((prev) => ({ isOpen: true, value: prev.value }));
  }

  function handleClick(id) {
    if (id === "date") return openDateFilter(id);
    if (id === "name") return handleOpenNameFilter(id);
    if (id === "type") return handleOpenTypeFilter(id);
    if (id === "duration") return handleOpenDurationFilter(id);
    if (id === "place") return handleOpenPlaceFilter(id);
    if (id === "price") return openCurrencyFilter();

    return handleChangeOrder(id);
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }
  const LabelItems = () => (
    <>
      {Labels.map((column) => {
        let isFiltered = filtersArray.some(
          (item) => item.type === column.id && item.isUsing
        );
        return (
          <StyledTableCell
            onClick={() => handleClick(column.id)}
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "edit" &&
                column.id !== "delete" &&
                column.id !== "id" &&
                column.id !== "signatureId" &&
                column.id !== "category" && (
                  <div className="!w-[60px]">
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "desc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "asc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
    </>
  );

  return (
    <>
      <LabelItems />
      <div>
        {isDateFilterOpen && (
          <DateFilter
            setIsDateFilterOpen={setIsDateFilterOpen}
            setDateFilter={setDateFilter}
            dateFilter={dateFilter}
          />
        )}
      </div>
    </>
  );
}
