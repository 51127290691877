/****
 * In this component we declare all functionlities for Category Page
 * We retrieve data from our server
 */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import StyledTable from "../../../Components/Table/index";
import { setCategoryState } from "../../../features/MenuSlice";
import CategoryData from "./bodyData";
import { CategoryLabels } from "./Labels";
import { Register } from "./Register";
import { PrimaryButton } from "../../../Components/Button";
import { AppContainer } from "../../../Components/Global/Reusable";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";

export default function Index() {
  const [actualEditId, setActualEditId] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [secondaryFilter, setSecondaryFilter] = useState("");
  const [orderFilter, setOrderFilter] = useState(null);

  const timerRef = useRef(0);
  const dispatch = useDispatch();

  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  function editItem(id) {
    setActualEditId(id);
    dispatch(setCategoryState());
  }

  function closeRegister() {
    dispatch(setCategoryState());
    setActualEditId("");
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);
  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="Cadastro de Categorias"
          Component={
            <PrimaryButton onClick={() => dispatch(setCategoryState())}>
              Cadastrar Categorias
            </PrimaryButton>
          }
          Labels={
            <CategoryLabels
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
            />
          }
          TableData={
            <CategoryData
              orderFilter={orderFilter}
              secondaryFilter={secondaryFilter}
              onToastOpen={onToastOpen}
              editItem={editItem}
            />
          }
        />
        <Register
          onToastOpen={onToastOpen}
          editId={actualEditId}
          closeRegister={closeRegister}
        />
      </AppContainer>
    </>
  );
}
