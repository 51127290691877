import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import CurrencyFilter from "../../../Components/CurrencyFilter/CurrencyFilter";
import { TABLES } from "../../../enums/tables";
import {
  getFirebaseDataByCreationDate
} from "../../../Services/firebase";
import { showOnlyFirstSixDigits } from "../../../utils/showOnlyFirstSixDigits";

export default function Index({
  orderFilter,
  dateFilter,
  setPurchaseData,
  modalNameFilter,
  setModalNameFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalModelFilter,
  setModalModelFilter,
  currencyFilter,
  setCurrencyFilter,
  modalActiveFilter,
  setModalActiveFilter
}) {
  const [purchases, setPurchases] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const monthToFilter = Number(queryParams?.get("month"));

  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalTypeFilterSearch, setModalTypeFilterSearch] = useState([]);
  const [modalModelFilterSearch, setModalModelFilterSearch] = useState([]);
  const [modalActiveFilterSearch, setModalActiveFilterSearch] = useState([]);

  async function getDataFromFirebase() {
    let dataPurchases = await getFirebaseDataByCreationDate(TABLES.PURCHASES);
    setPurchases(dataPurchases);
  }

  useEffect(() => {
    getDataFromFirebase();
  }, []);

  const currencySymbol =
    currencyFilter.value === "BRL"
      ? "R$"
      : currencyFilter.value === "USD"
      ? "$"
      : "€";

  const currentPurchaseType = {
    connectguides: "ConnectGuide",
    connectstores: "ConnectStore",
    connectsales: "ConnectSale",
  };

  const currentPurchaseTypePlan = {
    complete: "ConnectStore completo",
    basic: "ConnectStore básico",
    "amateur-guide": "ConnectGuide não credenciado",
    "pro-guide": "ConnectGuide credenciado",
    connectsale: "ConnectSale",
  };

  useEffect(() => {
    async function formatTableData() {
      if (!purchases) return;
      let thisPurchases = purchases;

      if (monthToFilter !== 0) {
        thisPurchases = filterMonth(thisPurchases, monthToFilter);
      }

      const currentData = thisPurchases?.filter(
        (purchase) =>
          purchase?.status === "approved" &&
          purchase?.plan &&
          purchase?.plan?.collection !== "events" &&
          purchase?.plan?.nature !== "highlight" &&
          purchase?.plan?.collection !== "connectwatches"
      );
      setData(currentData);
    }
    formatTableData();
  }, [purchases, monthToFilter, currencyFilter.value]);

  function filterMonth(array, month) {
    return array?.filter((item) => {
      const date = new Date(item?.created_at);
      const itemMonth = date?.getMonth() + 1;

      return itemMonth === month;
    });
  }

  useEffect(() => {
    if (data?.length > 0) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);

      const filtered = data
        ?.filter((row) => {
          let filterNameMatch = true;
          let filterTypeMatch = true;
          let filterModelMatch = true;
          let filterActiveMatch = true;

          if (modalNameFilterSearch?.length > 0) {
            const matchingItems = modalNameFilterSearch?.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.user?.name?.toLowerCase()?.trim()
            );
            filterNameMatch = matchingItems?.length > 0;
          }
          if (modalTypeFilterSearch?.length > 0) {
            const matchingItems = modalTypeFilterSearch?.filter(
              (filterItem) =>
                filterItem?.type?.toLowerCase()?.trim() ===
                currentPurchaseType?.[row?.plan?.collection]
                  ?.toLowerCase()
                  ?.trim()
            );
            filterTypeMatch = matchingItems?.length > 0;
          }

          if (modalModelFilterSearch?.length > 0) {
            const matchingItems = modalModelFilterSearch?.filter(
              (filterItem) =>
                filterItem?.model?.toLowerCase()?.trim() ===
                currentPurchaseTypePlan?.[row?.plan.type]?.toLowerCase()?.trim()
            );
            filterModelMatch = matchingItems?.length > 0;
          }
          
          if (modalActiveFilterSearch.length > 0) {
            const matchingItems = modalActiveFilterSearch.filter(
              (filterItem) =>
                filterItem.status.toLowerCase().trim() ===
                row.status?.toLowerCase().trim()
            );
            filterActiveMatch = matchingItems.length > 0;
          }

          const nameMatch = row?.user?.name?.toLowerCase()?.includes(query);

          const idMatch = String(row?.id)?.toLowerCase()?.includes(query);

          const usernameMatch = String(row?.user?.name)
            ?.toLowerCase()
            ?.includes(query);

          const planMatch = String(currentPurchaseType?.[row?.plan?.collection])
            ?.toLowerCase()
            ?.includes(query);

          const modelMatch = String(currentPurchaseTypePlan?.[row?.plan?.type])
            ?.toLowerCase()
            ?.includes(query);

          const priceMatch = String(
            (
              row?.plan?.prices?.[currencyFilter.value].price_minor_units /
                100 || 0
            ).toFixed(2)
          )
            ?.toLowerCase()
            ?.includes(query);

          return (
            (nameMatch ||
              idMatch ||
              usernameMatch ||
              planMatch ||
              modelMatch ||
              priceMatch) &&
            filterNameMatch &&
            filterTypeMatch &&
            filterModelMatch &&
            filterActiveMatch &&
            (dateFilter
              ? dayjs(row?.created_at)?.isAfter(fromDate) &&
                dayjs(row?.created_at)?.isBefore(toDate?.add(1, "day"))
              : true)
          );
        })

        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.userName?.localeCompare(a?.userName)
              : a?.userName?.localeCompare(b?.userName);
          }
          if (orderFilter && orderFilter?.id === "type") {
            return orderFilter?.order === "asc"
              ? b?.purchaseType?.localeCompare(a?.purchaseType)
              : a?.purchaseType?.localeCompare(b?.purchaseType);
          }
          if (orderFilter && orderFilter?.id === "model") {
            return orderFilter?.order === "asc"
              ? b?.purchaseTypePlan?.localeCompare(a?.purchaseTypePlan)
              : a?.purchaseTypePlan?.localeCompare(b?.purchaseTypePlan);
          }
          if (orderFilter && orderFilter?.id === "price") {
            return orderFilter?.order === "asc"
              ? a?.price - b?.price
              : b?.price - a?.price;
          }

          return 0;
        });

      setFilteredData(filtered);
    }
  }, [
    currencyFilter.value,
    data,
    dateFilter,
    modalModelFilterSearch,
    modalNameFilterSearch,
    modalTypeFilterSearch,
    modalActiveFilterSearch,
    orderFilter,
    query,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    queryNum,
    query,
    filteredData,
    dateFilter,
    modalModelFilterSearch,
    modalNameFilterSearch,
    modalActiveFilterSearch,
    modalTypeFilterSearch,
    orderFilter,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);
  const statusEnum = {
    approved: 'Ativo',
    pending: 'Pendente',
    cancelled: 'Cancelado',
  }

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    setPurchaseData(data);

    return (
      <>
        {paginatedData?.map((row) => {
          return (
            <TableRow hover key={row?.id}>
              <TableCell sx={{ width: "100px" }}>
                {showOnlyFirstSixDigits(row?.id)}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {statusEnum[row.status] ?? "--"}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {row?.user?.name ?? "--"}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {currentPurchaseType?.[row?.plan?.collection] ?? "--"}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {currentPurchaseTypePlan?.[row?.plan?.type] ?? "--"}
              </TableCell>
              <TableCell sx={{ width: "100px" }}>
                {dayjs(row?.created_at)?.format("DD[/]MM[/]YYYY") ?? "--"}
              </TableCell>
             
              <TableCell sx={{ width: "100px" }}>
                {`${currencySymbol}  ${
                  (
                    row?.plan?.prices?.[currencyFilter.value]
                      ?.price_minor_units / 100
                  ).toFixed(2) ?? "--"
                }`}
              </TableCell>
            </TableRow>
          );
        })}
        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {modalNameFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(
                data?.map(
                  (item) => item?.user?.name && item?.user?.name?.trim()
                )
              ),
            ]?.map((item) => ({ name: item }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalTypeFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalTypeFilterSearch}
            setModalFilterSearch={setModalTypeFilterSearch}
            setModalFilter={setModalTypeFilter}
            data={[
              ...new Set(
                data?.map((item) =>
                  currentPurchaseType?.[item?.plan?.collection]?.trim()
                )
              ),
            ]?.map((item) => ({ type: String(item) }))}
            labelPath="type"
            label="tipo do plano"
          />
        )}

        {modalModelFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalModelFilterSearch}
            setModalFilterSearch={setModalModelFilterSearch}
            setModalFilter={setModalModelFilter}
            data={[
              ...new Set(
                data?.map((item) =>
                  currentPurchaseTypePlan?.[item?.plan.type]?.trim()
                )
              ),
            ]?.map((item) => ({ model: item }))}
            labelPath="model"
            label="modelo do plano"
          />
        )}

        {currencyFilter.isOpen && (
          <CurrencyFilter
            currencyFilter={currencyFilter}
            setCurrencyFilter={setCurrencyFilter}
          />
        )}
        {modalActiveFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalActiveFilterSearch}
            setModalFilterSearch={setModalActiveFilterSearch}
            setModalFilter={setModalActiveFilter}
            data={[
              ...new Set(data.map((item) => item.status.trim())),
            ].map((item) => ({ status: item, label: statusEnum[item]}))}
            labelPath="status"
            label="status"
          />
      
        )}
      </>
    );
  }
  return (
    <>
      <p style={{ paddingLeft: "1rem", whiteSpace: "nowrap" }}>
        Nenhum resultado?.
      </p>
    </>
  );
}
