import { doc, getDoc, updateDoc } from 'firebase/firestore'
import React, { useState, createContext, useEffect } from 'react'
import { auth, db } from '../firebase'

export const AuthContext = createContext({})

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [currentUserData, setCurrentUserData] = useState(null)
  const [loading, setLoading] = useState(true)

  const value = {
    currentUser,
    currentUserData,
    login,
    signup,
    logout,
    forgotPassword,
    updateEmail,
    updatePassword,
    updateUserInfo
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function forgotPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePasssword(password)
  }

  async function updateUserInfo(options) {
    const userDoc = doc(db, 'users', currentUser.uid)
    return updateDoc(userDoc, { ...options })
  }

  async function getUserData(id) {
    const userDoc = await getDoc(doc(db, 'users', id))
    setCurrentUserData(userDoc.data())
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user)
      if (user) {
        await getUserData(user.uid)
      } else {
        setCurrentUserData(null)
      }
      setLoading(false)
    })

    return unsubscribe
  }, [])

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
